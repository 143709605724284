import React, { ReactElement } from 'react';
import { ArrayField } from 'react-admin';
import SmartChipListField from '../../common/SmartChipListField';

interface PredecessorActivityFieldProps {
  source?: string;
  linkSource?: string;
  label?: string;
  chipSource?: string;
  sortable?: boolean;
}

const PredecessorActivityField: React.FC<PredecessorActivityFieldProps> = ({
  source,
  linkSource,
  label,
  chipSource,
  sortable,
}): ReactElement => {
  return (
    <ArrayField source={source} label={label} sortable={sortable}>
      <SmartChipListField linkSource={linkSource} chipSource={chipSource} />
    </ArrayField>
  );
};

PredecessorActivityField.defaultProps = {
  source: 'predecessors',
  linkSource: 'predecessorId',
  label: 'Predecessor Activities',
  chipSource: 'predecessor.activity',
};

export default PredecessorActivityField;
