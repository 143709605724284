import { registerLicense } from '@syncfusion/ej2-base';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { SYNCFUSION_LICENSE_KEY } from './components/constants';
import reportWebVitals from './reportWebVitals';
import './index.css';

// https://help.syncfusion.com/common/essential-studio/licensing/how-to-register-in-an-application
registerLicense(SYNCFUSION_LICENSE_KEY);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// <React.StrictMode> is not supported by Telerik Report Viewer, So It is removed
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
