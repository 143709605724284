import React, { ReactElement } from 'react';
import {
  Labeled,
  LabeledProps,
  usePermissions,
  useResourceContext,
} from 'react-admin';
import { hasFieldShowAccess } from '../../utils/UtilityFunctions';

interface LabeledWithCheckAccessProps extends LabeledProps {
  children: ReactElement;
}

const LabeledWithCheckAccess: React.FC<LabeledWithCheckAccessProps> = ({
  children,
  ...rest
}): ReactElement => {
  const resource = useResourceContext();
  const { permissions } = usePermissions();
  let hasFieldAccess = false;
  // Skip field permission check for related sources
  if (!children?.props?.source || children?.props?.source.includes('.')) {
    hasFieldAccess = true;
  } else {
    hasFieldAccess = hasFieldShowAccess(
      permissions,
      `${resource}.${children?.props?.source}`
    );
  }

  return hasFieldAccess ? <Labeled {...rest}>{children}</Labeled> : null;
};

export default LabeledWithCheckAccess;
