import React, { Fragment, ReactElement } from 'react';
import {
  Datagrid,
  DateField,
  ReferenceManyField,
  TextField,
  ArrayField,
  useRecordContext,
} from 'react-admin';
import AttachmentRevisionFormTab from '../attachmentRevision/AttachmentRevisionFormTab';
import ActivityPhaseField from '../common/ActivityPhaseField';
import { RESOURCE_ACTIVITY_CERTIFICATE } from '../constants';
import CertTemplateField from '../common/CertTemplateField';
import CustomBooleanField from '../common/CustomBooleanField';
import DisciplineField from '../common/DisciplineField';
import DownloadButton from '../common/DownloadButton';
import PreviewButton from '../common/PreviewButton';
import renderCertificateCategoryNameField from './renderCertificateCategoryNameField';
import { CertificateDetailsShowTabProps } from './types';

const CertDetailsShowTab: React.FC<CertificateDetailsShowTabProps> = ({
  target,
}): ReactElement => {
  const record = useRecordContext();

  return (
    <ReferenceManyField
      target={target}
      reference={RESOURCE_ACTIVITY_CERTIFICATE}
    >
      <Datagrid
        expand={<AttachmentRevisionFormTab targetId="certDetailId" onlyShow />}
        expandSingle
        bulkActionButtons={false}
        rowClick={false}
      >
        {renderCertificateCategoryNameField(record)}
        <ActivityPhaseField />
        <CertTemplateField />
        <DisciplineField />
        <TextField source="generatedBy" label="Generated By" />
        <DateField
          source="generatedDate"
          label="Generated Date"
          showTime={false}
        />
        <TextField source="acceptedBy" label="Accepted By" />
        <TextField source="acceptedDate" label="Accepted Date" />
        <CustomBooleanField source="isVerified" label="Verified" />
        <ArrayField source="" label="Actions">
          <Fragment>
            <PreviewButton />
            <DownloadButton />
          </Fragment>
        </ArrayField>
      </Datagrid>
    </ReferenceManyField>
  );
};

export default CertDetailsShowTab;
