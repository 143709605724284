import { activityCategory } from '../common/constants';
import {
  RESOURCE_COMPONENT,
  RESOURCE_PROJECT,
  RESOURCE_SUBSYSTEM,
  RESOURCE_SYSTEM,
} from '../constants';

export const ACTIVITY_EXPORT_BUTTONS = [
  {
    label: 'Export',
    fileName: 'activity',
    columns: [
      { source: 'projectId', label: 'Project' },
      { source: 'systemId', label: 'System' },
      { source: 'subsystemId', label: 'Subsystem' },
      { source: 'componentId', label: 'Tag' },
    ],
  },
  {
    label: 'Export All',
    fileName: 'activity_all',
    columns: [
      { source: 'projectId', label: 'Project' },
      { source: 'systemId', label: 'System' },
      { source: 'subsystemId', label: 'Subsystem' },
      { source: 'componentId', label: 'Tag' },
    ],
    includeAllColumns: true,
  },
];

export const entityNames = {
  [activityCategory.project]: 'Project',
  [activityCategory.system]: 'System',
  [activityCategory.subSystem]: 'Subsystem',
  [activityCategory.component]: 'Tag',
};

export const ACTIVITY_CATEGORY_OPTIONS = [
  { id: activityCategory.project, name: 'Project' },
  { id: activityCategory.system, name: 'System' },
  { id: activityCategory.subSystem, name: 'Subsystem' },
  { id: activityCategory.component, name: 'Tag' },
];

export const activityCategoryResources = {
  [activityCategory.project]: RESOURCE_PROJECT,
  [activityCategory.system]: RESOURCE_SYSTEM,
  [activityCategory.subSystem]: RESOURCE_SUBSYSTEM,
  [activityCategory.component]: RESOURCE_COMPONENT,
};

export const activityCategorySource = {
  [activityCategory.project]: 'projectId',
  [activityCategory.system]: 'systemId',
  [activityCategory.subSystem]: 'subsystemId',
  [activityCategory.component]: 'componentId',
};

export const activityCategoryNameSource = {
  [activityCategory.project]: 'project.project',
  [activityCategory.system]: 'system.systemName',
  [activityCategory.subSystem]: 'subsystem.subsystem',
  [activityCategory.component]: 'component.tag',
};

export const activityCategoryDescriptionSource = {
  [activityCategory.project]: 'project.description',
  [activityCategory.system]: 'system.description',
  [activityCategory.subSystem]: 'subsystem.description',
  [activityCategory.component]: 'component.tagDescription',
};
