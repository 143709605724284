import React, { Fragment, ReactElement, useCallback, useState } from 'react';
import { Identifier } from 'react-admin';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { titleFromResource } from '../../common/hooks/useResourceTitle';
import { getJsonValue } from '../../common/helpers/json';
import ModalDialog from '../../common/ModalDialog';
import { Notification } from '../type';
import NotificationItemCard from './NotificationItemCard';

interface ErrorNotificationProps {
  notification: Notification;
  readNotification: (id: Identifier) => void;
}

type ErrorResultType = {
  message: string;
  stack: string;
};

const ErrorNotification: React.FC<ErrorNotificationProps> = ({
  notification,
  readNotification,
}): ReactElement => {
  const resource =
    titleFromResource?.[notification?.details?.resourceName]?.[0] || '';
  const notificationTitle = `${resource} error`;
  const errorResult: ErrorResultType =
    getJsonValue(notification?.details?.error) || '';
  const [showDialog, setShowDialog] = useState(false);
  const handleOpenNotification = useCallback(() => setShowDialog(true), []);
  const closeDialog = useCallback(() => {
    setShowDialog(false);
  }, []);

  return (
    <Fragment>
      <NotificationItemCard
        notification={notification}
        readNotification={readNotification}
        handleOpenNotification={handleOpenNotification}
      />
      <ModalDialog
        title={notificationTitle}
        open={!!errorResult && showDialog}
        onClose={closeDialog}
      >
        <Fragment>
          <Typography variant="body1">
            {errorResult?.message && `Message: ${errorResult.message}`}
          </Typography>
          <div style={{ margin: '0 -16px' }}>
            <Accordion sx={{ boxShadow: 'none' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Stack details
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle2" sx={{ whiteSpace: 'pre-line' }}>
                  {errorResult.stack}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </Fragment>
      </ModalDialog>
    </Fragment>
  );
};

export default ErrorNotification;
