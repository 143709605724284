import { Button } from '@mui/material';
import React, { ReactElement, useEffect } from 'react';
import { useListContext, useUnselectAll } from 'react-admin';
import { Attachment } from '../../../attachment/types';
import { RESOURCE_ATTACHMENT } from '../../../constants';
import { SelectionItem } from '../../types';

interface AttachmentSelectButtonProps {
  onSelect: (items: SelectionItem[]) => void;
}

const AttachmentSelectButton: React.FC<AttachmentSelectButtonProps> = ({
  onSelect,
}): ReactElement => {
  const { data, selectedIds } = useListContext<Attachment>();
  const unselectAll = useUnselectAll(RESOURCE_ATTACHMENT);

  const select = () => {
    const selectedItems = data.filter((item) => selectedIds.includes(item.id));
    onSelect(
      selectedItems.map(({ id, fileName }) => ({
        id,
        title: fileName,
      }))
    );
    unselectAll();
  };

  useEffect(() => {
    return () => unselectAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Button onClick={select}>Select</Button>;
};

export default AttachmentSelectButton;
