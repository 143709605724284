import React, { Fragment, useCallback, ReactElement, useState } from 'react';
import { Button, useNotify, useRecordContext } from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import get from 'lodash/get';
import useEnforceCertGatingSetting from '../hooks/useEnforceCertGatingSetting';
import { activityStatusOverall, strictPrompt } from '../constants';
import { RESOURCE_ACTIVITY_CERTIFICATE } from '../../constants';
import useCanAccess from '../hooks/useCanAccess';
import { ActivityCategory } from '../types';
import ModalDialog from '../ModalDialog';
import CertificateValidationForm from './CertificateValidationForm';
import { useGenerateCertificate } from './hooks';
import styles from './styles';

interface GenerateCertificateButtonProps {
  activityCategory: ActivityCategory;
  certStatusSource?: string;
}

const GenerateCertificateButton: React.FC<GenerateCertificateButtonProps> = ({
  activityCategory,
  certStatusSource,
}): ReactElement => {
  const record = useRecordContext();
  const canAccessList = useCanAccess();
  const notify = useNotify();
  const enforceCertGating = useEnforceCertGatingSetting(activityCategory);
  const [open, setOpen] = useState(false);
  const openDialog = useCallback(() => setOpen(true), []);
  const closeDialog = useCallback(() => setOpen(false), []);
  const { generateCertificate, generationInProgress } =
    useGenerateCertificate();
  const queryClient = useQueryClient();

  const certStatus = get(record, certStatusSource);

  const handleGenLinkClick = useCallback(() => {
    // No Certificate Validation modal needed for 'STRICT' cert gating mode
    // But we have additional logic and show modal in any case
    openDialog();
  }, [openDialog]);

  const handleGenerateButtonClick = useCallback(
    async (type?: string) => {
      closeDialog();
      const res = await generateCertificate(type);
      if (res?.status === 201) {
        notify('Certificate was created');
        queryClient.removeQueries({
          queryKey: [RESOURCE_ACTIVITY_CERTIFICATE],
          exact: false,
        });
      }
    },
    [closeDialog, generateCertificate, notify, queryClient]
  );

  return canAccessList.certGen && // user must have 'certGen' permission
    certStatus !== activityStatusOverall.noCert && // don't show Gen link for 'Grey' ('No Cert') status
    !(
      // don't show Gen link for 'Red' ('Not Ready') status and 'STRICT' cert gating mode
      (
        certStatus === activityStatusOverall.notReady &&
        enforceCertGating === strictPrompt.strict
      )
    ) ? (
    <Fragment>
      <Button
        label={!generationInProgress ? 'Generate Cert' : 'Generating...'}
        onClick={handleGenLinkClick}
        sx={styles.genLink}
        disabled={generationInProgress}
      />

      <ModalDialog
        title="Certificate Validation"
        open={open}
        onClose={closeDialog}
      >
        <CertificateValidationForm
          record={{ ...record, enforceCertGating }}
          activityCategory={activityCategory}
          onGenButtonClick={handleGenerateButtonClick}
          showGenButton={
            // don't show 'Generate Certificate' button for 'STRICT_WITH_PROMPT' cert gating mode
            // and 'Red` ('Not Ready') status for user without 'certOverride' permission
            !(
              enforceCertGating === strictPrompt.strictWithPrompt &&
              certStatus === activityStatusOverall.notReady &&
              !canAccessList.certOverride
            )
          }
          genButtonLabel={
            enforceCertGating === strictPrompt.prompt &&
            certStatus === activityStatusOverall.notReady
              ? 'Proceed Anyways'
              : 'Generate'
          }
          genButtonDisabled={generationInProgress}
        />
      </ModalDialog>
    </Fragment>
  ) : canAccessList.certGen ? (
    <span style={styles.genLink} />
  ) : null;
};

GenerateCertificateButton.defaultProps = {
  certStatusSource: 'certGatingStatus.certStatus',
};

export default GenerateCertificateButton;
