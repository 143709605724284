import React, { useCallback, useState } from 'react';
import { ImageField, SimpleShowLayout, useRecordContext } from 'react-admin';
import { getFileName } from '../common/helpers/getFileName';
import CustomDialog from '../common/CustomDialog';
import commonStyles from '../common/commonStyles';

export const useModal = (isOpenedByDefault = false) => {
  const [isOpenedModal, setIsOpenedModal] = useState(isOpenedByDefault);
  const closeModal = useCallback(() => setIsOpenedModal(false), []);
  const openModal = useCallback(() => setIsOpenedModal(true), []);

  return {
    closeModal,
    openModal,
    isOpenedModal,
  };
};

type PreviewButtonProps = {
  showEmptyIcon?: boolean;
  isOpenedModal: boolean;
  closeModal: () => void;
};

export const ImageModal: React.FC<PreviewButtonProps> = ({
  isOpenedModal,
  closeModal,
}) => {
  const record = useRecordContext();
  const fileName = getFileName(
    record?.fileName || record.s3FileKey || record.downloadUrl
  );

  return (
    <CustomDialog title={fileName} open={isOpenedModal} onClose={closeModal}>
      <SimpleShowLayout record={record}>
        <ImageField
          source="downloadUrl"
          label={false}
          sx={commonStyles.previewImageField}
        />
      </SimpleShowLayout>
    </CustomDialog>
  );
};
