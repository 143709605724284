import React, { Fragment, ReactElement, useMemo } from 'react';
import {
  DeleteWithConfirmButton,
  Identifier,
  useGetRecordId,
  useRecordContext,
  useResourceContext,
  useStore,
  ResourceContextProvider,
} from 'react-admin';
import { DeleteWithConfirmButtonProps } from 'ra-ui-materialui';
import { ReactComponent as DeleteIcon } from '../../assets/icons/functionalIcons/Trash.svg';
import EditTitle from './EditTitle';
import useInvalidateRelatedResourceQueries from './hooks/useInvalidateRelatedResourceQueries';
import useUpdateSelectedIds from './hooks/useUpdateSelectedIds';

interface DeleteWithConfirmIconButtonProps
  extends DeleteWithConfirmButtonProps {
  onDelete?: (record) => void;
  resourceAlias?: string;
  removeRelatedResource?: boolean;
}

const DeleteWithConfirmIconButton: React.FC<
  DeleteWithConfirmIconButtonProps
> = ({
  onDelete,
  resourceAlias,
  removeRelatedResource = true,
  ...rest
}): ReactElement => {
  const resourceContext: string = useResourceContext();
  const resource = resourceAlias || resourceContext;
  const record = useRecordContext();
  const recordId: Identifier = useGetRecordId();

  const invalidateRelatedResourceQueries =
    useInvalidateRelatedResourceQueries();

  const [expandedRecords, setExpandedRecords] = useStore(
    `${resourceContext}.datagrid.expanded`
  );
  const updateSelectedIds = useUpdateSelectedIds(resourceContext);

  const mutationOptions = useMemo(() => {
    return {
      onSuccess: async () => {
        const newExpandedRecords =
          expandedRecords &&
          expandedRecords.filter(
            (expandedRecord) => expandedRecord !== recordId
          );

        if (newExpandedRecords) {
          setExpandedRecords(newExpandedRecords);
        }

        updateSelectedIds(record.id);

        if (onDelete) {
          onDelete(record);
        }

        if (removeRelatedResource) {
          await invalidateRelatedResourceQueries();
        }
      },
    };
  }, [
    expandedRecords,
    updateSelectedIds,
    record,
    onDelete,
    removeRelatedResource,
    recordId,
    setExpandedRecords,
    invalidateRelatedResourceQueries,
  ]);

  return (
    <DeleteWithConfirmButton
      {...rest}
      icon={<DeleteIcon />}
      label=""
      mutationOptions={mutationOptions}
      confirmTitle={
        <ResourceContextProvider value={resource}>
          <Fragment>
            Delete <EditTitle />
          </Fragment>
        </ResourceContextProvider>
      }
      className="deleteButton"
    />
  );
};

export default DeleteWithConfirmIconButton;
