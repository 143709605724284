import React, { ReactElement } from 'react';
import { DateField, TextField, useRecordContext } from 'react-admin';
import { useDictionaryLabelsWithResource } from '../../../../../utils/UtilityFunctions';
import commonStyles from '../../../../common/commonStyles';
import CustomBooleanField from '../../../../common/CustomBooleanField';
import ActivityField from '../../../../common/ActivityField';
import { RESOURCE_CERT_DETAIL } from '../../../../constants';
import { FormTabProps, QrScanList } from '../../../types';
import DataFormTab from '../DataFormTab';
import CertFormRow from './CertFormRow';

const CertFormTab: React.FC<FormTabProps> = ({ editMode }): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();
  const record = useRecordContext<QrScanList>();
  const data = record.qrs.certificates;

  return (
    <DataFormTab
      data={data}
      attachmentId={record.id}
      resource={RESOURCE_CERT_DETAIL}
      editFormRow={<CertFormRow data={data} />}
      editMode={editMode}
    >
      <ActivityField source="activityId" activitySource="activity.activity" />
      <TextField
        source="certTemplate.certName"
        label={labels['certName'] || 'Certificate Name'}
      />
      <TextField
        source="acceptedBy"
        label={labels['acceptedBy'] || 'Accepted By'}
        sx={commonStyles.rowFormField}
      />
      <DateField
        source="acceptedDate"
        label={labels['acceptedDate'] || 'Accepted Date'}
        sx={commonStyles.rowFormField}
      />
      <CustomBooleanField
        source="isVerified"
        label="Verified"
        sx={commonStyles.rowFormShortField}
      />
      <CustomBooleanField
        source="isDeleted"
        label="Deleted"
        sx={commonStyles.rowFormShortField}
      />
    </DataFormTab>
  );
};

export default CertFormTab;
