import React, { ReactElement, useMemo } from 'react';
import { SelectInput, TextInput, Identifier } from 'react-admin';
import { RowForm, useRowContext } from '@react-admin/ra-editable-datagrid';
import { required } from '../../../utils/UtilityFunctions';
import ActivityPhaseInput from '../../common/ActivityPhaseInput';
import ActivityTypeInput from '../../common/ActivityTypeInput';
import commonStyles from '../../common/commonStyles';
import CustomDateInput from '../../common/CustomDateInput';
import CustomNumberInput from '../../common/CustomNumberInput';
import DisciplineInput from '../../common/DisciplineInput';
import PredecessorActivityInput from './PredecessorActivityInput';

interface Props {
  activityId: Identifier;
  onSuccess: () => Promise<void>;
}

const PredecessorActivitiesRowForm: React.FC<Props> = ({
  activityId,
  onSuccess,
}): ReactElement => {
  const { close } = useRowContext();

  const mutationOptions = useMemo(
    () => ({
      onSuccess: async () => {
        await onSuccess();
        close();
      },
    }),
    [close, onSuccess]
  );

  const predecessorActivityRenderer = (choice) =>
    `${choice.activity}::${choice.description}`;

  const transform = ({
    activityId,
    predecessorId,
    type,
    offset,
    offsetUnit,
  }) => ({
    activityId,
    predecessorId,
    type,
    offset,
    offsetUnit,
  });

  return (
    <RowForm
      mutationOptions={mutationOptions}
      defaultValues={{ activityId }}
      transform={transform}
    >
      <PredecessorActivityInput
        source="predecessorId"
        label="Predecessor Activity"
        idsToFilterOut={[activityId]}
        optionText={predecessorActivityRenderer}
        sx={commonStyles.rowFormInput}
        isRequired
      />
      <TextInput
        source="predecessor.description"
        label="Description"
        sx={commonStyles.rowFormInput}
        disabled
      />
      <SelectInput
        source="type"
        label="Dependency Type"
        choices={[
          { id: 'FF', name: 'FF' },
          { id: 'FS', name: 'FS' },
          { id: 'SF', name: 'SF' },
          { id: 'SS', name: 'SS' },
        ]}
        validate={required()}
        sx={commonStyles.rowFormInput}
      />
      <CustomNumberInput
        source="offset"
        label="Offset"
        sx={commonStyles.rowFormExtraExtraShortInput}
        isRequired
        defaultValue={0}
      />
      <SelectInput
        source="offsetUnit"
        label="Offset Unit"
        choices={[
          { id: 'MINUTE', name: 'MINUTE' },
          { id: 'HOUR', name: 'HOUR' },
          { id: 'DAY', name: 'DAY' },
        ]}
        defaultValue="DAY"
        validate={required()}
        sx={commonStyles.rowFormExtraShortInput}
      />
      <TextInput
        source="predecessor.category"
        label="Category"
        sx={commonStyles.rowFormExtraShortInput}
        disabled
      />
      <ActivityPhaseInput
        source="predecessor.activityPhase.id"
        sx={commonStyles.rowFormExtraExtraShortInput}
        disabled
      />
      <ActivityTypeInput
        source="predecessor.activityType.id"
        sx={commonStyles.rowFormExtraShortInput}
        disabled
      />
      <DisciplineInput
        source="predecessor.discipline.id"
        sx={commonStyles.rowFormExtraShortInput}
        disabled
      />
      <CustomNumberInput
        source="predecessor.percentComplete"
        label="Progress"
        sx={commonStyles.rowFormExtraExtraShortInput}
        disabled
      />
      <CustomDateInput
        source="predecessor.plannedStartDate"
        label="Planned Start Date"
        sx={commonStyles.rowFormShortInput}
        disableOpenPicker
        disabled
      />
      <CustomDateInput
        source="predecessor.plannedDate"
        label="Planned Completion Date"
        sx={commonStyles.rowFormShortInput}
        disableOpenPicker
        disabled
      />
    </RowForm>
  );
};

export default PredecessorActivitiesRowForm;
