import { Box, Checkbox, Divider, FormControlLabel, Paper } from '@mui/material';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { AutocompleteInput } from 'react-admin';
import { RESOURCE_MATERIAL_PURCHASE_ORDER } from '../../../constants';
import { getContext, UserContext } from '../../../../provider/userContext';
import AutocompleteReferenceInput from '../../../common/AutocompleteReferenceInput';
import { PurchaseOrderRequestStatus } from '../../purchaseOrder/constants';

interface PurchaseOrderFilterInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  alwaysOn?: boolean;
  multiple?: boolean;
  sx?: object;
}

const PurchaseOrderFilterInput: React.FC<PurchaseOrderFilterInputProps> = ({
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const [showOnlyActivePO, setShowOnlyActivePO] = useState<boolean>(true);

  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
        ...(showOnlyActivePO && {
          status: [
            PurchaseOrderRequestStatus.GENERATED,
            PurchaseOrderRequestStatus.ORDERED,
          ],
        }),
      },
      includeFields: ['id', 'orderNo'],
      sort: { field: 'orderNo', order: 'ASC' as const },
    };
  }, [ctx?.projectId, showOnlyActivePO]);

  const handleCheckboxClick = useCallback((event) => {
    event.preventDefault();
    setShowOnlyActivePO((prev) => !prev);
  }, []);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_MATERIAL_PURCHASE_ORDER}
      requestParams={requestParams}
      name="purchaseOrder_eq_any"
      optionText="orderNo"
      includeFields={['id', 'orderNo']}
      PaperComponent={(paperProps) => {
        const { children, ...restPaperProps } = paperProps;
        return (
          <Paper {...restPaperProps}>
            <Box
              style={{ paddingLeft: '12px' }}
              onMouseDown={(event) => event.preventDefault()}
            >
              <FormControlLabel
                label="Show only active PO"
                onClick={handleCheckboxClick}
                control={<Checkbox checked={showOnlyActivePO} />}
              />
            </Box>
            <Divider />
            {children}
          </Paper>
        );
      }}
    />
  );
};

PurchaseOrderFilterInput.defaultProps = {
  source: 'materialPurchaseOrder->materialPurchaseOrderId',
  label: 'Purchase Order',
};

export default PurchaseOrderFilterInput;
