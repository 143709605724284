import { useQueryClient, useMutation } from '@tanstack/react-query';
import React, {
  ReactElement,
  useState,
  Fragment,
  useMemo,
  useCallback,
} from 'react';
import {
  WrapperField,
  Identifier,
  RowClickFunction,
  useNotify,
} from 'react-admin';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import { Box } from '@mui/material';
import { GREEN_LIGHT, WHITE } from '../../../../theme/colors';
import { getErrorMessage } from '../../../../utils/UtilityFunctions';
import BulkEditButton from '../../../common/BulkEditButton';
import useDefaultFilter from '../../../common/hooks/useDefaultFilter';
import useInvalidateResourceQueryCache from '../../../common/hooks/useInvalidateResourceQueryCache';
import commonStyles from '../../../common/commonStyles';
import ListWithTitle from '../../../common/ListWithTitle';
import {
  RESOURCE_COMPONENT_TEST,
  RESOURCE_QR_SCANNER,
} from '../../../constants';
import { QrScanItem } from '../../types';
import useDataProvider from '../../../../provider/dataProvider/useDataProvider';
import DeleteQrItemButton from './components/DeleteQrItemButton';
import FileNameField from './components/FileNameField';
import ProcessButton from './components/ProcessButton';
import PdfView from './components/PdfView';
import ItrBulkEditForm from './itrFormTab/ItrBulkEditForm';
import EditRowButton from './EditRowButton';

interface DataFormTabProps {
  data: QrScanItem[];
  attachmentId: Identifier;
  resource: string;
  children: ReactElement[];
  editFormRow: ReactElement;
  editMode?: boolean;
}

const DataFormTab: React.FC<DataFormTabProps> = ({
  data,
  attachmentId,
  resource,
  editFormRow,
  children,
  editMode,
}): ReactElement => {
  const notify = useNotify();
  const ids = useMemo(() => data.map((item) => item.recordId), [data]);
  const [selectedRecordId, setSelectedRecordId] = useState<Identifier>(null);
  const [processInProgress, setProcessInProgress] = useState(false);
  const defaultFilter = useDefaultFilter('project', { id: ids });
  const queryClient = useQueryClient();
  const invalidateQueries =
    useInvalidateResourceQueryCache(RESOURCE_QR_SCANNER);
  const dataProvider = useDataProvider();

  const { mutate: startProcess } = useMutation({
    mutationFn: async (revisionIds: Identifier[]) => {
      setProcessInProgress(true);
      let i = 1;

      for await (const revisionId of revisionIds) {
        const result = await dataProvider.startProcess(RESOURCE_QR_SCANNER, {
          attachmentId,
          revisionId,
        });
        if (i === revisionIds.length) {
          return result;
        }
        i++;
      }
    },
    onSuccess: async ({ data }) => {
      if (data?.attachmentDeletedCompletely) {
        await queryClient.invalidateQueries({
          queryKey: [RESOURCE_QR_SCANNER, 'getList'],
        });
      } else {
        await invalidateQueries();
      }
      notify('Process completed', {
        type: 'success',
      });
    },
    onError: async (error) => {
      await invalidateQueries();
      notify(getErrorMessage(error), {
        type: 'error',
        undoable: false,
      });
    },
    onSettled: () => {
      setProcessInProgress(false);
    },
  });

  const pdfUrl = useMemo(() => {
    const qrScanItem = data.find((item) => item.recordId === selectedRecordId);
    return qrScanItem?.downloadUrl || '';
  }, [data, selectedRecordId]);

  const handleProcess = useCallback(
    (data: Identifier[]) => {
      startProcess(data);
    },
    [startProcess]
  );

  const handleRowClick = useCallback<RowClickFunction>(
    (id) => {
      if (selectedRecordId === id) {
        setSelectedRecordId(null);
      } else {
        setSelectedRecordId(id);
      }
      return false;
    },
    [selectedRecordId]
  );

  return (
    <ListWithTitle
      title={<Fragment />}
      filterDefaultValues={defaultFilter}
      filter={null}
      resource={resource}
      actions={false}
      disableSyncWithLocation
      aside={pdfUrl ? <PdfView url={pdfUrl} /> : null}
      storeKey={`qr-scan-tab-${resource}`}
    >
      <EditableDatagrid
        disableAutofocus
        bulkActionButtons={
          editMode ? (
            <Fragment>
              {resource === RESOURCE_COMPONENT_TEST && (
                <BulkEditButton
                  resource={resource}
                  form={<ItrBulkEditForm />}
                />
              )}
              <ProcessButton
                data={data}
                resource={resource}
                onClick={handleProcess}
                label={processInProgress ? 'Processing...' : 'Process'}
                disabled={processInProgress}
              />
            </Fragment>
          ) : null
        }
        mutationMode="pessimistic"
        actions={false}
        editForm={editMode ? editFormRow : null}
        sx={commonStyles.formTabDatagrid}
        rowClick={handleRowClick}
        rowSx={(record) => ({
          backgroundColor: record.id === selectedRecordId ? GREEN_LIGHT : WHITE,
        })}
      >
        <FileNameField data={data} />
        {children}
        {editMode ? (
          <WrapperField label="Actions">
            <Box sx={{ width: '64px' }}>
              <EditRowButton />
              <DeleteQrItemButton data={data} />
            </Box>
          </WrapperField>
        ) : null}
      </EditableDatagrid>
    </ListWithTitle>
  );
};

export default DataFormTab;
