import React, { ReactElement } from 'react';
import { DateField, Labeled, TabbedShowLayout, TextField } from 'react-admin';
import Box from '@mui/material/Box';
import AttachmentList from '../attachment/AttachmentList';
import AuditListTab from '../audit/AuditListTab';
import AssignedField from '../common/Assigned/AssignedField';
import commonStyles from '../common/commonStyles';
import CustomBooleanField from '../common/CustomBooleanField';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import TestEquipmentTypeField from '../common/TestEquipmentTypeField';
import VendorField from '../common/VendorField';
import { RESOURCE_TEST_EQUIPMENT_CHECKOUT } from '../constants';

const TestEquipmentCheckoutShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Test Equipment Checkout">
        <Box>
          <Labeled>
            <TextField
              label="Test Equipment Name"
              source="testEquipment.testEquipment"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              label="Manufacturer"
              source="testEquipment.manufacturer"
              sx={commonStyles.flexBoxLongItem}
            />
          </Labeled>
        </Box>
        <Box>
          <Labeled>
            <TextField
              label="Model"
              source="testEquipment.model"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              label="Serial Number"
              source="testEquipment.serialNumber"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <CustomBooleanField
              label="Rent"
              source="testEquipment.rent"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box>
          <Labeled>
            <DateField
              label="Calibration Date"
              source="testEquipment.calibrationDate"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <DateField
              label="Calibration Expiration Date"
              source="testEquipment.calibrationExpirationDate"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box>
          <Labeled>
            <TextField
              label="Cost"
              source="testEquipment.cost"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <VendorField
              label="Vendor"
              source="testEquipment.vendorId"
              textSource="testEquipment.vendor.vendor"
            />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TestEquipmentTypeField
              label="Test Equipment Type"
              source="testEquipment.testEquipmentTypeId"
              textSource="testEquipment.testEquipmentType.type"
            />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <AssignedField label="Responsible" />
          </Labeled>
          <Labeled>
            <DateField
              label="Check Out Date"
              source="checkOutDate"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <DateField
              label="Check In Date"
              source="checkInDate"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label="Attachment"
        id="attachment_tab"
        sx={commonStyles.formTabHeader}
      >
        <AttachmentList
          targetId="testEquipmentCheckoutId"
          attachmentType={[ATTACHMENT_TYPE_ENUMS.GENERAL]}
          resourceName={RESOURCE_TEST_EQUIPMENT_CHECKOUT}
          onlyShow
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_test_equipment_checkout" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default TestEquipmentCheckoutShow;
