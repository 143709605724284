import React, { ReactElement } from 'react';
import { TextField, useRecordContext } from 'react-admin';
import SmartLinkField from '../../common/SmartLinkField';
import {
  activityCategoryNameSource,
  activityCategoryResources,
  activityCategorySource,
} from '../constants';

interface Props {
  label?: string;
  source?: string;
  textSource?: string;
  sortBy?: string;
  sx?: object;
}

const CategoryNameField: React.FC<Props> = ({
  label,
  source,
  sx,
  textSource,
}): ReactElement => {
  const record = useRecordContext();

  return (
    <SmartLinkField
      label={label}
      source={activityCategorySource[record.category] || source}
      resource={activityCategoryResources[record.category]}
    >
      <TextField
        sx={sx}
        {...{
          source: activityCategoryNameSource[record.category] || textSource,
        }}
      />
    </SmartLinkField>
  );
};

CategoryNameField.defaultProps = {
  label: 'Category Name',
  source: 'entity',
  textSource: 'system.systemName',
};

export default CategoryNameField;
