import React, { ReactElement, useMemo } from 'react';
import { getContext, UserContext } from '../../../provider/userContext';
import AutocompleteReferenceInput, {
  AutocompleteReferenceInputProps,
} from '../../common/AutocompleteReferenceInput';
import { RESOURCE_USER_GROUP } from '../../constants';

interface UserGroupsInputProps
  extends Partial<AutocompleteReferenceInputProps> {}

const UserGroupsInput: React.FC<UserGroupsInputProps> = (
  props
): ReactElement => {
  const ctx: UserContext = getContext();
  const projectId: number = ctx.projectId;
  const requestParams = useMemo(() => {
    return {
      filter: { projectId, isDeleted: false },
      sort: { field: 'userGroup', order: 'ASC' as const },
    };
  }, [projectId]);

  return (
    <AutocompleteReferenceInput
      {...props}
      resource={RESOURCE_USER_GROUP}
      requestParams={requestParams}
      multiple
      optionText="userGroup"
      includeFields={['id', 'userGroup']}
    />
  );
};

export default UserGroupsInput;
