import { useQueryClient } from '@tanstack/react-query';
import React, { ReactElement } from 'react';
import CertDetailsEditTab from '../certDetails/CertDetailsEditTab';
import AttachmentFormTab from '../common/AttachmentFormTab';
import commonStyles from '../common/commonStyles';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import HistoryTab from '../common/HistoryTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import LazyFormTab from '../common/LazyFormTab';
import { EditPropsWithLocation } from '../common/types';
import { RESOURCE_ACTIVITY } from '../constants';
import ActivityForm from './components/ActivityForm';
import PredecessorActivitiesEditTab from './components/PredecessorActivitiesEditTab';

const ActivityEdit: React.FC<EditPropsWithLocation> = (props): ReactElement => {
  const queryClient = useQueryClient();
  const onSuccess = async () => {
    queryClient.removeQueries({
      queryKey: [RESOURCE_ACTIVITY, 'getOne'],
      exact: false,
    });
  };

  return (
    <TabbedEditForm {...props} mutationOptions={{ onSuccess }}>
      <LazyFormTab
        label="Activity"
        id="activity_tab"
        sx={commonStyles.formTabHeader}
      >
        <ActivityForm />
        <MetaDataInputs />
      </LazyFormTab>
      <LazyFormTab
        label="Predecessor Activities"
        id="predecessor_activities_tab"
        sx={commonStyles.formTabHeader}
      >
        <PredecessorActivitiesEditTab />
      </LazyFormTab>
      <LazyFormTab
        label="Certificates"
        id="certificates_tab"
        sx={commonStyles.formTabHeader}
      >
        <CertDetailsEditTab
          target="activityId"
          targetReference={RESOURCE_ACTIVITY}
        />
      </LazyFormTab>
      <LazyFormTab
        label="Attachment"
        id="attachment_tab"
        sx={commonStyles.formTabHeader}
      >
        <AttachmentFormTab
          targetId="activityId"
          resourceName={RESOURCE_ACTIVITY}
          attachmentType={[ATTACHMENT_TYPE_ENUMS.GENERAL]}
        />
      </LazyFormTab>
      <HistoryTab tableName="tbl_activity" />
    </TabbedEditForm>
  );
};

export default ActivityEdit;
