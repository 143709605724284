import { useGetList } from 'react-admin';
import { LOOKUP_PAGE_SIZE } from '../../common/constants';
import { RESOURCE_PROJECT } from '../../constants';
import { getProjectListFilterForCurrentUser } from '../helpers';

const useProjectListForSwitch = () => {
  const filter = getProjectListFilterForCurrentUser();

  const { data, total, isPending } = useGetList(RESOURCE_PROJECT, {
    filter,
    pagination: {
      page: 1,
      perPage: 5 * LOOKUP_PAGE_SIZE,
    },
    sort: {
      field: 'project',
      order: 'ASC',
    },
  });

  return {
    projects: data,
    totalProjects: total,
    projectsAreLoading: isPending, // https://marmelab.com/react-admin/Actions.html#ispending-vs-isloading-vs-isfetching
  };
};

export default useProjectListForSwitch;
