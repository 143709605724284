import { DialogComponent } from '@syncfusion/ej2-react-popups';
import React, { ReactElement, useCallback } from 'react';
import useDebounce from './hooks/useDebounce';

interface CustomDialogProps {
  title: string;
  children: ReactElement;
  open: boolean;
  onClose?: () => void;
}

const CustomDialog: React.FC<CustomDialogProps> = ({
  title,
  children,
  open,
  onClose,
}): ReactElement => {
  const debounce = useDebounce();
  const handleResizing = useCallback(() => {
    debounce(() => {
      if (window.dispatchEvent) {
        window.dispatchEvent(new Event('resize'));
      }
    });
  }, [debounce]);

  return (
    open && (
      <DialogComponent
        id="resizable-dialog"
        header={title}
        width="850px"
        height="75vh"
        isModal={true}
        visible={open}
        close={onClose}
        overlayClick={onClose}
        showCloseIcon={true}
        enableResize={true}
        resizeHandles={['All']}
        allowDragging={true}
        resizing={handleResizing}
      >
        {children}
      </DialogComponent>
    )
  );
};

export default CustomDialog;
