import React, { ReactElement } from 'react';
import { Button, Filter, NullableBooleanInput } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import AssignedInput from '../../common/Assigned/AssignedInput';
import { ContactCategories } from '../../common/contactInput/constants';
import useSidebarFilters from '../../common/hooks/useSidebarFilters';
import ContactInput from '../../common/contactInput/ContactInput';
import ActivityPhaseInput from '../../common/ActivityPhaseInput';
import CertTemplateInput from '../../common/CertTemplateInput';
import sideFilterStyles from '../../common/sideFilterStyles';
import CustomDateInput from '../../common/CustomDateInput';
import DisciplineInput from '../../common/DisciplineInput';
import DateRangeFilter from '../../common/DateRangeFilter';
import SubsystemInput from '../../common/SubsystemInput';
import ComponentInput from '../../common/ComponentInput';
import { RESOURCE_CERT_DETAIL } from '../../constants';
import AutocompleteInput from '../../common/AutocompleteInput';
import SystemInput from '../../common/SystemInput';
import { SideFilter } from '../../common/types';
import TextInput from '../../common/TextInput';
import { statuses } from '../constants';

const CertDetailsSidebarFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
  } = useSidebarFilters(RESOURCE_CERT_DETAIL, defaultFilter);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <CertTemplateInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <AutocompleteInput
            label="Status"
            source="status"
            fullWidth
            alwaysOn
            sx={sideFilterStyles.sideFiltersSelectInput}
            choices={statuses}
          />
          <ComponentInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <SystemInput
            source="certDetailSubsystemSystem->finalSystemId"
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <SubsystemInput
            source="certDetailSubsystemSystem->finalSubsystemId"
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <ActivityPhaseInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <DisciplineInput
            sx={sideFilterStyles.sideFiltersSelectInput}
            alwaysOn
            multiple
          />
          <NullableBooleanInput
            label="Smart"
            name="isSmart"
            source="isSmart"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <AssignedInput
            label="Responsible"
            category={ContactCategories.certSignOff}
            source="responsible"
            alwaysOn
          />
          <NullableBooleanInput
            label="Verified"
            name="isVerified"
            source="isVerified"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <ContactInput
            category={ContactCategories.certSignOff}
            label="Accepted By"
            source="acceptedBy_inc"
            alwaysOn
            canCreate={false}
          />
          <CustomDateInput
            source="acceptedDate"
            label="Accepted Date"
            name="acceptedDate_inc"
            alwaysOn
          />
          <NullableBooleanInput
            label="Printed"
            name="isPrinted"
            source="isPrinted"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <CustomDateInput
            source="printedDate"
            label="Printed Date"
            name="printedDate_inc"
            alwaysOn
          />
          <DateRangeFilter
            label="Updated"
            source="updatedAt"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            label="Updated By"
            name="updatedBy_inc"
            source="updatedBy"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Updated By Name"
            name="updatedByName_inc"
            source="updatedByName"
            fullWidth
            alwaysOn
          />
        </Filter>
        <Button
          label="Clear filters"
          onClick={handleClearFilters}
          id="clearFilterButton"
        />
      </CardContent>
    </Card>
  );
};

export default CertDetailsSidebarFilters;
