import React, { ReactElement, useMemo, Fragment } from 'react';
import { Datagrid, Identifier, TextField, useGetList } from 'react-admin';
import { Box, Typography } from '@mui/material';
import { UserContext, getContext } from '../../../../provider/userContext';
import DownloadButton from '../../../common/DownloadButton';
import PreviewButton from '../../../common/PreviewButton';
import { ListWithPagination } from '../../../common/ListWithPagination';
import commonStyles from '../../../common/commonStyles';
import { BookSection } from '../../../bookSection/type';
import { SectionType } from '../../../bookSection/constants';
import { RESOURCE_ATTACHMENT, RESOURCE_BOOK_SECTION } from '../../../constants';
import ResourceAndIdField from '../ResourceAndIdField';
import { SelectionItem } from '../../types';
import { styles } from '../../styles';
import AttachmentSelectButton from './AttachmentSelectButton';
import AttachmentSelectionFilters from './AttachmentSelectionFilters';

interface AttachmentSelectionProps {
  bookId: Identifier;
  onSelectAttachments: (items: SelectionItem[]) => void;
}

export const AttachmentSelection: React.FC<AttachmentSelectionProps> = ({
  bookId,
  onSelectAttachments,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const { data: bookSections } = useGetList<BookSection>(
    RESOURCE_BOOK_SECTION,
    {
      filter: {
        bookId,
        sectionType: SectionType.ATTACHMENT,
      },
    }
  );

  const attachmentTypes = useMemo(() => {
    const attachmentSections =
      bookSections?.filter((section) => section.attachmentType) || [];

    return attachmentSections?.reduce(
      (attachmentTypes, currentValue) =>
        Array.from(
          new Set([...attachmentTypes, ...currentValue.attachmentType])
        ),
      []
    );
  }, [bookSections]);

  if (!attachmentTypes?.length) {
    return (
      <Typography variant={'body2'} sx={{ marginTop: '30px' }}>
        Book has no attachment type section
      </Typography>
    );
  }

  return (
    <Fragment>
      <Typography
        sx={styles.selectListTitle}
        variant={'body1'}
        color={'textSecondary'}
      >
        Select Attachment(s)
      </Typography>
      <ListWithPagination
        key={bookId}
        title={<Fragment />}
        resource={RESOURCE_ATTACHMENT}
        filter={{
          projectId: ctx.projectId,
        }}
        disableSyncWithLocation
        filterDefaultValues={{ subtypeId: attachmentTypes, isDeleted: false }}
        actions={<AttachmentSelectionFilters />}
        sx={styles.attachmentFilterActions}
        storeKey="attachmentSelection"
      >
        <Datagrid
          bulkActionButtons={
            <AttachmentSelectButton onSelect={onSelectAttachments} />
          }
          rowClick={false}
        >
          <TextField source="fileName" label="File Name" sortable={false} />
          <TextField
            source="subtype.subtype"
            label="Attachment Type"
            sortable={false}
          />
          <ResourceAndIdField />
          <Box sx={commonStyles.columnActionsBox}>
            <PreviewButton />
            <DownloadButton />
          </Box>
        </Datagrid>
      </ListWithPagination>
    </Fragment>
  );
};

export default AttachmentSelection;
