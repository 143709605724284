import React, { Fragment, ReactElement, useState } from 'react';
import { FormTabProps } from 'react-admin';
import Tab from './components/Tab';

const LazyTab: React.FC<FormTabProps> = (props): ReactElement => {
  const [loaded, setLoaded] = useState(false);

  if (!loaded && (props.intent === 'header' || !props.hidden)) {
    setLoaded(true);
  }

  return loaded ? <Tab {...props} /> : <Fragment />;
};

LazyTab.displayName = 'LazyTab';

export default LazyTab;
