import React, { ReactElement } from 'react';
import { useRecordContext } from 'react-admin';
import PendingPunchEdit from '../../pendingPunch/PendingPunchEdit';
import { RESOURCE_SMART_TAG_ITR_REVIEW } from '../../constants';

const SmartPunchReviewEdit: React.FC = (): ReactElement => {
  const record = useRecordContext();

  return (
    <PendingPunchEdit
      id={record?.id}
      redirect={false}
      syncWithLocation={false}
      footerToolbarProps={{
        cancelButtonResource: RESOURCE_SMART_TAG_ITR_REVIEW,
      }}
    />
  );
};

export default SmartPunchReviewEdit;
