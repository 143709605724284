import { GanttComponent } from '@syncfusion/ej2-react-gantt';
import { useEffect, useMemo, useRef } from 'react';
import { exportProps } from './constants';
import { GanttApi } from './types';

const useGantt = () => {
  /*
  Provides 'ganttApi' which is a sort of adapter over the reference to
  the original ej2 'GanttComponent' in order to not spread original reference
  and everything closely related to ej2 settings all over the code.
  Plus provides 'ganttInstanceRef' in order to connect to the 'GanttComponent'
  via the 'ref' property.

  So you set 'ganttInstanceRef' to the ej2 'GanttComponent' but use 'ganttApi'
  where you need to trigger any available ej2 GanttComponent methods

  For more methods see https://helpej2.syncfusion.com/react/documentation/api/gantt/#methods
   */
  const ganttInstanceRef = useRef<GanttComponent>(null);

  useEffect(() => {
    // Disable taskbar double-click to prevent builtin dialog opening;
    const ganttEditModule = ganttInstanceRef.current.editModule;
    if (ganttEditModule?.dialogModule) {
      ganttEditModule.dialogModule.openEditDialog = () => {};
    }
  }, [ganttInstanceRef]);

  const ganttApi = useMemo<GanttApi>(
    () => ({
      pdfExport: () => {
        ganttInstanceRef.current.pdfExport(exportProps).then(() => {
          ganttInstanceRef.current.refresh();
        });
      },
      search: (value: string) => {
        ganttInstanceRef.current.search(value);
      },
      undo: () => {
        ganttInstanceRef.current.undo();
      },
      redo: () => {
        ganttInstanceRef.current.redo();
      },
      clear: () => {
        ganttInstanceRef.current.clearUndoCollection();
        ganttInstanceRef.current.clearRedoCollection();
        ganttInstanceRef.current.clearSelection();
      },
      containsUndoActions: () => {
        return ganttInstanceRef.current.getUndoActions().length > 0;
      },
      containsRedoActions: () => {
        return ganttInstanceRef.current.getRedoActions().length > 0;
      },
      updateDataSource: (
        data: object[],
        projectStartDate: Date | string,
        projectEndDate: Date | string
      ) => {
        ganttInstanceRef.current.updateDataSource(data, {
          projectStartDate,
          projectEndDate,
        });
      },
      clearSelection: () => {
        ganttInstanceRef.current.clearSelection();
      },
    }),
    [ganttInstanceRef]
  );

  return { ganttApi, ganttInstanceRef };
};

export default useGantt;
