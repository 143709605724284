import React, { ReactElement } from 'react';
import {
  Annotation,
  BookmarkView,
  FormFields,
  Inject,
  LinkAnnotation,
  Magnification,
  Navigation,
  PdfViewerComponent,
  Print,
  TextSearch,
  TextSelection,
  ThumbnailView,
  Toolbar,
} from '@syncfusion/ej2-react-pdfviewer';
import { Identifier } from 'react-admin';

interface PdfViewerProps {
  id?: Identifier;
  style?: Record<string, string | number>;
  url: string;
}

const PdfViewer: React.FC<PdfViewerProps> = ({
  id = '',
  url,
  ...rest
}): ReactElement => (
  <PdfViewerComponent
    style={{ height: '100%', width: '100%' }}
    {...rest}
    id={`container_${id}`}
    resourceUrl="https://cdn.syncfusion.com/ej2/28.2.5/dist/ej2-pdfviewer-lib"
    documentPath={url}
    toolbarSettings={{
      showTooltip: true,
      toolbarItems: [
        'PageNavigationTool',
        'MagnificationTool',
        'PanTool',
        'SelectionTool',
        'SearchOption',
        'PrintOption',
      ],
      annotationToolbarItems: [],
      formDesignerToolbarItems: [],
    }}
  >
    <Inject
      services={[
        Magnification,
        Toolbar,
        Navigation,
        ThumbnailView,
        TextSearch,
        TextSelection,
        LinkAnnotation,
        ThumbnailView,
        BookmarkView,
        Print,
        Annotation,
        FormFields,
      ]}
    />
  </PdfViewerComponent>
);

export default PdfViewer;
