import { fieldFromResource } from '../common/hooks/usePageShortInfo';
import {
  RESOURCE_ACTIVITY,
  RESOURCE_BOOK,
  RESOURCE_CERT_DETAIL,
  RESOURCE_CERT_TEMPLATE,
  RESOURCE_COMPONENT,
  RESOURCE_COMPONENT_TEST,
  RESOURCE_DRAWING,
  RESOURCE_MATERIAL,
  RESOURCE_MATERIAL_PURCHASE_ORDER,
  RESOURCE_MATERIAL_RELEASED,
  RESOURCE_MOC,
  RESOURCE_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_PROJECT,
  RESOURCE_PUNCH,
  RESOURCE_PUNCH_TEMPLATE,
  RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_SMART_PUNCH_REVIEW,
  RESOURCE_SMART_TAG_ITR,
  RESOURCE_SUBSYSTEM,
  RESOURCE_SYSTEM,
  RESOURCE_TEST,
  RESOURCE_TEST_EQUIPMENT,
  RESOURCE_TEST_EQUIPMENT_CHECKOUT,
  RESOURCE_SMART_CERT_DETAIL,
} from '../constants';

export enum SortOrder {
  TAG = 'TAG',
  ITR = 'ITR',
}

export enum BookType {
  TABS = 'TABS',
  TAGS = 'TAGS',
  SCANS = 'SCANS',
}

export enum GenerateType {
  ZIP = 'ZIP',
  PDF = 'PDF',
  BOTH = 'BOTH',
}

export const PROPERTY_ID_BY_RESOURCE_NAME = {
  [RESOURCE_SUBSYSTEM]: 'subsystemId',
  [RESOURCE_SYSTEM]: 'systemId',
  [RESOURCE_DRAWING]: 'drawingId',
  [RESOURCE_COMPONENT]: 'componentId',
  [RESOURCE_COMPONENT_TEST]: 'componentTestId',
  [RESOURCE_TEST]: 'testId',
  [RESOURCE_PUNCH]: 'punchId',
  [RESOURCE_MOC]: 'mocId',
  [RESOURCE_PROJECT]: 'refProjectId',
  [RESOURCE_CERT_TEMPLATE]: 'certTemplateId',
  [RESOURCE_CERT_DETAIL]: 'certDetailId',
  [RESOURCE_PRESERVATION_ITEM_ACTIVITY]: 'preservationItemActivityId',
  [RESOURCE_SMART_PUNCH_REVIEW]: 'pendingPunchId',
  [RESOURCE_BOOK]: 'bookId',
  [RESOURCE_PUNCH_TEMPLATE]: 'punchTemplateId',
  [RESOURCE_MATERIAL]: 'materialId',
  [RESOURCE_MATERIAL_RELEASED]: 'materialReleasedId',
  [RESOURCE_MATERIAL_PURCHASE_ORDER]: 'materialPurchaseOrderId',
  [RESOURCE_TEST_EQUIPMENT]: 'testEquipmentId',
  [RESOURCE_TEST_EQUIPMENT_CHECKOUT]: 'testEquipmentCheckoutId',
  [RESOURCE_ACTIVITY]: 'activityId',
  [RESOURCE_SMART_TAG_ITR]: 'componentTestId',
  [RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY]: 'preservationItemActivityId',
  [RESOURCE_SMART_CERT_DETAIL]: 'certDetailId',
};

export const SUBSYSTEM_FILTER_RESOURCE = {
  id: 'subsystemId',
  name: 'SubSystem',
  filter: 'project',
  field: fieldFromResource[RESOURCE_SUBSYSTEM],
};

export const RESOURCE_CHOICES_FILTER_OUT = [
  'certTemplateId',
  'punchTemplateId',
];
