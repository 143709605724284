import React, { Fragment, ReactElement, useCallback, useEffect } from 'react';
import { maxLength, useCreate, useGetList } from 'react-admin';
import Box from '@mui/material/Box';
import { getContext, UserContext } from '../../../provider/userContext';
import { ContactCategories } from '../../common/contactInput/constants';
import PunchPriorityInput from '../../common/PunchPriorityInput';
import PunchCategoryInput from '../../common/PunchCategoryInput';
import DisciplineInput from '../../common/DisciplineInput';
import CustomDateInput from '../../common/CustomDateInput';
import SubsystemInput from '../../common/SubsystemInput';
import LocationInput from '../../common/LocationInput';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_CONTACT } from '../../constants';
import TextInput from '../../common/TextInput';
import { required } from '../../../utils/UtilityFunctions';

interface PendingPunchFormProps {
  enabled?: boolean;
  readonly?: boolean;
}

const PendingPunchForm: React.FC<PendingPunchFormProps> = ({
  enabled = false,
  readonly = false,
}): ReactElement => {
  const [create] = useCreate();
  const ctx: UserContext = getContext();

  const { data: contacts } = useGetList(
    RESOURCE_CONTACT,
    { filter: { nickname: ctx?.fullName } },
    { enabled: enabled }
  );

  const createContact = useCallback(() => {
    create(RESOURCE_CONTACT, {
      data: {
        nickname: ctx?.fullName,
        projectId: ctx?.projectId,
        category: ContactCategories.punchSignOff,
      },
    });
  }, [create, ctx]);

  useEffect(() => {
    if (contacts && contacts.length === 0) {
      createContact();
    }
  }, [contacts, createContact]);

  return (
    <Fragment>
      <Box
        sx={{
          ...commonStyles.flexBox,
          ...commonStyles.fullWidth,
          flexWrap: 'wrap',
        }}
      >
        <PunchPriorityInput
          source="priorityId"
          isRequired
          disabled={!!readonly}
        />
        <PunchCategoryInput source="punchCatId" disabled={!!readonly} />
      </Box>
      <Box sx={{ ...commonStyles.flexBox, flexWrap: 'wrap' }}>
        <TextInput
          source="description"
          label="Description"
          fullWidth
          multiline
          validate={[required(), maxLength(3000)]}
          disabled={!!readonly}
        />
      </Box>
      <Box sx={{ ...commonStyles.flexBox, flexWrap: 'wrap' }}>
        <TextInput
          source="comments"
          label="Review Comments"
          fullWidth
          multiline
          validate={[maxLength(255)]}
          disabled={!!readonly}
        />
      </Box>
      <Box
        sx={{
          ...commonStyles.flexBox,
          ...commonStyles.fullWidth,
          flexWrap: 'wrap',
        }}
      >
        <SubsystemInput disabled />
        <LocationInput disabled />
      </Box>
      <Box
        sx={{
          ...commonStyles.flexBox,
          ...commonStyles.fullWidth,
          flexWrap: 'wrap',
        }}
      >
        <DisciplineInput disabled />
        <TextInput
          source="raisedBy"
          label="Raised By"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <CustomDateInput
          source="raisedDate"
          label="Raised Date"
          sx={commonStyles.flexBoxItem}
          disabled
        />
      </Box>
    </Fragment>
  );
};

export default PendingPunchForm;
