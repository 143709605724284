import React, {
  useCallback,
  useState,
  useEffect,
  Fragment,
  ReactElement,
} from 'react';
import { useRedirect } from 'react-admin';
import { Autocomplete, TextField } from '@mui/material';
import commonStyles from '../common/commonStyles';
import { getContext } from '../../provider/userContext';
import useProjectListForSwitch from './hooks/useProjectListForSwitch';
import { Project } from './types';
import useChangeProject from './hooks/useChangeProject';

interface ProjectSwitchProps {
  onProjectSelectionOpen: () => void;
  updateProjectCount: (value) => void;
}

const ProjectSwitch: React.FC<ProjectSwitchProps> = ({
  onProjectSelectionOpen,
  updateProjectCount,
}): ReactElement => {
  const ctx = getContext();
  const redirect = useRedirect();
  const [currentProject, setCurrentProject] = useState<Project | null>(null);

  const changeProject = useChangeProject({ skipRefreshCompanyContext: true });

  const { projects, totalProjects, projectsAreLoading } =
    useProjectListForSwitch();

  useEffect(() => {
    if (Array.isArray(projects) && ctx?.projectId) {
      projects.map((item) => {
        if (item.id === ctx?.projectId) {
          setCurrentProject(item);
        }
      });
    }
  }, [ctx?.projectId, projects]);

  const handleSwitchChange = useCallback(
    async (e, value: Project) => {
      await changeProject(Number(value.id));
      redirect('/');
    },
    [changeProject, redirect]
  );

  useEffect(() => {
    // User just logged in
    if (!ctx?.projectId) {
      onProjectSelectionOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!projectsAreLoading) {
      updateProjectCount(totalProjects);
    }
  }, [projectsAreLoading, totalProjects, updateProjectCount]);

  return (
    <Fragment>
      {projects?.length && currentProject && (
        <Autocomplete
          value={currentProject}
          onChange={handleSwitchChange}
          options={projects}
          getOptionLabel={(option) => option.project}
          isOptionEqualToValue={(option, value) =>
            option.project === value.project
          }
          renderInput={(params) => <TextField {...params} />}
          disableClearable
          sx={{
            ...commonStyles.flexBoxItem,
            '& button, & input': { color: 'white' },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white !important',
            },
          }}
        />
      )}
    </Fragment>
  );
};

export default ProjectSwitch;
