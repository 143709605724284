import React, { Fragment, ReactElement } from 'react';
import CommentList from '../comment/CommentList';
import CommentCreate from '../comment/CommentCreate';
import { RESOURCE_COMMENT } from '../constants';
import useCanAccess from './hooks/useCanAccess';

export interface CommentFormTabProps {
  targetId: string;
  commentType: string;
  transformFunc?: (data, recordId) => object;
}

const CommentFormTab: React.FC<CommentFormTabProps> = ({
  targetId,
  commentType,
  transformFunc,
}): ReactElement => {
  const { edit: canEditComment } = useCanAccess(RESOURCE_COMMENT);

  return (
    <Fragment>
      <CommentList
        targetId={targetId}
        commentType={commentType}
        withActions={canEditComment}
      />
      {canEditComment && <CommentCreate transformFunc={transformFunc} />}
    </Fragment>
  );
};

export default CommentFormTab;
