import {
  AccordionDetails,
  Accordion as AccordionMui,
  AccordionSummary,
  Typography,
} from '@mui/material';
import React, { PropsWithChildren, ReactElement } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface AccordionProps {
  label: string | ReactElement;
  alwaysOn?: boolean;
}

const Accordion: React.FC<PropsWithChildren<AccordionProps>> = ({
  children,
  label,
}): ReactElement => {
  return (
    <AccordionMui>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1-header">
        {typeof label === 'string' ? <Typography>{label}</Typography> : label}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </AccordionMui>
  );
};

export default Accordion;
