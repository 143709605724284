import React, { ReactElement } from 'react';
import { TextField, DateField } from 'react-admin';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import CustomBooleanField from '../common/CustomBooleanField';
import ListWithTitle from '../common/ListWithTitle';
import { RESOURCE_ASSIGN_ITR, RESOURCE_SUBSYSTEM } from '../constants';
import ColumnActions from './components/ColumnActions';
import ListActions from './components/ListActions';
import SubSystemEdit from './AssignITREdit';
import BulkActionButtons from './components/BulkActionButtons';

const AssignITRList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      resource={RESOURCE_SUBSYSTEM}
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={<ListActions />}
      sort={{ field: 'subsystem', order: 'ASC' }}
      storeKey={'assign-itr.listParams'}
    >
      <DatagridConfigurableRbac
        preferenceKey={RESOURCE_ASSIGN_ITR}
        EditComponent={<SubSystemEdit syncWithLocation={false} />}
        bulkActionButtons={<BulkActionButtons />}
      >
        <TextField source="id" label="ID" />
        <TextField source="subsystem" label="SubSystem" />
        <TextField source="description" label="Description" />
        <CustomBooleanField source="isAssignItr" label="Populate" />
        <DateField source="assignItrDateTime" label="Date Populated" />
        <TextField source="itrAssigner" label="Populated By" />
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default AssignITRList;
