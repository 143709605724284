import Box from '@mui/material/Box';
import React, { ReactElement } from 'react';
import { BooleanInput, maxLength } from 'react-admin';
import { required } from '../../utils/UtilityFunctions';
import commonStyles from '../common/commonStyles';
import CustomDateInput from '../common/CustomDateInput';
import CustomNumberInput from '../common/CustomNumberInput';
import TestEquipmentTypeInput from '../common/TestEquipmentTypeInput';
import TextInput from '../common/TextInput';
import { validateDependentDate } from '../common/validators';
import VendorInput from '../common/VendorInput/VendorInput';
import styles from './styles';

interface TestEquipmentFormProps {
  bulkEditMode?: boolean;
  editMode?: boolean;
}

const TestEquipmentForm: React.FC<TestEquipmentFormProps> = ({
  bulkEditMode,
  editMode,
}): ReactElement => {
  const requiredValidation = bulkEditMode ? [] : [required()];
  const isRequired = !bulkEditMode;

  return (
    <Box sx={commonStyles.columnFlexBox}>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="testEquipment"
          label="Test Equipment Name"
          sx={styles.flexBoxItem}
          validate={[...requiredValidation, maxLength(100)]}
        />
        <TextInput
          source="manufacturer"
          label="Manufacturer"
          sx={styles.flexBoxLongItem}
          validate={[...requiredValidation, maxLength(100)]}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="model"
          label="Model"
          sx={styles.flexBoxItem}
          validate={[...requiredValidation, maxLength(50)]}
        />
        <TextInput
          source="serialNumber"
          label="Serial Number"
          sx={styles.flexBoxItem}
          validate={[...requiredValidation, maxLength(50)]}
        />
        <BooleanInput source="rent" label="Rent" sx={styles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInput
          source="calibrationDate"
          label="Calibration Date"
          sx={styles.flexBoxItem}
          validate={requiredValidation}
          isRequired={isRequired}
        />
        <CustomDateInput
          source="calibrationExpirationDate"
          label="Calibration Expiration Date"
          sx={styles.flexBoxItem}
          validate={[
            ...requiredValidation,
            validateDependentDate('calibrationDate', 'Calibration Date'),
          ]}
          isRequired={isRequired}
        />
        <TestEquipmentTypeInput
          sx={styles.flexBoxItem}
          source="testEquipmentTypeId"
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomNumberInput
          source="cost"
          label="Cost"
          sx={styles.flexBoxItem}
          isPositive
          max={9999999999}
        />
        <VendorInput label="Vendor" sx={styles.flexBoxItem} />
        {editMode && (
          <BooleanInput
            source="testEquipmentAvailability.available"
            label="Available"
            sx={styles.flexBoxItem}
            disabled
          />
        )}
      </Box>
    </Box>
  );
};

export default TestEquipmentForm;
