import React, { ReactElement, useMemo, useCallback } from 'react';
import {
  AutocompleteInput,
  AutocompleteArrayInput,
  GetListParams,
  ReferenceInput,
  ReferenceArrayInput,
} from 'react-admin';
import TextFieldWithFilterOption from './additionalSearchFilter/TextFieldWithFilterOption';
import { useAdditionalSearchFilter } from './additionalSearchFilter/useAdditionalSearchFilter';
import { LOOKUP_PAGE_SIZE } from './constants';

type CombinedComponentProps = React.ComponentProps<typeof ReferenceInput> &
  React.ComponentProps<typeof ReferenceArrayInput> &
  React.ComponentProps<typeof AutocompleteInput> &
  React.ComponentProps<typeof AutocompleteArrayInput>;

export interface AutocompleteReferenceInputProps
  extends Omit<Partial<CombinedComponentProps>, 'children'> {
  resource: string;
  requestParams?: Partial<GetListParams>;
  disabled?: boolean;
  handleOnChange?: (values, records) => void;
  includeFields?: string[];
  excludeFields?: string[];
  additionalParams?: Record<string, unknown>;
  withAdditionalFilter?: boolean;
}

const AutocompleteReferenceInput: React.FC<AutocompleteReferenceInputProps> = (
  props
): ReactElement => {
  const {
    multiple,
    source,
    resource,
    validate,
    enableGetChoices,
    requestParams,
    label,
    name,
    page,
    perPage = LOOKUP_PAGE_SIZE,
    queryOptions: paramsQueryOptions,
    disabled,
    handleOnChange,
    id,
    includeFields,
    excludeFields,
    additionalParams,
    withAdditionalFilter = true,
    ...rest
  } = props;

  const { filter, sort } = requestParams ?? {};
  const { meta: paramsMeta } = paramsQueryOptions ?? {};
  const {
    menuOpen,
    isInputFocused,
    filterType,
    addFilterAnchor,
    getFilterName,
    handleChangeFilterType,
    handleInputFocus,
    handleInputBlur,
    handleMenuOpen,
    handleMenuClose,
    menuRef,
  } = useAdditionalSearchFilter();

  const queryOptions = useMemo(
    () => ({
      ...paramsQueryOptions,
      meta: {
        ...paramsMeta,
        ...additionalParams,
        includeFields,
        excludeFields,
      },
    }),
    [
      additionalParams,
      paramsMeta,
      paramsQueryOptions,
      includeFields,
      excludeFields,
    ]
  );

  const referenceProps = {
    source,
    reference: resource,
    filter,
    label,
    page,
    perPage,
    queryOptions,
    sort,
    enableGetChoices: disabled ? () => false : enableGetChoices,
  };

  const renderInput = useCallback(
    (params) => (
      <TextFieldWithFilterOption
        {...{
          ...params,
          id,
          source,
          label,
          validate,
          menuOpen,
          isInputFocused,
          filterType,
          handleChangeFilterType,
          handleInputFocus,
          handleInputBlur,
          handleMenuOpen,
          handleMenuClose,
          menuRef,
        }}
      />
    ),
    [
      filterType,
      handleChangeFilterType,
      handleInputBlur,
      handleInputFocus,
      handleMenuClose,
      handleMenuOpen,
      id,
      isInputFocused,
      label,
      menuOpen,
      menuRef,
      source,
      validate,
    ]
  );

  const autocompleteProps = {
    ...rest,
    source,
    resource,
    label,
    disabled,
    validate,
    onChange: handleOnChange,
    ...(withAdditionalFilter && { renderInput }),
  };

  const ReferenceComponent = multiple ? ReferenceArrayInput : ReferenceInput;
  const AutocompleteComponent = multiple
    ? AutocompleteArrayInput
    : AutocompleteInput;

  const filterToQuery = useCallback(
    (value) => {
      return {
        [getFilterName(name)]: addFilterAnchor(value),
      };
    },
    [addFilterAnchor, getFilterName, name]
  );

  return (
    <ReferenceComponent {...referenceProps}>
      <AutocompleteComponent
        {...autocompleteProps}
        id={id || autocompleteProps.source}
        filterToQuery={name ? filterToQuery : undefined}
      />
    </ReferenceComponent>
  );
};

export default AutocompleteReferenceInput;
