import React, { ReactElement } from 'react';
import { EditRowButton } from '@react-admin/ra-editable-datagrid';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import DeleteWithConfirmIconButton from '../common/DeleteWithConfirmIconButton';
import DownloadButton from '../common/DownloadButton';
import PreviewButton from '../common/PreviewButton';

const CertDetailsActions: React.FC = (): ReactElement => (
  <Box sx={commonStyles.columnActionsBox}>
    <PreviewButton />
    <DownloadButton />
    <EditRowButton />
    <DeleteWithConfirmIconButton />
  </Box>
);

export default CertDetailsActions;
