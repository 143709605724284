import React, { Fragment, ReactElement, useEffect, useMemo } from 'react';
import {
  maxLength,
  maxValue,
  minValue,
  NumberInput,
  SelectInput,
} from 'react-admin';
import Box from '@mui/material/Box';
import { useFormContext, useWatch } from 'react-hook-form';
import commonStyles from '../../common/commonStyles';
import TextInput from '../../common/TextInput';
import { required } from '../../../utils/UtilityFunctions';
import {
  Industry,
  INDUSTRY_CHOICES,
  LOCATION_CATEGORY_CHOICES,
  PROJECT_TYPE_CHOICES,
  SUB_INDUSTRY_CHOICES_LNG,
  SUB_INDUSTRY_CHOICES_OILGAS,
  SUB_INDUSTRY_CHOICES_POWER,
  SUB_INDUSTRY_CHOICES_RENEWABLES,
} from '../constants';

const ProjectFormDetailsTab: React.FC = (): ReactElement => {
  const industry = useWatch({ name: 'industry' });
  const subIndustry = useWatch({ name: 'subIndustry' });
  const { setValue } = useFormContext();

  const subIndustryChoices = useMemo(() => {
    switch (industry) {
      case Industry.POWER:
        return SUB_INDUSTRY_CHOICES_POWER;
      case Industry.OIL_GAS:
        return SUB_INDUSTRY_CHOICES_OILGAS;
      case Industry.RENEWABLES:
        return SUB_INDUSTRY_CHOICES_RENEWABLES;
      case Industry.LNG:
        return SUB_INDUSTRY_CHOICES_LNG;
      default:
        return [];
    }
  }, [industry]);

  useEffect(() => {
    if (
      subIndustry &&
      !subIndustryChoices.map((choice) => choice.id).includes(subIndustry)
    ) {
      setValue('subIndustry', '', { shouldDirty: true });
    }
  }, [subIndustryChoices, setValue, subIndustry]);

  return (
    <Fragment>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="documentNumber"
          label="Document Number"
          validate={[maxLength(35)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="owner"
          label="Owner"
          validate={[maxLength(55)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="gsap"
          label="GSAP"
          validate={[maxLength(35)]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <NumberInput
          source="workDays"
          label="Work Days"
          defaultValue={5}
          validate={[required(), minValue(1), maxValue(7)]}
          sx={commonStyles.flexBoxItem}
        />
        <NumberInput
          source="workHours"
          label="Work Hours"
          defaultValue={8}
          validate={[required(), minValue(1), maxValue(24)]}
          sx={commonStyles.flexBoxItem}
        />
        <NumberInput
          source="shifts"
          label="Shifts"
          defaultValue={1}
          validate={[required(), minValue(1), maxValue(5)]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <SelectInput
          source="industry"
          label="Industry"
          choices={INDUSTRY_CHOICES}
          sx={commonStyles.flexBoxItem}
          validate={required()}
        />
        <SelectInput
          source="subIndustry"
          label="Subindustry"
          readOnly={!industry}
          choices={subIndustryChoices}
          validate={required()}
          sx={commonStyles.flexBoxItem}
        />
        <SelectInput
          source="projectType"
          label="Project Type"
          choices={PROJECT_TYPE_CHOICES}
          validate={required()}
          sx={commonStyles.flexBoxItem}
        />
        <SelectInput
          source="locationCategory"
          label="Location Category"
          choices={LOCATION_CATEGORY_CHOICES}
          validate={required()}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
    </Fragment>
  );
};

export default ProjectFormDetailsTab;
