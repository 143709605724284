import React, { Fragment, ReactElement, useState, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Labeled,
  SimpleForm,
  useResourceContext,
  useUnselectAll,
  useListContext,
  useUpdateMany,
  useNotify,
} from 'react-admin';
import AssignedWithCreateInput from './Assigned/AssignedWithCreateInput';
import { ContactCategories } from './contactInput/constants';
import commonStyles from './commonStyles';
import ModalDialog from './ModalDialog';

interface AssignResponsibleButtonProps {
  category: ContactCategories;
  label?: string;
}

const AssignResponsibleButton: React.FC<AssignResponsibleButtonProps> = ({
  label,
  category,
}): ReactElement => {
  const queryClient = useQueryClient();
  const notify = useNotify();
  const resource = useResourceContext();
  const [updateMany] = useUpdateMany();
  const unselectAll = useUnselectAll(resource);
  const { selectedIds } = useListContext();
  const [open, setOpen] = useState(false);
  const openDialog = useCallback(() => setOpen(true), []);
  const closeDialog = useCallback(() => setOpen(false), []);

  const submit = useCallback(
    async (data) => {
      await updateMany(
        resource,
        {
          ids: selectedIds,
          data,
        },
        {
          onSuccess: async () => {
            queryClient.invalidateQueries({ queryKey: [resource] });
            notify(`${selectedIds.length} elements updated`, {
              type: 'success',
            });
            unselectAll();
          },
        }
      );
      closeDialog();
    },
    [
      closeDialog,
      unselectAll,
      selectedIds,
      notify,
      updateMany,
      queryClient,
      resource,
    ]
  );

  return (
    <Fragment>
      <Button label={label} onClick={openDialog} />

      <ModalDialog title="Update Responsible" open={open} onClose={closeDialog}>
        <SimpleForm id="edit_smart_form" onSubmit={submit}>
          <Labeled
            label="Select a Responsible Person"
            sx={commonStyles.flexBoxLongItem}
          >
            <AssignedWithCreateInput
              label=""
              sx={commonStyles.flexBoxLongItem}
              category={category}
            />
          </Labeled>
        </SimpleForm>
      </ModalDialog>
    </Fragment>
  );
};

AssignResponsibleButton.defaultProps = { label: 'Assign Responsible' };

export default AssignResponsibleButton;
