import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import ActivityInput, { ActivityInputProps } from './ActivityInput';

const PredecessorActivityInput: React.FC<ActivityInputProps> = (
  props
): ReactElement => {
  const { setValue } = useFormContext();

  const handleOnChange = (id, activity) => {
    setValue('predecessor', activity);
  };

  return <ActivityInput {...props} handleOnChange={handleOnChange} />;
};

export default PredecessorActivityInput;
