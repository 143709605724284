import React, { ReactElement } from 'react';
import ChoicesLegend from '../../common/choicesLegend/ChoicesLegend';

const InheritCertSignOffsLegend: React.FC = (): ReactElement => (
  <ChoicesLegend
    title="Inherit Cert Sign Offs Settings Descriptions"
    items={[
      {
        name: 'Yes',
        description:
          'Selecting "Yes" will cause the system or ' +
          'subsystem or component to use the "Completed Date" entered ' +
          'during the QR import process to close out the Activity by automatically ' +
          'updating the "Actual Completion Date" field that is associated with that ' +
          'Activity and Certificate.',
        isDefault: true,
      },
      {
        name: 'No',
        description:
          'Selecting "No" requires a user to manually update the ' +
          '"Actual Completion Date" field associated with the Activity to close it out.',
      },
    ]}
  />
);

export default InheritCertSignOffsLegend;
