import React, { ReactElement } from 'react';
import { SimpleForm } from 'react-admin';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import { getContext, UserContext } from '../../provider/userContext';
import { required } from '../../utils/UtilityFunctions';
import AssignedWithCreateInput from '../common/Assigned/AssignedWithCreateInput';
import AutocompleteReferenceInput from '../common/AutocompleteReferenceInput';
import commonStyles from '../common/commonStyles';
import { ContactCategories } from '../common/contactInput/constants';
import CreateWithTitle from '../common/CreateWithTitle';
import CustomDateInput from '../common/CustomDateInput';
import FooterToolbar from '../common/FooterToolbar';
import { RESOURCE_TEST_EQUIPMENT } from '../constants';
import { TestEquipmentCheckout } from './types';

const TestEquipmentCheckoutCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();

  const transform = (data: TestEquipmentCheckout): TestEquipmentCheckout => ({
    ...data,
    projectId: ctx?.projectId,
  });

  return (
    <CreateWithTitle redirect="list" transform={transform}>
      <SimpleForm toolbar={<FooterToolbar />}>
        <Box sx={commonStyles.flexBox}>
          <AutocompleteReferenceInput
            label="Test Equipment"
            source="testEquipmentId"
            resource={RESOURCE_TEST_EQUIPMENT}
            requestParams={{
              filter: {
                projectId: ctx?.projectId,
                'testEquipmentAvailability->available': true,
              },
              sort: { field: 'testEquipment', order: 'ASC' as const },
            }}
            validate={required()}
            includeFields={[
              'id',
              'testEquipment',
              'manufacturer',
              'model',
              'serialNumber',
              'testEquipmentAvailability.available',
            ]}
            optionText={(choice) =>
              `${choice.testEquipment} :: ${choice.manufacturer} :: ${choice.model} :: ${choice.serialNumber}`
            }
            sx={commonStyles.flexBoxLongItem}
          />
          <AssignedWithCreateInput
            label="Responsible"
            category={ContactCategories.itrEmpWorkGrp}
            sx={commonStyles.flexBoxItem}
            isRequired
          />
          <CustomDateInput
            label="Check Out Date"
            source="checkOutDate"
            sx={commonStyles.flexBoxItem}
            defaultValue={dayjs()}
            maxDate={dayjs()}
            isRequired
          />
        </Box>
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default TestEquipmentCheckoutCreate;
