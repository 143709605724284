import { Identifier } from 'react-admin';
import get from 'lodash/get';
import { getContext, UserContext } from '../../provider/userContext';

export const canUserAccessProject = (projectId: Identifier): boolean => {
  const ctx: UserContext = getContext();
  const isCurrentUserProject = get(
    ctx,
    `companies.${ctx.company}.projects[${projectId}]`,
    false
  );

  return !!isCurrentUserProject;
};

export const isProjectCurrentProject = (projectId: Identifier): boolean => {
  const ctx: UserContext = getContext();
  const userCurrentProjectId = get(ctx, 'projectId', null);

  return userCurrentProjectId === projectId;
};

export const getProjectListFilterForCurrentUser = () => {
  const ctx = getContext();

  if (!ctx?.company) {
    return {};
  }

  return {
    isActive: true,
    id: ctx?.companies?.[ctx?.company]?.projectIds,
    divisionId: ctx?.companies?.[ctx?.company]?.defaultDivisionId,
  };
};
