import React, { ReactElement, useCallback } from 'react';
import { AutocompleteInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import AutocompleteReferenceInput from '../../../common/AutocompleteReferenceInput';
import commonStyles from '../../../common/commonStyles';
import { fieldFromResource } from '../../../common/hooks/usePageShortInfo';
import {
  RESOURCE_CHOICES,
  RESOURCE_NAMES,
} from '../../../attachment/admin/components/constants';
import { RESOURCE_PROJECT } from '../../../constants';
import { getContext, UserContext } from '../../../../provider/userContext';
import { RESOURCE_CHOICES_FILTER_OUT } from '../../constants';

interface AttachmentAdditionalFilterDialogProps {
  resource: { id: string; name: string; filter: string };
  setResource: (value) => void;
  setResourceValues: (value) => void;
  handleResourceValueChange: (values, records) => void;
  optionRenderer: (record) => string;
}

const AttachmentAdditionalFilterDialog: React.FC<
  AttachmentAdditionalFilterDialogProps
> = ({
  resource,
  setResource,
  setResourceValues,
  handleResourceValueChange,
  optionRenderer,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const { resetField } = useFormContext();
  const resourceChoices = RESOURCE_CHOICES.filter(
    (item) => !RESOURCE_CHOICES_FILTER_OUT.includes(item.id)
  ).sort((a, b) => a.name.localeCompare(b.name));

  const handleResourceChange = useCallback(
    (value, record) => {
      setResource(null);
      setResourceValues([]);
      resetField('resourceValue');

      if (value && record) {
        setTimeout(() => {
          setResource({
            ...record,
            field: fieldFromResource[RESOURCE_NAMES[value]],
          });
        }, 100);
      }
    },
    [resetField, setResource, setResourceValues]
  );

  const title =
    fieldFromResource?.[RESOURCE_NAMES?.[resource?.id]]?.[0] || 'id';

  return (
    <Box sx={commonStyles.flexBox}>
      <AutocompleteInput
        label="Resource"
        source="resource"
        choices={resourceChoices}
        onChange={handleResourceChange}
        sx={commonStyles.flexBoxSelectItem}
      />
      {resource ? (
        <AutocompleteReferenceInput
          label="Resource Value"
          resource={RESOURCE_NAMES[resource?.id]}
          source={resource?.id}
          id={resource?.id}
          optionText={optionRenderer}
          requestParams={{
            filter:
              resource?.filter === 'project'
                ? { projectId: ctx?.projectId }
                : resource?.filter === 'division'
                  ? { divisionId: ctx?.defaultDivisionId }
                  : {},
            sort: {
              field: title.includes('.') ? '`' + title + '`' : title,
              order: 'ASC' as const,
            },
          }}
          multiple
          handleOnChange={handleResourceValueChange}
          sx={commonStyles.flexBoxSelectItem}
          includeFields={[
            'id',
            ...(fieldFromResource?.[RESOURCE_NAMES?.[resource?.id]] || []),
          ]}
        />
      ) : (
        <AutocompleteInput
          label="Resource Value"
          resource={RESOURCE_PROJECT}
          source="projectId"
          id="projectId"
          optionText="project"
          sx={commonStyles.flexBoxSelectItem}
          disabled
        />
      )}
    </Box>
  );
};

export default AttachmentAdditionalFilterDialog;
