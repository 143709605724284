import React, { ReactElement } from 'react';
import { useRecordContext } from 'react-admin';
import Box from '@mui/material/Box';
import TabbedEditForm from '../../common/TabbedEditForm';
import LazyFormTab from '../../common/LazyFormTab';
import ImageGalleryFormTab from '../../common/ImageGalleryFormTab';
import { EditPropsWithLocation } from '../../common/types';
import commonStyles from '../../common/commonStyles';
import { PendingPunch } from '../types';
import PendingPunchForm from './PendingPunchForm';

interface PendingPunchEditProps extends EditPropsWithLocation<PendingPunch> {
  readonly?: boolean;
  outerResource?: string;
}

const PendingPunchEdit: React.FC<PendingPunchEditProps> = ({
  readonly,
  outerResource,
  ...rest
}): ReactElement => {
  const record = useRecordContext();

  return (
    <TabbedEditForm
      {...rest}
      id={record?.id}
      footerToolbarProps={{ cancelButtonResource: outerResource }}
    >
      <LazyFormTab
        label="Punch Details"
        id="pending_punch_tab"
        sx={commonStyles.formTabHeader}
      >
        <Box sx={commonStyles.pendingPunchEditForm}>
          <PendingPunchForm readonly={readonly} />
        </Box>
      </LazyFormTab>
      <LazyFormTab
        label="Image Gallery"
        id="pending_punch_gallery_tab"
        sx={commonStyles.formTabHeader}
      >
        <ImageGalleryFormTab
          targetId="pendingPunchId"
          onlyShow={readonly}
          outerResource={outerResource}
        />
      </LazyFormTab>
    </TabbedEditForm>
  );
};

export default PendingPunchEdit;
