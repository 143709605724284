import React, { Fragment, ReactElement, useCallback } from 'react';
import { GanttApi } from '../gantt/types';
import EditModeToolbar from './EditModeToolbar';
import ViewModeToolbar from './ViewModeToolbar';

interface Props {
  ganttApi: GanttApi;
  containsChanges: boolean;
  selectedId: number;
  onCleanState: () => void;
  onBulkSave: () => void;
  onDiscardAll: () => void;
  onDeselect: () => void;
}

const GanttChartToolbar: React.FC<Props> = ({
  ganttApi,
  containsChanges,
  selectedId,
  onCleanState,
  onBulkSave,
  onDiscardAll,
  onDeselect,
}): ReactElement => {
  const onExportToPdf = useCallback(() => {
    ganttApi.pdfExport();
  }, [ganttApi]);

  const onSearch = useCallback(
    (value: string) => {
      ganttApi.search(value);
    },
    [ganttApi]
  );

  const onUndo = useCallback(() => {
    ganttApi.undo();
  }, [ganttApi]);

  const onRedo = useCallback(() => {
    ganttApi.redo();
  }, [ganttApi]);

  return (
    <Fragment>
      <ViewModeToolbar
        selectedId={selectedId}
        onExportToPdf={onExportToPdf}
        onSearch={onSearch}
        onSelectedSuccess={onCleanState}
        onDeselect={onDeselect}
      />
      <EditModeToolbar
        visible={containsChanges}
        onUndo={onUndo}
        onRedo={onRedo}
        undoDisabled={ganttApi && !ganttApi.containsUndoActions()}
        redoDisabled={ganttApi && !ganttApi.containsRedoActions()}
        onDiscard={onDiscardAll}
        onBulkSave={onBulkSave}
        selectedId={selectedId}
        onDeselect={onDeselect}
      />
    </Fragment>
  );
};

export default GanttChartToolbar;
