import React, { ReactElement } from 'react';
import { MenuItemLink, useLogout, UserMenu as BaseUserMenu } from 'react-admin';
import packageJson from '../../../package.json';
import { clearLocalStorageSettings } from '../../utils/UtilityFunctions';
import checkSecurityLevel from '../common/helpers/checkSecurityLevel';
import useCanAccess from '../common/hooks/useCanAccess';
import { securityLevel } from '../common/constants';
import {
  CUSTOM_ROUTE_USER_PROFILE,
  RESOURCE_COMPANY_SUBSCRIPTION,
} from '../constants';
import CustomMenuItemLink from './CustomMenuItemLink';

interface UserMenuProps {
  recordingInProgress: boolean;
  onOpenSupportModal: () => void;
  onStartRecording: () => void;
  onStopRecording: () => void;
  onOpenCompanySwitch: () => void;
}

const UserMenu: React.FC<UserMenuProps> = ({
  recordingInProgress,
  onOpenSupportModal,
  onStartRecording,
  onStopRecording,
  onOpenCompanySwitch,
}): ReactElement => {
  const logout = useLogout();
  const canAccessCompanySubscription = useCanAccess(
    RESOURCE_COMPANY_SUBSCRIPTION
  );

  const handleRecordClick = () => {
    if (recordingInProgress) {
      onStopRecording();
    } else {
      onStartRecording();
    }
  };

  return (
    <BaseUserMenu>
      <CustomMenuItemLink
        to={CUSTOM_ROUTE_USER_PROFILE}
        primaryText="My Profile"
      />
      <CustomMenuItemLink
        to="#"
        primaryText="Switch Company/Project"
        onClick={onOpenCompanySwitch}
      />
      {checkSecurityLevel(securityLevel.admin) &&
        canAccessCompanySubscription.list && (
          <CustomMenuItemLink
            to={RESOURCE_COMPANY_SUBSCRIPTION}
            primaryText="Manage Subscriptions"
          />
        )}
      <MenuItemLink to="#" primaryText={`Version ${packageJson.version}`} />
      <CustomMenuItemLink
        to="#"
        primaryText={
          recordingInProgress
            ? 'Stop Recording'
            : 'Start Recording Support Ticket'
        }
        onClick={handleRecordClick}
      />
      <CustomMenuItemLink
        to="#"
        disabled={recordingInProgress}
        primaryText="Contact Support"
        onClick={onOpenSupportModal}
      />
      {/*
        TODO: This is temporary solution to clear search settings.
        It should be removed after couple releases
      */}
      <MenuItemLink
        to=""
        primaryText="Clear local settings"
        onClick={clearLocalStorageSettings}
      />
      <MenuItemLink
        to="https://tracker-check-web-static-v5.s3.us-east-2.amazonaws.com/terms_and_conditions.html"
        primaryText="Terms of Use"
        target="_blank"
      />
      <MenuItemLink
        to="https://tracker-check-web-static-v5.s3.us-east-2.amazonaws.com/privacy_policy.html"
        primaryText="Privacy Policy"
        target="_blank"
      />
      <MenuItemLink to="" primaryText="Logout" onClick={logout} />
    </BaseUserMenu>
  );
};

export default UserMenu;
