import React, { ReactElement, useCallback } from 'react';
import {
  TextField,
  useStore,
  useResourceContext,
  DateField,
  RowClickFunction,
} from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@mui/material';
import CertTemplateCategoryField from '../../common/CertTemplateCategoryField';
import renderMetaDataFields from '../../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../../common/DatagridConfigurableRbac';
import ActivityPhaseField from '../../common/ActivityPhaseField';
import CustomBooleanField from '../../common/CustomBooleanField';
import AssignedField from '../../common/Assigned/AssignedField';
import CertTemplateField from '../../common/CertTemplateField';
import DisciplineField from '../../common/DisciplineField';
import ComponentField from '../../common/ComponentField';
import ColumnActions from '../../common/ColumnActions';
import SelectField from '../../common/SelectField';
import SubsystemField from '../../common/SubsystemField';
import SystemField from '../../common/SystemField';
import { RESOURCE_ACTIVITY_TYPE } from '../../constants';
import { getRowStyle } from '../../certDetails/helpers';
import { statuses } from '../constants';
import ActivityTextField from '../../certDetails/components/ActivityTextField';
import ActivityField from '../../certDetails/components/ActivityField';
import useExpandRowOnMount from '../../common/hooks/useExpandRowOnMount';
import BulkActionButtons from './BulkActionButtons';

interface SmartCertDetailsListDatagridProps {
  defaultVisibleColumns: string[];
}

export const SmartCertDetailsListDatagrid: React.FC<
  SmartCertDetailsListDatagridProps
> = ({ defaultVisibleColumns }): ReactElement => {
  const queryClient = useQueryClient();
  const resource: string = useResourceContext();
  const [expanded, setExpanded] = useStore<number[]>(
    `${resource}.datagrid.expanded`,
    []
  );
  useExpandRowOnMount();
  const rowClick: RowClickFunction = useCallback(
    async (id, resource): Promise<false> => {
      if (!expanded.length || !expanded.includes(+id)) {
        await queryClient.invalidateQueries({
          queryKey: [resource, 'getOne', { id: String(id) }],
        });
        setExpanded([+id]);
      } else {
        setExpanded([]);
      }

      return false;
    },
    [expanded, setExpanded, queryClient]
  );

  const getRowStyles = useCallback(
    (record) => getRowStyle(record, expanded),
    [expanded]
  );

  return (
    <DatagridConfigurableRbac
      bulkActionButtons={<BulkActionButtons />}
      defaultVisibleColumns={defaultVisibleColumns}
      rowClick={rowClick}
      rowStyle={getRowStyles}
      hover
    >
      <TextField source="id" label="ID" />
      <SelectField source="status" label="Status" choices={statuses} />
      <CertTemplateField />
      <TextField source="certTemplate.description" label="Description" />
      <CertTemplateCategoryField
        source="certTemplate.category"
        label="Category"
        sortBy="certTemplate.category"
      />
      <ActivityField source="activity" label="Activity" sortable={false} />
      <ActivityTextField
        resource={RESOURCE_ACTIVITY_TYPE}
        source="activityTypeId"
        sortBy="activityType.activityType"
        label="Activity Type"
        sortable={false}
      />
      <ComponentField />
      <SystemField
        source="certDetailSubsystemSystem.finalSystemId"
        textSource="certDetailSubsystemSystem.systemName"
        sortBy="certDetailSubsystemSystem.system_name"
      />
      <SubsystemField
        source="certDetailSubsystemSystem.finalSubsystemId"
        textSource="certDetailSubsystemSystem.subsystem"
        sortBy="certDetailSubsystemSystem.subsystem"
      />
      <ActivityPhaseField />
      <DisciplineField />
      <CustomBooleanField source="isPrinted" label="Printed" />
      <DateField source="printedDate" label="Printed Date" />
      <TextField source="printedBy" label="Printed By" />
      <CustomBooleanField source="isSmart" label="Smart" />
      <TextField
        source="certTotalSignatures"
        label="Certificate Total Signatures"
      />
      <TextField source="certSigned" label="Certificate Signed" />
      <TextField source="certSignedBy" label="Certificate Signed By" />
      <AssignedField label="Responsible" sortable={false} />
      <CustomBooleanField source="isVerified" label="Verified" />
      <TextField source="generatedBy" label="Generated By" />
      <DateField source="generatedDate" label="Generated Date" />
      {renderMetaDataFields()}
      <ColumnActions
        label="Actions"
        showActions={{ edit: false, delete: false, show: false }}
      >
        <Button>Show/Hide</Button>
      </ColumnActions>
    </DatagridConfigurableRbac>
  );
};
