import React, { ReactElement } from 'react';
import ComponentField from '../common/ComponentField';
import ProjectField from '../common/ProjectField';
import SubsystemField from '../common/SubsystemField';
import SystemField from '../common/SystemField';

const renderCertificateCategoryNameField = (record): ReactElement => {
  switch (true) {
    case Boolean(record.systemId):
      return <SystemField />;
    case Boolean(record.subsystemId):
      return <SubsystemField />;
    case Boolean(record.componentId):
      return <ComponentField />;
    default:
      return <ProjectField />;
  }
};

export default renderCertificateCategoryNameField;
