import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_DISCIPLINE } from '../constants';
import { required } from '../../utils/UtilityFunctions';
import sideFilterStyles from './sideFilterStyles';
import commonStyles from './commonStyles';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';

interface DisciplineInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  isRequired?: boolean;
  withAllOption?: boolean;
}

const DisciplineInput: React.FC<DisciplineInputProps> = ({
  isRequired,
  withAllOption,
  alwaysOn,
  label,
  sx,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
        ...(!withAllOption ? { discipline_neq: 'All' } : {}),
      },
      sort: { field: 'discipline', order: 'ASC' as const },
    };
  }, [withAllOption, ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_DISCIPLINE}
      requestParams={requestParams}
      label={label}
      name="discipline_inc"
      optionText="discipline"
      validate={isRequired ? required() : undefined}
      sx={
        alwaysOn
          ? sideFilterStyles.sideFiltersSelectInput
          : sx || commonStyles.flexBoxItem
      }
      includeFields={['id', 'discipline']}
    />
  );
};

DisciplineInput.defaultProps = { source: 'disciplineId', label: 'Discipline' };

export default DisciplineInput;
