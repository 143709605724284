import { Box, Divider } from '@mui/material';
import React, { ReactElement } from 'react';
import { Button, TopToolbar } from 'react-admin';
import useCanAccess from '../../../common/hooks/useCanAccess';
import CreateButton from './CreateButton';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import SearchField from './SearchField';

export interface Props {
  selectedId?: number;
  onExportToPdf: () => void;
  onSearch: (value: string) => void;
  onSelectedSuccess: () => void;
  onDeselect: () => void;
}

const ViewModeToolbar: React.FC<Props> = ({
  selectedId,
  onExportToPdf,
  onSearch,
  onSelectedSuccess,
  onDeselect,
}): ReactElement => {
  const access = useCanAccess();

  return (
    <TopToolbar sx={{ flex: '1 0 auto' }}>
      <Box alignItems="left" width="100%">
        <SearchField onSearch={onSearch} />
      </Box>
      {!!selectedId && (
        <Button label="Deselect" onClick={onDeselect} color="warning" />
      )}
      {access.edit && (
        <EditButton recordId={selectedId} onSuccess={onSelectedSuccess} />
      )}
      {access.delete && (
        <DeleteButton recordId={selectedId} onSuccess={onSelectedSuccess} />
      )}
      <Divider
        orientation="vertical"
        style={{ marginTop: '14px' }}
        flexItem
        hidden={!selectedId || (!access.edit && !access.delete)}
      />
      {access.create && <CreateButton />}
      {access.pdfGen && (
        <Button label="Export to PDF" onClick={onExportToPdf} />
      )}
    </TopToolbar>
  );
};

export default ViewModeToolbar;
