import React, { ReactElement, useMemo } from 'react';
import { Link, useRecordContext } from 'react-admin';
import { RESOURCE_CHOICES } from '../../attachment/admin/components/constants';
import { Attachment } from '../../attachment/types';
import { PROPERTY_ID_BY_RESOURCE_NAME } from '../constants';

const ResourceAndIdField: React.FC = (): ReactElement => {
  const record = useRecordContext<Attachment>();
  const resource = record?.resourceName;
  const propertyIdName = PROPERTY_ID_BY_RESOURCE_NAME[resource];
  const recordId = record[propertyIdName];
  const resourceTitle = useMemo(() => {
    return RESOURCE_CHOICES.find((item) => item.id === propertyIdName)?.name;
  }, [propertyIdName]);

  return (
    <Link
      to={`/${resource}/${recordId}`}
    >{`${resourceTitle} / ${recordId}`}</Link>
  );
};

ResourceAndIdField.defaultProps = { label: 'Resource / Id' };

export default ResourceAndIdField;
