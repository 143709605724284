import React, { ReactElement } from 'react';
import { Button, Filter } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import GroupInput from '../../common/GroupInput';
import ModuleInput from '../../common/ModuleInput';
import MobileTestStatusInput from '../../componentTest/components/MobileTestStatusInput';
import { ContactCategories } from '../../common/contactInput/constants';
import useSidebarFilters from '../../common/hooks/useSidebarFilters';
import EquipmentTypeInput from '../../common/EquipmentTypeInput';
import AssignedInput from '../../common/Assigned/AssignedInput';
import VendorInput from '../../common/VendorInput/VendorInput';
import sideFilterStyles from '../../common/sideFilterStyles';
import TestFormNoInput from '../../common/TestFormNoInput';
import DisciplineInput from '../../common/DisciplineInput';
import SubsystemInput from '../../common/SubsystemInput';
import { RESOURCE_SMART_TAG_ITR } from '../../constants';
import ComponentInput from '../../common/ComponentInput';
import LocationInput from '../../common/LocationInput';
import TestTypeInput from '../../common/TestTypeInput';
import WorkPackInput from '../../common/WorkPackInput';
import { mobileTestStatuses } from '../constants';
import { SideFilter } from '../../common/types';
import TextInput from '../../common/TextInput';

const SmartTagITRSidebarFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const { data, isLoading, filterValues, handleClearFilters } =
    useSidebarFilters(RESOURCE_SMART_TAG_ITR, defaultFilter, true);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <TextInput
            source="id"
            label="Id"
            name="id"
            alwaysOn
            fullWidth
            resettable
          />
          <ComponentInput
            label="Tag"
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <EquipmentTypeInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
            source="component->equipTypeId"
          />
          <TestFormNoInput
            label="ITR"
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <TestTypeInput
            label="ITR Type"
            source="test->testTypeId"
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <SubsystemInput
            source="component->subsystemId"
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <DisciplineInput
            source="component->disciplineId"
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <LocationInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <WorkPackInput
            source="component->workPackId"
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <AssignedInput
            label="Responsible"
            category={ContactCategories.itrEmpWorkGrp}
            alwaysOn
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <VendorInput source="responsibleVendorId" alwaysOn multiple />
          <MobileTestStatusInput
            label="Status"
            source="mobileTestStatus"
            fullWidth
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
            choices={mobileTestStatuses}
          />
          <GroupInput
            source="component->groupId"
            category={['COMPONENT']}
            sx={sideFilterStyles.sideFiltersSelectInput}
            alwaysOn
            multiple
          />
          <ModuleInput
            source="component->moduleId"
            sx={sideFilterStyles.sideFiltersSelectInput}
            alwaysOn
            multiple
          />
        </Filter>
        <Button label="Clear filters" onClick={handleClearFilters} />
      </CardContent>
    </Card>
  );
};

export default SmartTagITRSidebarFilters;
