import { Typography } from '@mui/material';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { Button, TopToolbar } from 'react-admin';
import { RED } from '../../../../theme/colors';

export interface Props {
  onBulkSave: () => void;
  onUndo: () => void;
  onRedo: () => void;
  undoDisabled: boolean;
  redoDisabled: boolean;
  onDiscard: () => void;
  visible: boolean;
  selectedId?: number;
  onDeselect: () => void;
}

const EditModeToolbar: React.FC<Props> = ({
  onBulkSave,
  onUndo,
  onRedo,
  undoDisabled,
  redoDisabled,
  onDiscard,
  visible,
  selectedId,
  onDeselect,
}): ReactElement => {
  return (
    <TopToolbar
      className={clsx('gantt-chart-edit-toolbar', visible ? 'visible' : '')}
    >
      <Typography align="left" width="100%" color={RED}>
        There are unsaved changes.
      </Typography>
      {!!selectedId && (
        <Button label="Deselect" onClick={onDeselect} color="warning" />
      )}
      <Button label="Undo" onClick={onUndo} disabled={undoDisabled} />
      <Button label="Redo" onClick={onRedo} disabled={redoDisabled} />
      <Button label="Discard All" onClick={onDiscard} color="error" />
      <Button label="Save" onClick={onBulkSave} />
    </TopToolbar>
  );
};

export default EditModeToolbar;
