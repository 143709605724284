import React, { ReactElement } from 'react';
import { Box } from '@mui/material';
import sideFilterStyles from '../../../../common/sideFilterStyles';
import PDFViewer from '../../../../common/PdfViewer';

interface PdfViewProps {
  url: string;
}

const PdfView: React.FC<PdfViewProps> = ({ url }): ReactElement => {
  return (
    <Box sx={sideFilterStyles.sideEditCard}>
      <PDFViewer style={{ width: 750, height: '75vh' }} url={url} />
    </Box>
  );
};

export default PdfView;
