import React, { ReactElement, useCallback, Fragment } from 'react';
import { useStore, useResourceContext } from 'react-admin';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import sideFilterStyles from '../common/sideFilterStyles';
import ListWithTitle from '../common/ListWithTitle';
import LegendButton from '../common/LegendButton';
import ListActions from '../common/ListActions';
import ListTitle from '../common/ListTitle';
import { RESOURCE_SMART_CERT_DETAIL } from '../constants';
import useSmartTabWideNarrow from '../common/SmartTabWidthButtons/useSmartTabWideNarrow';
import { legends } from './constants';
import SmartCertDetailsEdit from './SmartCertDetailsEdit';
import FilterMyCertificatesButton from './components/FilterMyCertificatesButton';
import SmartCertDetailsSidebarFilters from './components/SmartCertDetailsSidebarFilters';
import { SmartCertDetailsListDatagrid } from './components/SmartCertDetailsListDatagrid';

const defaultVisibleColumns = [
  'id',
  'certTemplateId',
  'activity',
  'assigned',
  'status',
  'certTotalSignatures',
  'certSigned',
  'certSignedBy',
  'actions',
];

const SmartCertDetailsList: React.FC = (): ReactElement => {
  const resource: string = useResourceContext();
  const sideFilter = getSideFiltersKey(resource);
  const [showSideFilter] = useStore(sideFilter, false);
  const [expanded, setExpanded] = useStore<number[]>(
    `${resource}.datagrid.expanded`,
    []
  );
  const defaultFilter = useDefaultFilter('project', {
    isSmart: true,
    status: ['NONE', 'IN_PROGRESS'],
  });
  const isWideActive = useSmartTabWideNarrow(
    ['id', 'certTemplateId', 'activity'],
    defaultVisibleColumns
  );
  const selectedRecordId = expanded?.length ? expanded[0] : null;

  const onCancel = useCallback(() => setExpanded([]), [setExpanded]);

  return (
    <ListWithTitle
      title={<ListTitle />}
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          sideFilter={isWideActive ? undefined : sideFilter}
          defaultFilter={defaultFilter}
          showAction={{
            import: false,
            export: false,
            effective: false,
            create: false,
            select: !isWideActive,
            topPagination: !isWideActive,
          }}
          actions={
            <Fragment>
              <FilterMyCertificatesButton defaultFilter={defaultFilter} />
              {!isWideActive && <LegendButton legends={legends} />}
            </Fragment>
          }
        />
      }
      sort={{ field: 'id', order: 'ASC' }}
      className="smartList"
      sx={sideFilterStyles.smartList}
      aside={
        <Fragment>
          {showSideFilter && !isWideActive && (
            <SmartCertDetailsSidebarFilters defaultFilter={defaultFilter} />
          )}
          {selectedRecordId && (
            <SmartCertDetailsEdit
              syncWithLocation={false}
              id={selectedRecordId}
              resource={RESOURCE_SMART_CERT_DETAIL}
              onCancel={onCancel}
            />
          )}
        </Fragment>
      }
    >
      <SmartCertDetailsListDatagrid
        defaultVisibleColumns={defaultVisibleColumns}
      />
    </ListWithTitle>
  );
};

export default SmartCertDetailsList;
