import React, { ReactElement, useCallback } from 'react';
import {
  Datagrid,
  ReferenceManyField,
  TextField,
  UpdateButton,
} from 'react-admin';
import { useDictionaryLabelsWithResource } from '../../../utils/UtilityFunctions';
import ColumnActions from '../../common/ColumnActions';
import commonStyles from '../../common/commonStyles';
import ComponentField from '../../common/ComponentField';
import useInvalidateResourceQueryCache from '../../common/hooks/useInvalidateResourceQueryCache';
import PunchCategoryField from '../../common/PunchCategoryField';
import PunchPriorityField from '../../common/PunchPriorityField';
import SelectField from '../../common/SelectField';
import SubsystemField from '../../common/SubsystemField';
import { RESOURCE_SMART_PUNCH_REVIEW } from '../../constants';
import { statuses } from '../../pendingPunch/constants';
import { originators } from '../../submitPunch/constants';
import SmartPunchReviewEdit from './SmartPunchReviewEdit';

interface Props {
  readonly?: boolean;
}

const SmartPunchReviewTab: React.FC<Props> = ({ readonly }): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource(
    RESOURCE_SMART_PUNCH_REVIEW
  );

  const invalidateQueries = useInvalidateResourceQueryCache(
    RESOURCE_SMART_PUNCH_REVIEW,
    true
  );

  const clearCache = useCallback(async () => {
    await invalidateQueries();
  }, [invalidateQueries]);

  return (
    <ReferenceManyField
      reference={RESOURCE_SMART_PUNCH_REVIEW}
      target="componentTestId"
      filter={{ status: ['SUBMITTED', 'APPROVED'], masterPunchId: null }}
      perPage={100}
    >
      <Datagrid
        bulkActionButtons={false}
        expand={<SmartPunchReviewEdit />}
        rowClick={false}
        expandSingle
        sx={commonStyles.fullWidth}
      >
        <TextField source="id" label="ID" />
        <TextField source="description" label="Description" />
        <SubsystemField label="SubSystem" />
        <ComponentField
          source="componentId"
          textSource="component.tag"
          sortBy="`component.tag`"
          label="Tag"
        />
        <PunchPriorityField label="Priority" />
        <PunchCategoryField source="punchCatId" label={labels['punchCatId']} />
        <SelectField source="status" label="Status" choices={statuses} />
        <SelectField
          source="originator"
          label="Originator"
          choices={originators}
        />
        <ColumnActions
          label="Actions"
          showActions={{
            show: true,
            edit: !readonly,
            delete: !readonly,
          }}
        >
          {!readonly && (
            <UpdateButton
              label="Add to Master Punch"
              mutationMode="pessimistic"
              confirmTitle="Add to Master Punch"
              confirmContent="Are you sure you want to add this Smart Punch Review to Master Punch?"
              icon={null}
              data={{ status: 'APPROVED_PROCESSED' }}
              mutationOptions={{
                onSuccess: clearCache,
              }}
            />
          )}
        </ColumnActions>
      </Datagrid>
    </ReferenceManyField>
  );
};

export default SmartPunchReviewTab;
