/* eslint-disable no-console */
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useListContext, useResourceContext, useStore } from 'react-admin';
import { useLocation } from 'react-router-dom';

const useExpandRowOnMount = () => {
  const queryClient = useQueryClient();
  const resource: string = useResourceContext();
  const { hash } = useLocation();
  const [, setExpanded] = useStore(`${resource}.datagrid.expanded`, []);
  const { setFilters, filterValues } = useListContext();

  useEffect(() => {
    async function f() {
      if (!hash || isNaN(+hash.slice(1))) {
        return;
      }

      const id = +hash.slice(1);
      await queryClient.invalidateQueries({
        queryKey: [resource, 'getOne', { id: String(id) }],
      });
      setExpanded([id]);
      setFilters({ ...filterValues, id });
    }
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient, resource]);
};

export default useExpandRowOnMount;
