import React, { ReactElement } from 'react';
import {
  Datagrid,
  ListContextProvider,
  useGetList,
  useList,
  useRecordContext,
  TextField,
  DateField,
  ResourceContextProvider,
} from 'react-admin';
import { Box } from '@mui/material';
import {
  RESOURCE_ATTACHMENT,
  RESOURCE_COMPONENT_TEST,
  RESOURCE_COMPONENT,
} from '../../constants';
import AttachmentShowButton from '../../attachment/components/AttachmentShowButton';
import AttachmentRevision from '../../attachment/components/AttachmentRevision';
import { getContext, UserContext } from '../../../provider/userContext';
import AttachmentEmpty from '../../common/AttachmentEmpty';
import { ATTACHMENT_TYPE_ENUMS } from '../../common/enums';
import { LOOKUP_PAGE_SIZE } from '../../common/constants';
import DownloadButton from '../../common/DownloadButton';
import commonStyles from '../../common/commonStyles';

const TestAttachmentTab: React.FC = (): ReactElement => {
  const record = useRecordContext();
  const ctx: UserContext = getContext();
  // Get all loop components with the same loopName if it isn't empty
  const {
    data: loopComponents,
    isLoading: loopComponentsIsLoading,
    isFetched: loopComponentsIsFetched,
  } = useGetList(
    RESOURCE_COMPONENT,
    {
      filter: {
        loopName: record.loopName,
        projectId: ctx?.projectId,
        id_neq: record.id,
      },
      pagination: {
        page: 1,
        perPage: LOOKUP_PAGE_SIZE,
      },
      meta: {
        includeFields: ['id'],
      },
    },
    {
      enabled: !!record.loopName,
    }
  );
  const loopComponentIds =
    loopComponents?.map((component) => component.id) || [];
  // Get all Component Tests for current componentId and loopComponentIds
  const {
    data: componentTests,
    isLoading: componentTestsIsLoading,
    isFetched: componentTestsIsFetched,
  } = useGetList(
    RESOURCE_COMPONENT_TEST,
    {
      filter: { componentId: [record.id, ...loopComponentIds] },
      pagination: {
        page: 1,
        perPage: LOOKUP_PAGE_SIZE,
      },
      meta: {
        includeFields: ['id', 'componentId', 'test.loop', 'test.multiTag'],
      },
    },
    {
      enabled: loopComponentsIsFetched || !record.loopName,
    }
  );
  // Filter Component Tests by current componentId or test.loop test.multiTag
  const componentTestIds = componentTests
    ?.filter(
      (componentTest) =>
        componentTest?.componentId === record.id ||
        componentTest?.test?.loop ||
        componentTest?.test?.multiTag
    )
    ?.map((componentTest) => componentTest.id);

  const { data: attachments, isLoading: attachmentsIsLoading } = useGetList(
    RESOURCE_ATTACHMENT,
    {
      filter: {
        componentTestId: componentTestIds,
        isDeleted: false,
        attachmentType: [
          ATTACHMENT_TYPE_ENUMS.GENERAL,
          ATTACHMENT_TYPE_ENUMS.SMART_ITR,
        ],
        resourceName: RESOURCE_COMPONENT_TEST,
      },
      pagination: {
        page: 1,
        perPage: LOOKUP_PAGE_SIZE,
      },
    },
    {
      enabled: componentTestsIsFetched,
    }
  );

  const listContext = useList({
    data: attachments,
    isLoading:
      loopComponentsIsLoading ||
      componentTestsIsLoading ||
      attachmentsIsLoading,
  });

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ResourceContextProvider value={RESOURCE_ATTACHMENT}>
      <ListContextProvider value={listContext}>
        <Datagrid
          bulkActionButtons={false}
          rowClick={false}
          empty={<AttachmentEmpty />}
          expand={<AttachmentRevision onlyShow />}
          expandSingle
        >
          <TextField source="id" />
          <TextField source="fileName" />
          <DateField source="updatedAt" label="Updated At" />
          <TextField source="updatedByName" label="Updated By Name" />
          <Box sx={commonStyles.columnActionsBox}>
            <AttachmentShowButton />
            <DownloadButton />
          </Box>
        </Datagrid>
      </ListContextProvider>
    </ResourceContextProvider>
  );
};

export default TestAttachmentTab;
