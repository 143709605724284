import React, { ReactElement, Fragment, useCallback } from 'react';
import { useStore, useResourceContext } from 'react-admin';
import { RED } from '../../theme/colors';
import useCheckCurrentProjectResourceAccessSettings from '../common/hooks/useCheckCurrentProjectResourceAccessSettings';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import useSmartTabWideNarrow from '../common/SmartTabWidthButtons/useSmartTabWideNarrow';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import FilterMyItemsButton from '../common/FilterMyItemsButton';
import ListWithTitle from '../common/ListWithTitle';
import LegendButton from '../common/LegendButton';
import ListActions from '../common/ListActions';
import sideFilterStyles from '../common/sideFilterStyles';
import { RESOURCE_SMART_TAG_ITR } from '../constants';
import SmartTagITRSidebarFilters from './components/SmartTagITRSidebarFilters';
import SmartTagITRListDatagrid from './components/SmartTagITRListDatagrid';
import SmartTagITREdit from './SmartTagITREdit';

const legends = [
  {
    label: 'Rejected ITR',
    color: RED,
  },
];

const defaultVisibleColumns = [
  'id',
  'componentId',
  'testId',
  'assignedEmail',
  'mobileTestStatus',
  'pendingPunchCounts.mobilePunch',
];

const SmartTagITRList: React.FC = (): ReactElement => {
  useCheckCurrentProjectResourceAccessSettings();

  const resource: string = useResourceContext();
  const sideFilter = getSideFiltersKey(resource);
  const [showSideFilter] = useStore(sideFilter, false);
  const [expanded, setExpanded] = useStore(`${resource}.datagrid.expanded`, []);
  const defaultFilter = useDefaultFilter('project', {
    isDeleted: false,
    isMobile: true,
    mobileTestStatus: ['NONE', 'REJECTED'],
  });
  const isWideActive = useSmartTabWideNarrow(
    ['id', 'componentId', 'testId'],
    defaultVisibleColumns
  );
  const selectedRecordId = expanded?.length ? expanded[0] : null;

  const onCancel = useCallback(() => setExpanded([]), [setExpanded]);

  return (
    <ListWithTitle
      resource={RESOURCE_SMART_TAG_ITR}
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          sideFilter={isWideActive ? undefined : sideFilter}
          defaultFilter={defaultFilter}
          showAction={{
            import: false,
            export: false,
            effective: false,
            create: false,
            select: !isWideActive,
            topPagination: !isWideActive,
          }}
          actions={
            <Fragment>
              <FilterMyItemsButton defaultFilter={defaultFilter} />
              {!isWideActive && <LegendButton legends={legends} />}
            </Fragment>
          }
        />
      }
      sort={{ field: 'id', order: 'ASC' }}
      className="smartList"
      sx={sideFilterStyles.smartList}
      aside={
        <Fragment>
          {showSideFilter && !isWideActive && (
            <SmartTagITRSidebarFilters defaultFilter={defaultFilter} />
          )}
          {selectedRecordId && (
            <SmartTagITREdit
              syncWithLocation={false}
              id={selectedRecordId}
              resource={RESOURCE_SMART_TAG_ITR}
              onCancel={onCancel}
            />
          )}
        </Fragment>
      }
    >
      <SmartTagITRListDatagrid defaultVisibleColumns={defaultVisibleColumns} />
    </ListWithTitle>
  );
};

export default SmartTagITRList;
