import { DateField, Labeled, TabbedShowLayout, TextField } from 'react-admin';
import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import AttachmentList from '../attachment/AttachmentList';
import commonStyles from '../common/commonStyles';
import CustomBooleanField from '../common/CustomBooleanField';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import AuditListTab from '../audit/AuditListTab';
import TestEquipmentTypeField from '../common/TestEquipmentTypeField';
import VendorField from '../common/VendorField';
import { RESOURCE_TEST_EQUIPMENT } from '../constants';

const TestEquipmentShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Test Equipment">
        <Box>
          <Labeled label="Test Equipment Name">
            <TextField source="testEquipment" sx={commonStyles.flexBoxItem} />
          </Labeled>
          <Labeled label="Manufacturer">
            <TextField
              source="manufacturer"
              sx={commonStyles.flexBoxLongItem}
            />
          </Labeled>
        </Box>
        <Box>
          <Labeled label="Model">
            <TextField source="model" sx={commonStyles.flexBoxItem} />
          </Labeled>
          <Labeled label="Serial Number">
            <TextField source="serialNumber" sx={commonStyles.flexBoxItem} />
          </Labeled>
          <Labeled label="Rent">
            <CustomBooleanField source="rent" sx={commonStyles.flexBoxItem} />
          </Labeled>
        </Box>
        <Box>
          <Labeled label="Calibration Date">
            <DateField source="calibrationDate" sx={commonStyles.flexBoxItem} />
          </Labeled>
          <Labeled label="Calibration Expiration Date">
            <DateField
              source="calibrationExpirationDate"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled label="Test Equipment Type" sx={commonStyles.flexBoxItem}>
            <TestEquipmentTypeField />
          </Labeled>
        </Box>
        <Box>
          <Labeled label="Cost">
            <TextField source="cost" sx={commonStyles.flexBoxItem} />
          </Labeled>
          <Labeled label="Vendor" sx={commonStyles.flexBoxItem}>
            <VendorField />
          </Labeled>
          <Labeled label="Available" sx={commonStyles.flexBoxItem}>
            <CustomBooleanField
              source="testEquipmentAvailability.available"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label="Attachment"
        id="attachment_tab"
        sx={commonStyles.formTabHeader}
      >
        <AttachmentList
          targetId="testEquipmentId"
          attachmentType={[ATTACHMENT_TYPE_ENUMS.GENERAL]}
          resourceName={RESOURCE_TEST_EQUIPMENT}
          onlyShow
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_test_equipment" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default TestEquipmentShow;
