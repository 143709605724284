import React, { ReactElement } from 'react';
import { FormTabProps } from 'react-admin';
import { Stack } from '@mui/material';
import TabHeader from './TabHeader';

// Main logic is copied from React Admin FormTab component
const Tab: React.FC<FormTabProps> = ({
  children,
  className,
  contentClassName,
  count,
  hidden,
  icon,
  intent,
  label,
  onChange,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  path,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resource,
  syncWithLocation,
  value,
  ...rest
}): ReactElement => {
  const renderHeader = () => (
    <TabHeader
      label={label}
      count={count}
      value={value}
      icon={icon}
      className={className}
      syncWithLocation={syncWithLocation}
      onChange={onChange}
      {...sanitizeRestProps(rest)}
    />
  );

  const renderContent = () => (
    <Stack
      alignItems="flex-start"
      style={hidden ? hiddenStyle : undefined}
      className={contentClassName}
      id={`tabpanel-${value}`}
      aria-labelledby={`tabheader-${value}`}
      // Set undefined instead of false because WAI-ARIA Authoring Practices 1.1
      // notes that aria-hidden="false" currently behaves inconsistently across browsers.
      aria-hidden={hidden || undefined}
      {...rest}
    >
      {children}
    </Stack>
  );

  return intent === 'header' ? renderHeader() : renderContent();
};

Tab.displayName = 'Tab';

const hiddenStyle = { display: 'none' };

const sanitizeRestProps = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  classes,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  margin,
  ...rest
}: Omit<
  FormTabProps,
  | 'className'
  | 'contentClassName'
  | 'children'
  | 'hidden'
  | 'icon'
  | 'intent'
  | 'label'
  | 'onChange'
  | 'path'
  | 'resource'
  | 'syncWithLocation'
  | 'value'
>) => rest;

export default Tab;
