import React, { ReactElement, useCallback } from 'react';
import { Button, useNotify } from 'react-admin';
import useDataProvider from '../../provider/dataProvider/useDataProvider';
import { RESOURCE_REPORT } from '../constants';

interface RefreshReportDatasetButtonProps {
  reportId: string;
}

const RefreshReportDatasetButton: React.FC<RefreshReportDatasetButtonProps> = ({
  reportId,
}): ReactElement => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleClick = useCallback(async () => {
    try {
      await dataProvider.refreshDataset(RESOURCE_REPORT, {
        reportId,
      });
      notify(
        `Refresh is successfully requested. It might take up to 5 minutes. Please refresh your browser tab in a couple of minutes!`,
        {
          type: 'success',
        }
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      notify('Refresh request is failed! Try again in a couple of minutes!', {
        type: 'error',
      });
    }
  }, [dataProvider, reportId, notify]);
  return <Button label="Refresh" onClick={handleClick} />;
};

export default RefreshReportDatasetButton;
