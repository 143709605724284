import React, { Fragment, ReactElement, useCallback } from 'react';
import { Edit, SimpleForm, useRecordContext } from 'react-admin';
import Box from '@mui/material/Box';
import EditTitle from '../common/EditTitle';
import FooterToolbar from '../common/FooterToolbar';
import useAutoSaveEnabled from '../common/hooks/useAutoSaveEnabled';
import useInvalidateResourceQueryCache from '../common/hooks/useInvalidateResourceQueryCache';
import PDFViewer from '../common/PdfViewer';
import { getAttachmentFileType } from '../attachment/helpers';
import { getFileName } from '../common/helpers/getFileName';
import { AttachmentFileType } from '../attachment/types';
import useCanAccess from '../common/hooks/useCanAccess';
import LazyTab from '../common/TabbedView/LazyTab';
import TabbedView from '../common/TabbedView/TabbedView';
import { EditPropsWithLocation } from '../common/types';
import { ComponentTest } from '../componentTest/types';
import commonStyles from '../common/commonStyles';
import DataProviderErrorsWrapper from '../DataProviderErrors/DataProviderErrorsWrapper';
import SmartPunchReviewTab from './components/SmartPunchReviewTab';
import SmartTagITRReviewForm from './components/SmartTagITRReviewForm';

const SmartTagITRReviewEdit: React.FC<EditPropsWithLocation<ComponentTest>> = ({
  syncWithLocation,
  ...rest
}): ReactElement => {
  const canAccessList = useCanAccess();
  const record = useRecordContext();
  const fileName = getFileName(record.downloadUrl);
  const attachmentFileType = getAttachmentFileType(fileName);

  const [isAutoSaveEnabled] = useAutoSaveEnabled();
  const { autoSave, ...restProps } = rest;
  const autoSaveEnabled = Boolean(
    autoSave === undefined ? isAutoSaveEnabled : autoSave
  );

  const invalidateQueries = useInvalidateResourceQueryCache(undefined, true);

  const clearCache = useCallback(async () => {
    await invalidateQueries();
  }, [invalidateQueries]);

  return (
    <TabbedView syncWithLocation={syncWithLocation}>
      <LazyTab
        label="Smart Tag ITR"
        id="smart_tag_itr_tab"
        sx={commonStyles.formTabHeader}
      >
        <DataProviderErrorsWrapper
          action="getOne"
          ignoreCodes={[400, 404, 500]}
        >
          <Edit
            {...restProps}
            mutationOptions={{
              onSettled: clearCache,
            }}
            mutationMode="pessimistic"
            actions={false}
            title={<EditTitle />}
          >
            <SimpleForm
              toolbar={
                <FooterToolbar
                  autoSave={autoSaveEnabled}
                  syncWithLocation={syncWithLocation}
                />
              }
            >
              <Box sx={commonStyles.rowFlexBoxItem}>
                <SmartTagITRReviewForm />
                <Box sx={commonStyles.pdfViewBox}>
                  {record.downloadUrl ? (
                    attachmentFileType === AttachmentFileType.pdf ? (
                      <PDFViewer
                        id={record?.id}
                        url={record.downloadUrl ? record.downloadUrl : ''}
                        style={{ width: 750, height: '75vh' }}
                      />
                    ) : (
                      <Fragment>Attachment is not PDF.</Fragment>
                    )
                  ) : (
                    <Fragment>No Attachment.</Fragment>
                  )}
                </Box>
              </Box>
            </SimpleForm>
          </Edit>
        </DataProviderErrorsWrapper>
      </LazyTab>
      <LazyTab
        label="Smart Punch Review"
        id="smart_punch_review_tab"
        sx={commonStyles.formTabHeader}
      >
        <SmartPunchReviewTab readonly={!canAccessList.edit} />
      </LazyTab>
    </TabbedView>
  );
};

export default SmartTagITRReviewEdit;
