import { useCallback, useState, useRef } from 'react';
import { useDataProvider, useNotify, useResourceContext } from 'react-admin';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import lodash, { isEmpty } from 'lodash';
import { getErrorMessage } from '../../utils/UtilityFunctions';
import { RESOURCE_ACTIVITY_PREDECESSOR } from '../constants';
import { IMPORT_RESULT_STATUS } from '../notification/type';
import { GanttApi, Task } from './components/gantt/types';
import {
  checkTasksAreEqual,
  getModifiedDataToSave,
  mapActivityToTask,
  removeExtraFieldsFromActivity,
} from './helpers';
import { Activity, ModifiedData } from './types';

const useGanttChartView = (
  ganttApi: GanttApi,
  projectStartDate: Date | string,
  projectEndDate: Date | string
) => {
  // store initial data in ref to fix issue with old value appearing in onTaskChange handler after "Save" button press
  const initialDataRef = useRef<Activity[]>([]);
  const [initialized, setInitialized] = useState(false);
  const resource = useResourceContext();
  const [selectedId, setSelectedId] = useState<number>(null);
  const [changes, setChanges] = useState<{ [id: number]: Task }>({});
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const queryClient = useQueryClient();
  const clearCache = useCallback(() => {
    [resource, RESOURCE_ACTIVITY_PREDECESSOR].forEach((item) => {
      queryClient.removeQueries({ queryKey: [item], exact: false });
    });
  }, [queryClient, resource]);

  const setInitialData = useCallback((data?: Activity[]) => {
    initialDataRef.current = (data || []).map(removeExtraFieldsFromActivity);
    setInitialized(true);
  }, []);

  const onCleanState = useCallback(() => {
    ganttApi.clear();
    setSelectedId(null);
    setChanges({});
  }, [ganttApi]);

  const onDiscardAll = useCallback(() => {
    onCleanState();
    ganttApi.updateDataSource(
      initialDataRef.current.map(mapActivityToTask),
      projectStartDate,
      projectEndDate
    );
  }, [ganttApi, onCleanState, projectEndDate, projectStartDate]);

  const onTaskSelect = useCallback(
    ({ data }) => {
      setSelectedId(data?.id);
    },
    [setSelectedId]
  );

  const onDeselect = useCallback(() => {
    setSelectedId(null);
    ganttApi.clearSelection();
  }, [ganttApi]);

  const onTaskChange = useCallback(({ modifiedRecords, requestType }) => {
    if (!['save', 'rowDropped'].includes(requestType)) return;

    setChanges((prev) => {
      const updatesById = lodash(modifiedRecords)
        .map(({ taskData }) => taskData)
        .keyBy(({ id }) => id)
        .value();

      const result = { ...(prev || {}), ...updatesById };

      Object.values(updatesById).forEach((updatedTask: Task) => {
        const initialActivity = initialDataRef.current.find(
          (item: Activity) => item.id === updatedTask.id
        );

        if (initialActivity) {
          const initialTask = mapActivityToTask(initialActivity);
          // store only changed items
          if (checkTasksAreEqual(initialTask, updatedTask)) {
            delete result[initialTask.id];
          }
        }
      });

      return result;
    });
  }, []);

  const { mutate: saveChanges } = useMutation({
    mutationFn: async (params: ModifiedData) => {
      const { activities, predecessors } = params;

      if (!isEmpty(predecessors.deleted)) {
        await dataProvider.deleteMany(RESOURCE_ACTIVITY_PREDECESSOR, {
          ids: predecessors.deleted,
        });
      }

      if (!isEmpty(predecessors.created)) {
        await dataProvider.createMany(RESOURCE_ACTIVITY_PREDECESSOR, {
          data: predecessors.created,
        });
      }

      if (!isEmpty(predecessors.updated)) {
        await dataProvider.bulkUpdate(
          RESOURCE_ACTIVITY_PREDECESSOR,
          predecessors.updated
        );
      }

      return dataProvider.bulkUpdate(resource, activities);
    },
  });

  const onBulkSave = useCallback(() => {
    const modifiedData = getModifiedDataToSave(
      initialDataRef.current,
      Object.values(changes)
    );

    saveChanges(modifiedData, {
      onSuccess: ({ data }) => {
        const { status, totalErrorsCount } = data;
        if (status === IMPORT_RESULT_STATUS.SUCCESS) {
          notify('Elements updated');
        } else {
          const msg = `Failed to update ${totalErrorsCount} elements.`;
          notify(msg, { type: 'error' });
        }
      },
      onError: (error) => {
        notify(getErrorMessage(error), { type: 'error' });
      },
      onSettled: async () => {
        clearCache();
        onCleanState();
      },
    });

    onCleanState();
  }, [onCleanState, saveChanges, notify, changes, clearCache]);

  return {
    changes,
    initialized,
    setInitialData,
    selectedId,
    onCleanState,
    onBulkSave,
    onTaskChange,
    onTaskSelect,
    onDiscardAll,
    onDeselect,
  };
};

export default useGanttChartView;
