import React, { ReactElement } from 'react';
import { ChipField, useListContext } from 'react-admin';
import SmartSingleFieldList from './SmartSingleFieldList';

interface Props {
  linkSource?: string;
  chipSource: string;
}

const SmartChipListField: React.FC<Props> = ({
  linkSource,
  chipSource,
}): ReactElement => {
  const { data } = useListContext();

  const mappedData = !linkSource
    ? data
    : data.map((item) => ({ ...item, id: item[linkSource] }));

  return (
    <SmartSingleFieldList data={mappedData}>
      <ChipField source={chipSource} />
    </SmartSingleFieldList>
  );
};

SmartChipListField.defaultProps = {
  linkSource: 'id',
};

export default SmartChipListField;
