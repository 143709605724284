import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import { RESOURCE_DISCIPLINE } from '../constants';
import SmartLinkField from './SmartLinkField';

interface DisciplineFieldProps {
  label?: string;
  source?: string;
  textSource?: string;
  sortBy?: string;
  sx?: SxProps<Theme>;
}

const DisciplineField: React.FC<DisciplineFieldProps> = ({
  label,
  source,
  textSource,
  sx,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_DISCIPLINE}
    sx={sx}
  >
    <TextField source={textSource} />
  </SmartLinkField>
);

DisciplineField.defaultProps = {
  label: 'Discipline',
  source: 'disciplineId',
  textSource: 'discipline.discipline',
  sortBy: '`discipline.discipline`',
};

export default DisciplineField;
