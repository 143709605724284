import React, { ReactElement, useCallback } from 'react';
import CommentFormTab from '../common/CommentFormTab';
import { commentType } from '../common/constants';
import useInvalidateResourceQueryCache from '../common/hooks/useInvalidateResourceQueryCache';
import AttachmentFormTab from '../common/AttachmentFormTab';
import { EditPropsWithLocation } from '../common/types';
import { RESOURCE_COMPONENT_TEST } from '../constants';
import TabbedEditForm from '../common/TabbedEditForm';
import MetaDataInputs from '../common/MetaDataInputs';
import commonStyles from '../common/commonStyles';
import LazyFormTab from '../common/LazyFormTab';
import HistoryTab from '../common/HistoryTab';
import ComponentTestForm from './ComponentTestForm';
import { ComponentTest } from './types';

const ComponentTestEdit: React.FC<EditPropsWithLocation<ComponentTest>> = (
  props
): ReactElement => {
  const invalidateQueries = useInvalidateResourceQueryCache(
    RESOURCE_COMPONENT_TEST,
    true
  );

  const clearCache = useCallback(async () => {
    await invalidateQueries();
  }, [invalidateQueries]);

  const commentTabTransformFunc = useCallback(
    (data, recordId) => ({
      ...data,
      componentTestId: recordId,
      commentType: commentType.componentTest,
    }),
    []
  );

  return (
    <TabbedEditForm {...props} autoSave={false}>
      <LazyFormTab
        label="Component Test"
        id="component_test_tab"
        sx={commonStyles.formTabHeader}
      >
        <ComponentTestForm />
        <MetaDataInputs />
      </LazyFormTab>
      <LazyFormTab
        label="Comment"
        id="comments_tab"
        sx={commonStyles.formTabHeader}
      >
        <CommentFormTab
          targetId="component_test_id"
          commentType={commentType.componentTest}
          transformFunc={commentTabTransformFunc}
        />
      </LazyFormTab>
      <LazyFormTab
        label="Attachment"
        id="attachment_tab"
        sx={commonStyles.formTabHeader}
      >
        <AttachmentFormTab
          targetId="componentTestId"
          resourceName={RESOURCE_COMPONENT_TEST}
          onUpload={clearCache}
          onDelete={clearCache}
        />
      </LazyFormTab>
      <HistoryTab tableName="tbl_component_test" />
    </TabbedEditForm>
  );
};

export default ComponentTestEdit;
