import React, { ReactElement, useEffect, useMemo } from 'react';
import { Loading, useListContext } from 'react-admin';
import Gantt, { GanttProps } from './components/gantt/Gantt';
import { mapActivityToTask } from './helpers';

interface Props extends Omit<GanttProps, 'tasks'> {
  onDataLoaded: (data) => void;
}

const GanttChartTasks: React.FC<Props> = ({
  onDataLoaded,
  ...props
}): ReactElement => {
  const { isPending, data } = useListContext();

  useEffect(() => {
    if (!isPending) {
      onDataLoaded(data);
    }
  }, [onDataLoaded, data, isPending]);

  const tasks = useMemo(() => (data || []).map(mapActivityToTask), [data]);

  return isPending ? <Loading /> : <Gantt tasks={tasks} {...props} />;
};

export default GanttChartTasks;
