import React, { ReactElement, useCallback } from 'react';
import { useUpdate, useRecordContext } from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Box } from '@mui/material';
import useCanAccess from '../../common/hooks/useCanAccess';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_SUBSYSTEM } from '../../constants';

interface ColumnActionsProps {
  label?: string;
}

const ColumnActions: React.FC<ColumnActionsProps> = (): ReactElement => {
  const [update, { isLoading }] = useUpdate();
  const canAccessList = useCanAccess();
  const record = useRecordContext();
  const queryClient = useQueryClient();

  const handleFlagClick = useCallback(
    async (value: boolean) => {
      update(
        RESOURCE_SUBSYSTEM,
        {
          id: record.id,
          data: { isAssignItr: value },
        },
        {
          onSuccess: async () => {
            queryClient.invalidateQueries({ queryKey: [RESOURCE_SUBSYSTEM] });
          },
        }
      );
    },
    [update, record, queryClient]
  );

  return (
    <Box sx={commonStyles.columnActionsBox}>
      {canAccessList.edit && (
        <Button onClick={handleFlagClick.bind(null, true)} disabled={isLoading}>
          Flag
        </Button>
      )}
      {canAccessList.edit && (
        <Button
          onClick={handleFlagClick.bind(null, false)}
          disabled={isLoading}
        >
          Unflag
        </Button>
      )}
    </Box>
  );
};

export default ColumnActions;
