import { Show, ShowProps } from 'react-admin';
import React from 'react';
import ShowActions, { ShowActionsProps } from '../common/ShowActions';
import DataProviderErrorsWrapper from '../DataProviderErrors/DataProviderErrorsWrapper';
import EditTitle from './EditTitle';

export interface ShowComponentProps {
  withoutActions?: boolean;
  syncWithLocation?: boolean;
  showActionsProps?: ShowActionsProps;
  withEditPermissionCheck?: boolean;
}

interface ComponentProps extends ShowProps, ShowComponentProps {}

const ShowComponent: React.FC<ComponentProps> = ({
  children,
  showActionsProps,
  ...rest
}) => {
  const { withoutActions } = rest;

  return (
    <DataProviderErrorsWrapper action={'getOne'} ignoreCodes={[400, 404, 500]}>
      <Show
        {...rest}
        title={<EditTitle />}
        actions={withoutActions ? false : <ShowActions {...showActionsProps} />}
      >
        {children}
      </Show>
    </DataProviderErrorsWrapper>
  );
};

export default ShowComponent;
