import { enUS } from '@mui/material/locale';
import { defaultTheme } from 'react-admin';
import { createTheme } from '@mui/material/styles';
import {
  GREY,
  RED,
  GREEN,
  WHITE,
  GREY_TEXT,
  GREEN_LIGHT,
  MENU_TEXT,
} from './colors';

export const lightTheme = createTheme(
  {
    ...defaultTheme,
    palette: {
      mode: 'light',
      background: {
        paper: WHITE,
        default: WHITE,
      },
      primary: {
        main: GREEN,
      },
      secondary: {
        main: GREEN,
      },
      text: {
        primary: GREY_TEXT,
        secondary: GREY_TEXT,
      },
    },
    sidebar: {
      width: 260,
      closedWidth: 55,
    },
    typography: {
      fontFamily: [
        'Hind',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Arial',
        'sans-serif',
      ].join(','),
    },
    components: {
      MuiPopper: {
        styleOverrides: {
          root: {
            maxHeight: '100vh',
            overflowY: 'auto',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: '6px',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            overflow: 'visible',
          },
        },
      },
      RaMenuItem: {
        styleOverrides: {
          root: {
            paddingLeft: 0,
            color: MENU_TEXT,
            borderBottom: `1px solid ${GREY}`,
            '&:last-child': {
              border: 'none',
            },
            '& .RaMenuItem-nestedList': {
              '& .RaMenuItemLink-active': {
                backgroundColor: GREEN_LIGHT,
              },
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            '& > a': {
              minWidth: '150px',
              padding: '0 8px',
            },
            '&.RaMenuItem-nestedList': {
              paddingLeft: 0,
              '& > div': {
                borderBottom: 'none',
              },
              '& .MuiMenuItem-root, li': {
                margin: '4px 0',
                paddingLeft: '48px',
              },
            },
          },
        },
      },
      RaMenuItemLink: {
        styleOverrides: {
          root: {
            padding: 0,
            height: '24px',
            color: MENU_TEXT,
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            margin: 'auto',
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'outlined',
        },
      },
      MuiTextField: {
        defaultProps: {
          size: 'small',
          variant: 'outlined',
        },
      },
      RaTopToolbar: {
        styleOverrides: {
          root: {
            '& button, a': {
              height: '24px',
              padding: '0 16px',
              textTransform: 'none',
              border: `2px solid ${GREEN}`,
              borderRadius: '5px',
              fontSize: '12px',
              fontWeight: 500,
              '&:hover': {
                backgroundColor: GREEN,
                color: WHITE,
              },
              '&.Mui-disabled': {
                borderColor: GREY,
              },
            },
          },
        },
      },
      RaToolbar: {
        styleOverrides: {
          root: {
            '& .MuiButton-text': {
              width: '100px',
              color: GREY_TEXT,
            },
          },
        },
      },
      RaSelectColumnsButton: {
        styleOverrides: {
          root: {
            '& .MuiButton-startIcon': {
              display: 'none',
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: '5px',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            '& span.k-loader-segment': {
              backgroundColor: GREEN,
            },
          },
        },
      },
      //icon buttons
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '& svg, div.showButton': {
              minWidth: '20px',
              minHeight: '20px',
              width: '20px',
              height: '20px',
            },
            '&.ra-delete-button svg': {
              fill: RED,
            },
            '&.Mui-disabled svg': {
              fill: GREY,
            },
          },
        },
      },
      RaAppBar: {
        styleOverrides: {
          root: {
            '& .MuiButtonBase-root svg': {
              width: '24px',
              height: '24px',
              fill: WHITE,
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            margin: '4px 8px',
            '& svg': {
              width: '32px',
              height: '32px',
            },
          },
        },
      },
    },
  },
  enUS
);
