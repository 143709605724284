import React, { ReactElement } from 'react';
import { TextField, useRecordContext } from 'react-admin';
import { activityCategoryDescriptionSource } from '../constants';

interface CategoryDescriptionFieldProps {
  sx?: object;
  label?: string;
}

const CategoryDescriptionField: React.FC<CategoryDescriptionFieldProps> = ({
  sx,
  label,
}): ReactElement => {
  const record = useRecordContext();

  return (
    <TextField
      label={label}
      sx={sx}
      source={activityCategoryDescriptionSource[record.category]}
    />
  );
};

CategoryDescriptionField.defaultProps = {
  label: 'Category Description',
};

export default CategoryDescriptionField;
