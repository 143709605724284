import React, { Fragment, useCallback, useState, ReactElement } from 'react';
import {
  Button,
  SimpleForm,
  useCreate,
  useNotify,
  useRecordContext,
} from 'react-admin';
import Add from '@mui/icons-material/Add';
import { CreateDialog } from '@react-admin/ra-form-layout';
import SaveOnlyToolbar from '../../common/SaveOnlyToolbar';
import useInvalidateRelatedResourceQueries from '../../common/hooks/useInvalidateRelatedResourceQueries';
import { RESOURCE_COMPONENT, RESOURCE_COMPONENT_TEST } from '../../constants';
import { getErrorMessage } from '../../../utils/UtilityFunctions';
import ComponentTestForm from '../ComponentTestForm';
import useCanAccess from '../../common/hooks/useCanAccess';

const ComponentTestCreateButton: React.FC = (): ReactElement => {
  const record = useRecordContext();
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const canAccessList = useCanAccess(RESOURCE_COMPONENT_TEST);
  const invalidateQueries =
    useInvalidateRelatedResourceQueries(RESOURCE_COMPONENT);
  const [create] = useCreate(
    RESOURCE_COMPONENT_TEST,
    {},
    {
      onSuccess: () => {
        invalidateQueries().then();
      },
    }
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      await create(
        RESOURCE_COMPONENT_TEST,
        {
          data: {
            ...data,
            projectId: record?.projectId,
          },
        },
        {
          onError: (error) => {
            notify('Error: ' + getErrorMessage(error), {
              type: 'error',
              undoable: false,
            });
          },
          onSuccess: () => {
            notify('Tag ITR was created', {
              type: 'success',
              undoable: false,
            });
          },
        }
      );

      handleClose();
    },
    [create, record.projectId, handleClose, notify]
  );

  return (
    <Fragment>
      {canAccessList.create && (
        <Button label="Create Tag ITR" onClick={handleOpen}>
          <Add />
        </Button>
      )}
      <CreateDialog
        title={<span>Create Tag ITR</span>}
        maxWidth={false}
        isOpen={open}
        open={handleOpen}
        close={handleClose}
      >
        <SimpleForm
          id="component_component_test"
          onSubmit={onSubmit}
          toolbar={<SaveOnlyToolbar />}
          defaultValues={{
            componentId: record?.id,
          }}
        >
          <ComponentTestForm />
        </SimpleForm>
      </CreateDialog>
    </Fragment>
  );
};

export default ComponentTestCreateButton;
