import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_GROUP } from '../constants';
import SmartLinkField from './SmartLinkField';

interface GroupFieldProps {
  label?: string;
  source?: string;
  textSource?: string;
  sortBy?: string;
}

const GroupField: React.FC<GroupFieldProps> = ({
  label,
  source,
  textSource,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_GROUP}
    sortBy={textSource}
  >
    <TextField source={textSource} />
  </SmartLinkField>
);

GroupField.defaultProps = {
  label: 'Group',
  source: 'groupId',
  textSource: 'group.groupName',
  sortBy: '`group.groupName`',
};

export default GroupField;
