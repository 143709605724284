import React, { ReactElement, useCallback } from 'react';
import useInvalidateResourceQueryCache from '../common/hooks/useInvalidateResourceQueryCache';
import { RESOURCE_PRESERVATION_ITEM_ACTIVITY } from '../constants';
import AttachmentFormTab from '../common/AttachmentFormTab';
import { EditPropsWithLocation } from '../common/types';
import TabbedEditForm from '../common/TabbedEditForm';
import MetaDataInputs from '../common/MetaDataInputs';
import CommentFormTab from '../common/CommentFormTab';
import commonStyles from '../common/commonStyles';
import { commentType } from '../common/constants';
import LazyFormTab from '../common/LazyFormTab';
import HistoryTab from '../common/HistoryTab';
import { PreservationItemActivityType } from './types';
import PreservationItemActivityForm from './PreservationItemActivityForm';

const PreservationItemActivityEdit: React.FC<
  EditPropsWithLocation<PreservationItemActivityType>
> = (props): ReactElement => {
  const { disableFieldsWithoutPermissionRules = false, ...rest } = props;
  const commentTabTransformFunc = useCallback(
    (data, recordId) => ({
      ...data,
      preservationItemActivityId: recordId,
      commentType: 'PRESERVATION_ITEM_ACTIVITY',
    }),
    []
  );

  const invalidateQueries = useInvalidateResourceQueryCache(
    RESOURCE_PRESERVATION_ITEM_ACTIVITY,
    true
  );

  const clearCache = useCallback(async () => {
    await invalidateQueries();
  }, [invalidateQueries]);

  return (
    <TabbedEditForm {...rest}>
      <LazyFormTab
        label="Preservation Item Activity"
        id="preservation_item_activity_tab"
        sx={commonStyles.formTabHeader}
      >
        <PreservationItemActivityForm
          disableFieldsWithoutPermissionRules={
            disableFieldsWithoutPermissionRules
          }
        />
        <MetaDataInputs withCheckAccess />
      </LazyFormTab>
      <LazyFormTab
        label="Comments"
        id="comment_tab"
        sx={commonStyles.formTabHeader}
      >
        <CommentFormTab
          targetId="preservation_item_activity_id"
          commentType={commentType.preservation_item_activity}
          transformFunc={commentTabTransformFunc}
        />
      </LazyFormTab>
      <LazyFormTab
        label="Attachment"
        id="attachment_tab"
        sx={commonStyles.formTabHeader}
      >
        <AttachmentFormTab
          targetId="preservationItemActivityId"
          resourceName={RESOURCE_PRESERVATION_ITEM_ACTIVITY}
          onUpload={clearCache}
          onDelete={clearCache}
        />
      </LazyFormTab>
      <HistoryTab tableName="tbl_preservation_item_activity" />
    </TabbedEditForm>
  );
};

export default PreservationItemActivityEdit;
