import React, { ReactElement, useCallback, Fragment } from 'react';
import { ListProps, useStore, useResourceContext } from 'react-admin';
import { useDictionaryLabelsWithResource } from '../../utils/UtilityFunctions';
import useCheckCurrentProjectResourceAccessSettings from '../common/hooks/useCheckCurrentProjectResourceAccessSettings';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import useSmartTabWideNarrow from '../common/SmartTabWidthButtons/useSmartTabWideNarrow';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import FilterMyItemsButton from '../common/FilterMyItemsButton';
import ListWithTitle from '../common/ListWithTitle';
import LegendButton from '../common/LegendButton';
import ListActions from '../common/ListActions';
import sideFilterStyles from '../common/sideFilterStyles';
import { RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY } from '../constants';
import SmartPreservationItemActivitySidebarFilters from './components/SmartPreservationItemActivitySidebarFilters';
import SmartPreservationItemActivityListDatagrid from './components/SmartPreservationItemActivityListDatagrid';
import { legends } from './components/helpers';
import SmartPreservationItemActivityEdit from './SmartPreservationItemActivityEdit';

const defaultVisibleColumns = [
  'id',
  'componentId',
  'testId',
  'locationId',
  'assigned',
  'frequency',
  'dueDate',
  'sequence',
  'storagePreservation',
];

const SmartPreservationItemActivityList: React.FC<
  ListProps
> = (): ReactElement => {
  useCheckCurrentProjectResourceAccessSettings();

  const { preferenceKey } = useDictionaryLabelsWithResource();
  const resource: string = useResourceContext();
  const sideFilter = getSideFiltersKey(resource);
  const [showSideFilter] = useStore(sideFilter, false);
  const [expanded, setExpanded] = useStore(`${resource}.datagrid.expanded`, []);
  const defaultFilter = useDefaultFilter('project', {
    isDeleted: false,
    isMobile: true,
    isClosed: false,
  });
  const isWideActive = useSmartTabWideNarrow(
    ['id', 'componentId', 'testId', 'locationId'],
    defaultVisibleColumns
  );
  const selectedRecordId = expanded?.length ? expanded[0] : null;

  const onCancel = useCallback(() => setExpanded([]), [setExpanded]);

  return (
    <ListWithTitle
      resource={RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          sideFilter={isWideActive ? undefined : sideFilter}
          preferenceKey={preferenceKey}
          defaultFilter={defaultFilter}
          showAction={{
            import: false,
            export: !isWideActive,
            effective: false,
            create: false,
            select: !isWideActive,
            topPagination: !isWideActive,
          }}
          actions={
            <Fragment>
              <FilterMyItemsButton
                defaultFilter={defaultFilter}
                label="My Preservations"
              />
              {!isWideActive && <LegendButton legends={legends} />}
            </Fragment>
          }
        />
      }
      sort={{ field: 'id', order: 'ASC' }}
      className="smartList"
      sx={sideFilterStyles.smartList}
      aside={
        <Fragment>
          {showSideFilter && !isWideActive && (
            <SmartPreservationItemActivitySidebarFilters
              defaultFilter={defaultFilter}
            />
          )}
          {selectedRecordId && (
            <SmartPreservationItemActivityEdit
              syncWithLocation={false}
              id={selectedRecordId}
              resource={RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}
              onCancel={onCancel}
            />
          )}
        </Fragment>
      }
    >
      <SmartPreservationItemActivityListDatagrid
        defaultVisibleColumns={defaultVisibleColumns}
      />
    </ListWithTitle>
  );
};

export default SmartPreservationItemActivityList;
