import { useCallback } from 'react';
import { useResourceContext } from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import {
  RESOURCE_ACTIVITY_PHASE,
  RESOURCE_ACTIVITY_TYPE,
  RESOURCE_ASSIGN_ITR,
  RESOURCE_ATTACHMENT,
  RESOURCE_ATTACHMENT_REVISION,
  RESOURCE_ATTACHMENT_TYPE,
  RESOURCE_CERT_DETAIL,
  RESOURCE_CERT_GATING,
  RESOURCE_CERT_GATING_PRED_CERT,
  RESOURCE_CERT_GATING_PUNCH,
  RESOURCE_CERT_GATING_TEST,
  RESOURCE_CERT_TEMPLATE,
  RESOURCE_COMPONENT,
  RESOURCE_COMPONENT_DRAWING,
  RESOURCE_COMPONENT_TEST,
  RESOURCE_CONFIGURATION,
  RESOURCE_CONTACT,
  RESOURCE_DISCIPLINE,
  RESOURCE_DRAWING,
  RESOURCE_DRAWING_DISCIPLINE,
  RESOURCE_DRAWING_TYPE,
  RESOURCE_EQUIPMENT_TYPE,
  RESOURCE_EQUIPMENT_TYPE_TEST,
  RESOURCE_EQUIPMENT_TYPE_WITH_TEST,
  RESOURCE_EQUIPMENT_TYPE_WITH_TEST_EQUIPMENT_TYPE,
  RESOURCE_GROUP,
  RESOURCE_GROUP_CONTACT,
  RESOURCE_LOCATION,
  RESOURCE_MATERIAL,
  RESOURCE_MATERIAL_COMPONENT,
  RESOURCE_MATERIAL_PURCHASE_ORDER,
  RESOURCE_MATERIAL_PURCHASE_ORDER_MATERIAL,
  RESOURCE_MATERIAL_RELEASED_MATERIAL_STORAGE,
  RESOURCE_MATERIAL_STORAGE,
  RESOURCE_MATERIAL_VENDOR,
  RESOURCE_MOC,
  RESOURCE_MOC_TYPE,
  RESOURCE_MODULE,
  RESOURCE_PENDING_PUNCH,
  RESOURCE_POSITION,
  RESOURCE_PRESERVATION_ITEM,
  RESOURCE_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_PRESERVATION_SERIES,
  RESOURCE_PROJECT,
  RESOURCE_PUNCH,
  RESOURCE_PUNCH_CATEGORY,
  RESOURCE_PUNCH_PRIORITY,
  RESOURCE_SECURITY_GROUP,
  RESOURCE_SECURITY_ROLE,
  RESOURCE_SMART_CERT_DETAIL,
  RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_SMART_TAG_ITR,
  RESOURCE_SMART_TAG_ITR_REVIEW,
  RESOURCE_STORAGE,
  RESOURCE_STRUCTURE_ASSET,
  RESOURCE_STRUCTURE_ASSET_GROUP,
  RESOURCE_STRUCTURE_REGION,
  RESOURCE_SUBMIT_PUNCH,
  RESOURCE_SUBSYSTEM,
  RESOURCE_SUBSYSTEM_DRAWING,
  RESOURCE_SYSTEM,
  RESOURCE_TEST,
  RESOURCE_TEST_EQUIPMENT_TYPE,
  RESOURCE_EQUIPMENT_TYPE_TEST_EQUIPMENT_TYPE,
  RESOURCE_TEST_POSITION,
  RESOURCE_TEST_TYPE,
  RESOURCE_USER,
  RESOURCE_USER_GROUP,
  RESOURCE_VENDOR,
  RESOURCE_VENDOR_CONTACT,
  RESOURCE_WORK_PACK,
  RESOURCE_ACTIVITY,
  RESOURCE_TEST_EQUIPMENT,
  RESOURCE_TEST_EQUIPMENT_CHECKOUT,
  RESOURCE_ACTIVITY_CERTIFICATE,
  RESOURCE_SMART_PUNCH_REVIEW,
  RESOURCE_ACTIVITY_PREDECESSOR,
} from '../../constants';

export const DEPENDENT_RESOURCE_MATRIX = {
  [RESOURCE_PUNCH_PRIORITY]: [
    RESOURCE_CERT_GATING,
    RESOURCE_PUNCH,
    RESOURCE_CERT_GATING_PUNCH,
  ],
  [RESOURCE_PUNCH]: [RESOURCE_ACTIVITY],
  [RESOURCE_PUNCH_CATEGORY]: [RESOURCE_PUNCH],
  [RESOURCE_PROJECT]: [
    RESOURCE_COMPONENT_TEST,
    RESOURCE_ATTACHMENT,
    RESOURCE_COMPONENT,
    RESOURCE_EQUIPMENT_TYPE_TEST,
    RESOURCE_MOC,
    RESOURCE_USER_GROUP,
    RESOURCE_SMART_TAG_ITR,
    RESOURCE_SMART_TAG_ITR_REVIEW,
    RESOURCE_PRESERVATION_ITEM,
    RESOURCE_PRESERVATION_ITEM_ACTIVITY,
    RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY,
    RESOURCE_USER,
  ],
  [RESOURCE_SECURITY_ROLE]: [RESOURCE_SECURITY_GROUP],
  [RESOURCE_ATTACHMENT]: [RESOURCE_ATTACHMENT, RESOURCE_ATTACHMENT_REVISION],
  [RESOURCE_SUBSYSTEM]: [
    RESOURCE_COMPONENT_TEST,
    RESOURCE_ASSIGN_ITR,
    RESOURCE_CERT_DETAIL,
    RESOURCE_ACTIVITY_CERTIFICATE,
    RESOURCE_COMPONENT,
    RESOURCE_PUNCH,
    RESOURCE_ACTIVITY,
    RESOURCE_SUBSYSTEM_DRAWING,
  ],
  [RESOURCE_SYSTEM]: [
    RESOURCE_CERT_DETAIL,
    RESOURCE_ACTIVITY_CERTIFICATE,
    RESOURCE_ACTIVITY,
    RESOURCE_SUBSYSTEM,
  ],
  [RESOURCE_ACTIVITY]: [
    RESOURCE_CERT_DETAIL,
    RESOURCE_ACTIVITY_PREDECESSOR,
    RESOURCE_ACTIVITY_CERTIFICATE,
  ],
  [RESOURCE_ACTIVITY_PREDECESSOR]: [RESOURCE_ACTIVITY],
  [RESOURCE_COMPONENT]: [
    RESOURCE_COMPONENT_TEST,
    RESOURCE_CERT_DETAIL,
    RESOURCE_ACTIVITY_CERTIFICATE,
    RESOURCE_PRESERVATION_ITEM_ACTIVITY,
    RESOURCE_PUNCH,
    RESOURCE_ACTIVITY,
    RESOURCE_COMPONENT_DRAWING,
    RESOURCE_MOC,
    RESOURCE_PRESERVATION_ITEM,
    RESOURCE_PRESERVATION_SERIES,
    RESOURCE_SMART_TAG_ITR,
    RESOURCE_SMART_TAG_ITR_REVIEW,
  ],
  [RESOURCE_CONFIGURATION]: [RESOURCE_CONFIGURATION],
  [RESOURCE_PRESERVATION_ITEM]: [
    RESOURCE_PRESERVATION_SERIES,
    RESOURCE_PRESERVATION_ITEM_ACTIVITY,
    RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY,
  ],
  [RESOURCE_PRESERVATION_ITEM_ACTIVITY]: [
    RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY,
    RESOURCE_PRESERVATION_SERIES,
    RESOURCE_COMPONENT,
  ],
  [RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY]: [
    RESOURCE_PRESERVATION_ITEM_ACTIVITY,
  ],
  [RESOURCE_ATTACHMENT_REVISION]: [RESOURCE_ATTACHMENT],
  [RESOURCE_ATTACHMENT_TYPE]: [RESOURCE_ATTACHMENT],
  [RESOURCE_LOCATION]: [
    RESOURCE_CERT_DETAIL,
    RESOURCE_ACTIVITY_CERTIFICATE,
    RESOURCE_COMPONENT,
    RESOURCE_DRAWING,
    RESOURCE_EQUIPMENT_TYPE_TEST,
    RESOURCE_PRESERVATION_ITEM_ACTIVITY,
    RESOURCE_PRESERVATION_ITEM,
    RESOURCE_PUNCH,
    RESOURCE_COMPONENT_TEST,
    RESOURCE_SMART_TAG_ITR,
    RESOURCE_SMART_TAG_ITR_REVIEW,
  ],
  [RESOURCE_DISCIPLINE]: [
    RESOURCE_CERT_DETAIL,
    RESOURCE_ACTIVITY_CERTIFICATE,
    RESOURCE_ACTIVITY,
    RESOURCE_COMPONENT,
    RESOURCE_EQUIPMENT_TYPE,
    RESOURCE_POSITION,
    RESOURCE_PUNCH,
  ],
  [RESOURCE_VENDOR]: [
    RESOURCE_CERT_DETAIL,
    RESOURCE_ACTIVITY_CERTIFICATE,
    RESOURCE_COMPONENT,
    RESOURCE_TEST_EQUIPMENT,
    RESOURCE_TEST_EQUIPMENT_CHECKOUT,
  ],
  [RESOURCE_ACTIVITY_PHASE]: [
    RESOURCE_CERT_DETAIL,
    RESOURCE_ACTIVITY_CERTIFICATE,
    RESOURCE_ACTIVITY,
  ],
  [RESOURCE_ACTIVITY_TYPE]: [RESOURCE_ACTIVITY],
  [RESOURCE_CERT_GATING]: [RESOURCE_ACTIVITY],
  [RESOURCE_CERT_GATING_PRED_CERT]: [
    RESOURCE_CERT_TEMPLATE,
    RESOURCE_CERT_GATING,
  ],
  [RESOURCE_CERT_GATING_PUNCH]: [RESOURCE_CERT_TEMPLATE, RESOURCE_CERT_GATING],
  [RESOURCE_CERT_GATING_TEST]: [RESOURCE_CERT_TEMPLATE, RESOURCE_CERT_GATING],
  [RESOURCE_CERT_TEMPLATE]: [
    RESOURCE_CERT_DETAIL,
    RESOURCE_ACTIVITY_CERTIFICATE,
    RESOURCE_CERT_GATING_PRED_CERT,
    RESOURCE_ACTIVITY,
  ],
  [RESOURCE_TEST]: [
    RESOURCE_COMPONENT_TEST,
    RESOURCE_EQUIPMENT_TYPE_TEST,
    RESOURCE_PRESERVATION_ITEM,
    RESOURCE_PRESERVATION_ITEM_ACTIVITY,
    RESOURCE_PRESERVATION_SERIES,
    RESOURCE_SMART_TAG_ITR,
    RESOURCE_SMART_TAG_ITR_REVIEW,
    RESOURCE_TEST_POSITION,
  ],
  [RESOURCE_TEST_TYPE]: [
    RESOURCE_CERT_GATING_TEST,
    RESOURCE_CERT_GATING,
    RESOURCE_TEST,
  ],
  [RESOURCE_GROUP]: [
    RESOURCE_COMPONENT,
    RESOURCE_DRAWING,
    RESOURCE_GROUP_CONTACT,
    RESOURCE_CONTACT,
    RESOURCE_PRESERVATION_ITEM_ACTIVITY,
    RESOURCE_COMPONENT_DRAWING,
    RESOURCE_SUBSYSTEM_DRAWING,
  ],
  [RESOURCE_GROUP_CONTACT]: [RESOURCE_CONTACT],
  [RESOURCE_CONTACT]: [
    RESOURCE_GROUP_CONTACT,
    RESOURCE_PRESERVATION_ITEM_ACTIVITY,
    RESOURCE_VENDOR_CONTACT,
  ],
  [RESOURCE_MODULE]: [RESOURCE_COMPONENT],
  [RESOURCE_WORK_PACK]: [RESOURCE_COMPONENT],
  [RESOURCE_EQUIPMENT_TYPE]: [RESOURCE_COMPONENT, RESOURCE_EQUIPMENT_TYPE_TEST],
  [RESOURCE_DRAWING_TYPE]: [
    RESOURCE_DRAWING,
    RESOURCE_COMPONENT_DRAWING,
    RESOURCE_SUBSYSTEM_DRAWING,
  ],
  [RESOURCE_DRAWING]: [
    RESOURCE_COMPONENT_DRAWING,
    RESOURCE_SUBSYSTEM_DRAWING,
    RESOURCE_COMPONENT,
  ],
  [RESOURCE_DRAWING_DISCIPLINE]: [
    RESOURCE_DRAWING,
    RESOURCE_COMPONENT_DRAWING,
    RESOURCE_SUBSYSTEM_DRAWING,
  ],
  [RESOURCE_MOC_TYPE]: [RESOURCE_MOC],
  [RESOURCE_STRUCTURE_ASSET]: [
    RESOURCE_STRUCTURE_ASSET_GROUP,
    RESOURCE_PROJECT,
  ],
  [RESOURCE_STRUCTURE_REGION]: [RESOURCE_STRUCTURE_ASSET, RESOURCE_PROJECT],
  [RESOURCE_COMPONENT_TEST]: [
    RESOURCE_SMART_TAG_ITR,
    RESOURCE_SMART_TAG_ITR_REVIEW,
    RESOURCE_PENDING_PUNCH,
    RESOURCE_SMART_PUNCH_REVIEW,
    RESOURCE_ACTIVITY,
  ],
  [RESOURCE_SMART_TAG_ITR_REVIEW]: [
    RESOURCE_SMART_TAG_ITR,
    RESOURCE_COMPONENT_TEST,
    RESOURCE_PENDING_PUNCH,
    RESOURCE_SMART_PUNCH_REVIEW,
    RESOURCE_ACTIVITY,
  ],
  [RESOURCE_SMART_TAG_ITR]: [RESOURCE_COMPONENT],
  [RESOURCE_PRESERVATION_SERIES]: [RESOURCE_PRESERVATION_ITEM_ACTIVITY],
  [RESOURCE_PENDING_PUNCH]: [RESOURCE_PUNCH, RESOURCE_SUBMIT_PUNCH],
  [RESOURCE_SMART_PUNCH_REVIEW]: [RESOURCE_PUNCH, RESOURCE_SUBMIT_PUNCH],
  [RESOURCE_EQUIPMENT_TYPE_TEST]: [RESOURCE_EQUIPMENT_TYPE],
  [RESOURCE_CERT_DETAIL]: [
    RESOURCE_ACTIVITY_CERTIFICATE,
    RESOURCE_SMART_CERT_DETAIL,
    RESOURCE_ACTIVITY,
  ],
  [RESOURCE_SMART_CERT_DETAIL]: [
    RESOURCE_CERT_DETAIL,
    RESOURCE_ACTIVITY_CERTIFICATE,
  ],
  [RESOURCE_EQUIPMENT_TYPE_WITH_TEST]: [
    RESOURCE_EQUIPMENT_TYPE,
    RESOURCE_EQUIPMENT_TYPE_TEST,
  ],
  [RESOURCE_EQUIPMENT_TYPE_WITH_TEST_EQUIPMENT_TYPE]: [
    RESOURCE_EQUIPMENT_TYPE,
    RESOURCE_EQUIPMENT_TYPE_TEST_EQUIPMENT_TYPE,
  ],
  [RESOURCE_TEST_EQUIPMENT_TYPE]: [
    RESOURCE_EQUIPMENT_TYPE_TEST_EQUIPMENT_TYPE,
    RESOURCE_EQUIPMENT_TYPE,
    RESOURCE_TEST_EQUIPMENT,
    RESOURCE_TEST_EQUIPMENT_CHECKOUT,
  ],
  [RESOURCE_TEST_EQUIPMENT]: [RESOURCE_TEST_EQUIPMENT_CHECKOUT],
  [RESOURCE_TEST_EQUIPMENT_CHECKOUT]: [RESOURCE_TEST_EQUIPMENT],
  [RESOURCE_SUBMIT_PUNCH]: [
    RESOURCE_PENDING_PUNCH,
    RESOURCE_SMART_PUNCH_REVIEW,
  ],
  [RESOURCE_MATERIAL]: [
    RESOURCE_MATERIAL_COMPONENT,
    RESOURCE_MATERIAL_VENDOR,
    RESOURCE_MATERIAL_PURCHASE_ORDER_MATERIAL,
    RESOURCE_MATERIAL_RELEASED_MATERIAL_STORAGE,
  ],
  [RESOURCE_MATERIAL_PURCHASE_ORDER]: [
    RESOURCE_MATERIAL_PURCHASE_ORDER_MATERIAL,
  ],
  [RESOURCE_STORAGE]: [RESOURCE_MATERIAL_RELEASED_MATERIAL_STORAGE],
  [RESOURCE_MATERIAL_STORAGE]: [RESOURCE_MATERIAL_RELEASED_MATERIAL_STORAGE],
};

const useInvalidateRelatedResourceQueries = (resourceName?: string) => {
  const resourceContext = useResourceContext();
  const resource = resourceName || resourceContext;
  const queryClient = useQueryClient();

  return useCallback(async () => {
    const dependentResources = DEPENDENT_RESOURCE_MATRIX[resource] ?? [];

    const dependentQueries = async (dependentResource: string) => {
      //Simply invalidate all queries for the dependent resource
      await queryClient.invalidateQueries({
        queryKey: [dependentResource],
        exact: false,
      });
    };

    return Promise.all(dependentResources.map(dependentQueries));
  }, [queryClient, resource]);
};

export default useInvalidateRelatedResourceQueries;
