import React, { useState, useCallback, ReactElement, useEffect } from 'react';
import { Datagrid } from 'react-admin';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { initNotificationContext } from '../../../provider/notificationContext';
import {
  AUTH_CONTEXT_KEY,
  getContext,
  UserContext,
} from '../../../provider/userContext';
import { WHITE } from '../../../theme/colors';
import FreeTextSearchFilter from '../../common/FreeTextSearchFilter';
import LogoField from '../../common/FileField';
import ListWithTitle from '../../common/ListWithTitle';
import { RESOURCE_PROJECT } from '../../constants';
import ProjectSideFilters from '../components/ProjectSideFilters';
import { getProjectListFilterForCurrentUser } from '../helpers';
import ProjectTextField from './ProjectTextField';
import FavoriteField from './FavoriteField';
import ColumnActions from './ColumnActions';
import CompanySwitch from './CompanySwitch';

interface ProjectSelectionProps {
  open: boolean;
  onClose: (event?, reason?: string) => void;
  allowClose?: boolean;
  projectCount?: number;
}

//ToDo: When user clicks Select Button, we need to set company, project header and call /user/my/details end-point again.
// With company and project we'll get effective permissions and roles and update the context.
const ProjectSelectionModal: React.FC<ProjectSelectionProps> = ({
  open,
  onClose,
  allowClose,
  projectCount,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const [filter, setFilter] = useState(null);

  const getFavoriteProjects = (ctx) => {
    if (!ctx?.company) return [];
    return (ctx?.companies?.[ctx?.company]?.favoriteProjects || []).filter(
      (id) => ctx?.companies?.[ctx?.company]?.projectIds.includes(id)
    );
  };
  const [defaultFilter, setDefaultFilter] = useState(
    getProjectListFilterForCurrentUser()
  );
  const [favoriteProjects, setFavoriteProjects] = useState(
    getFavoriteProjects(ctx)
  );

  const handleFavorite = useCallback(
    (val) => {
      setFilter(val ? { id: favoriteProjects } : null);
    },
    [setFilter, favoriteProjects]
  );

  const handleClose = useCallback(
    (event?: object, reason?: 'backdropClick' | 'escapeKeyDown') => {
      if (reason === 'backdropClick') {
        return;
      }

      initNotificationContext(ctx?.ablyToken, ctx?.company, ctx?.id);

      if (onClose) {
        onClose(event, reason);
      }
    },
    [ctx?.ablyToken, ctx?.company, ctx?.id, onClose]
  );

  useEffect(() => {
    const listenContextChange = () => {
      const newCtx: UserContext = getContext();
      if (!newCtx?.company) return;

      setDefaultFilter(getProjectListFilterForCurrentUser());
      setFavoriteProjects(getFavoriteProjects(newCtx));
    };

    window.addEventListener(AUTH_CONTEXT_KEY, listenContextChange);
    return () => {
      window.removeEventListener(AUTH_CONTEXT_KEY, listenContextChange);
    };
  }, []);

  if (!ctx?.company) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        sx={{
          background: '#2bab2b',
          color: 'white',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
      >
        {`Select Project${projectCount ? ` (${projectCount} Total)` : ''}`}
      </DialogTitle>
      {allowClose ? (
        <IconButton
          onClick={handleClose}
          sx={{
            color: WHITE,
            position: 'absolute',
            right: 4,
            top: 4,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <DialogContent>
        <ListWithTitle
          resource={RESOURCE_PROJECT}
          actions={<CompanySwitch />}
          filters={<FreeTextSearchFilter />}
          filter={{ ...defaultFilter, ...(filter ? filter : {}) }}
          sort={{ field: 'project', order: 'ASC' }}
          storeKey="projectSelection"
          aside={
            <ProjectSideFilters
              isAdminList={false}
              handleFavorite={handleFavorite}
              defaultFilter={defaultFilter}
            />
          }
          sx={{ height: '100%' }}
        >
          <Datagrid bulkActionButtons={false} rowClick={false}>
            <FavoriteField
              label="My Favorite"
              favoriteProjects={favoriteProjects}
            />
            <LogoField
              source="attachment"
              label="Logo"
              width={100}
              height={50}
              sortable={false}
            />
            <ProjectTextField label="Project" />
            <ColumnActions label="Actions" handleClose={handleClose} />
          </Datagrid>
        </ListWithTitle>
      </DialogContent>
    </Dialog>
  );
};

export default ProjectSelectionModal;
