import React, { ReactElement } from 'react';
import { Card, CardContent } from '@mui/material';
import { Button, Filter } from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import AssignedInput from '../common/Assigned/AssignedInput';
import { ContactCategories } from '../common/contactInput/constants';
import sideFilterStyles from '../common/sideFilterStyles';
import useSidebarFilters from '../common/hooks/useSidebarFilters';
import { SideFilter } from '../common/types';
import { RESOURCE_PRESERVATION_ITEM } from '../constants';
import DateRangeFilter from '../common/DateRangeFilter';
import CustomNumberInput from '../common/CustomNumberInput';
import LocationInput from '../common/LocationInput';
import TestInput from '../common/TestInput';
import ComponentInput from '../common/ComponentInput';
import { useDictionaryLabelsWithResource } from '../../utils/UtilityFunctions';
import TextInput from '../common/TextInput';
import { getContext, UserContext } from '../../provider/userContext';

const PreservationItemSidebarFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
  } = useSidebarFilters(RESOURCE_PRESERVATION_ITEM, defaultFilter);
  const { labels } = useDictionaryLabelsWithResource();
  const ctx: UserContext = getContext();

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <ComponentInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
            label={labels['componentId']}
          />
          <TestInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
            label={labels['testId']}
            name="testFormNo_inc"
            optionText="testFormNo"
            requestParams={{
              filter: {
                is_preservation: true,
                divisionId: ctx?.defaultDivisionId,
              },
              sort: { field: 'testFormNo', order: 'ASC' },
            }}
          />
          <LocationInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
            label={labels['locationId']}
          />
          <CustomNumberInput
            source="frequency"
            label={labels['frequency'] || 'Frequency'}
            alwaysOn
          />
          <CustomNumberInput
            source="estimatedManHours"
            label={labels['estimatedManHours'] || 'Estimated Man Hours'}
            alwaysOn
          />
          <DateRangeFilter
            source="startDate"
            label={labels['startDate'] || 'Start Date'}
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <DateRangeFilter
            source="endDate"
            label={labels['endDate'] || 'End Date'}
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            source="sequence"
            label={labels['sequence'] || 'Sequence'}
            name="sequence_inc"
            alwaysOn
          />
          <CustomNumberInput
            source="plusDate"
            label={labels['plusDate'] || 'Plus Date'}
            alwaysOn
          />
          <TextInput
            source="storagePreservation"
            label={labels['storagePreservation'] || 'Storage Preservation'}
            name="storagePreservation_inc"
            fullWidth
            alwaysOn
          />
          <AssignedInput
            category={ContactCategories.preservationEmpWorkGrp}
            source="assigned"
            label={labels['assigned'] || 'Responsible'}
            alwaysOn
          />
          <DateRangeFilter
            source="updatedAt"
            label="Updated At"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            source="updatedBy"
            label="Updated By"
            name="updatedBy_inc"
            fullWidth
            alwaysOn
          />
          <TextInput
            source="updatedByName"
            label="Updated By Name"
            name="updatedByName_inc"
            fullWidth
            alwaysOn
          />
        </Filter>
        <Button label="Clear Filters" onClick={handleClearFilters} />
      </CardContent>
    </Card>
  );
};

export default PreservationItemSidebarFilters;
