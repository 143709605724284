import React, { Fragment, ReactElement } from 'react';
import {
  DateField,
  ReferenceManyField,
  TextField,
  useRecordContext,
} from 'react-admin';
import {
  EditableDatagrid,
  EditRowButton,
} from '@react-admin/ra-editable-datagrid';
import ActivityPhaseField from '../../common/ActivityPhaseField';
import ActivityTypeField from '../../common/ActivityTypeField';
import commonStyles from '../../common/commonStyles';
import DeleteWithConfirmIconButton from '../../common/DeleteWithConfirmIconButton';
import DisciplineField from '../../common/DisciplineField';
import useInvalidateResourceQueryCache from '../../common/hooks/useInvalidateResourceQueryCache';
import SmartLinkField from '../../common/SmartLinkField';
import {
  RESOURCE_ACTIVITY,
  RESOURCE_ACTIVITY_PREDECESSOR,
} from '../../constants';
import PredecessorActivitiesRowForm from './PredecessorActivitiesRowForm';

interface Props {
  showMode?: boolean;
}

const PredecessorActivitiesEditTab: React.FC<Props> = ({
  showMode,
}): ReactElement => {
  const record = useRecordContext();

  const onRowChange = useInvalidateResourceQueryCache(RESOURCE_ACTIVITY);

  return (
    <ReferenceManyField
      target="activityId"
      reference={RESOURCE_ACTIVITY_PREDECESSOR}
    >
      <EditableDatagrid
        disableAutofocus
        mutationMode="pessimistic"
        createForm={
          !showMode && (
            <PredecessorActivitiesRowForm
              activityId={record.id}
              onSuccess={onRowChange}
            />
          )
        }
        editForm={
          !showMode && (
            <PredecessorActivitiesRowForm
              activityId={record.id}
              onSuccess={onRowChange}
            />
          )
        }
        actions={
          showMode ? (
            <Fragment />
          ) : (
            <Fragment>
              <EditRowButton />
              <DeleteWithConfirmIconButton onDelete={onRowChange} />
            </Fragment>
          )
        }
        bulkActionButtons={false}
        sx={commonStyles.formTabDatagrid}
      >
        <SmartLinkField
          label="Predecessor Activity"
          source="predecessorId"
          resource={RESOURCE_ACTIVITY}
          sortBy="`predecessor.activity`"
          sx={commonStyles.rowFormField}
        >
          <TextField source="predecessor.activity" />
        </SmartLinkField>
        <TextField
          source="predecessor.description"
          label="Description"
          sortBy="`predecessor.description`"
          sx={commonStyles.rowFormLongField}
        />
        <TextField
          source="type"
          label="Dependency Type"
          sx={commonStyles.rowFormExtraExtraShortField}
        />
        <TextField
          source="offset"
          label="Offset"
          sx={commonStyles.rowFormExtraExtraShortField}
        />
        <TextField
          source="offsetUnit"
          label="Offset Unit"
          sx={commonStyles.rowFormExtraExtraShortField}
        />
        <TextField
          source="predecessor.category"
          label="Category"
          sortBy="`predecessor.category`"
          sx={commonStyles.rowFormExtraShortField}
        />
        <ActivityPhaseField
          source="predecessor.activityPhase.id"
          textSource="predecessor.activityPhase.phase"
          sortBy="`predecessor.activityPhase.phase`"
          sx={commonStyles.rowFormExtraExtraShortField}
        />
        <ActivityTypeField
          source="predecessor.activityType.id"
          textSource="predecessor.activityType.activityType"
          sortBy="`predecessor.activityType.activityType`"
          sx={commonStyles.rowFormExtraShortField}
        />
        <DisciplineField
          source="predecessor.discipline.id"
          textSource="predecessor.discipline.discipline"
          sortBy="`predecessor.discipline.discipline`"
          sx={commonStyles.rowFormExtraShortField}
        />
        <TextField
          source="predecessor.percentComplete"
          label="Progress"
          sortBy="`predecessor.percentComplete`"
          sx={commonStyles.rowFormExtraExtraShortField}
        />
        <DateField
          source="predecessor.plannedStartDate"
          label="Planned Start Date"
          sortBy="`predecessor.plannedStartDate`"
          sx={commonStyles.rowFormExtraShortField}
        />
        <DateField
          source="predecessor.plannedDate"
          label="Planned Completion Date"
          sortBy="`predecessor.plannedDate`"
          sx={commonStyles.rowFormExtraShortField}
        />
      </EditableDatagrid>
    </ReferenceManyField>
  );
};

PredecessorActivitiesEditTab.defaultProps = {
  showMode: false,
};

export default PredecessorActivitiesEditTab;
