import React, { ReactElement, useMemo } from 'react';
import { getContext, UserContext } from '../../../provider/userContext';
import { required } from '../../../utils/UtilityFunctions';
import { RESOURCE_ACTIVITY } from '../../constants';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';

export interface ActivityInputProps
  extends Partial<React.ComponentProps<typeof AutocompleteReferenceInput>> {
  isRequired?: boolean;
  idsToFilterOut?: (string | number)[];
}

const ActivityInput: React.FC<ActivityInputProps> = ({
  isRequired,
  validate: validateFromProps,
  optionText,
  idsToFilterOut: idToFilterOut,
  ...rest
}): ReactElement => {
  let validate = validateFromProps;
  validate = validate ?? [];
  validate = Array.isArray(validate) ? validate : [validate];
  if (isRequired) {
    validate.push(required());
  }

  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
        ...(idToFilterOut && { id_neq_any: idToFilterOut }),
      },
      sort: { field: 'activity', order: 'ASC' as const },
    };
  }, [ctx?.projectId, idToFilterOut]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_ACTIVITY}
      requestParams={requestParams}
      name="activity_inc"
      optionText={optionText || 'activity'}
      validate={validate}
    />
  );
};

export default ActivityInput;
