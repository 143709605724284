import { flatten } from 'lodash';
import get from 'lodash/get';
import React, {
  useCallback,
  useState,
  Fragment,
  ReactElement,
  useEffect,
} from 'react';
import { Button, Form, useListContext, useStore } from 'react-admin';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { truncateLongMessage } from '../../../../utils/UtilityFunctions';
import { SUBSYSTEM_FILTER_RESOURCE } from '../../constants';
import AttachmentAdditionalFilterDialog from './AttachmentAdditionalFilterDialog';

export const AttachmentAdditionalFilters: React.FC = (): ReactElement => {
  const { setFilters, filterValues } = useListContext();
  const [selectedSubsystems] = useStore('BookGenSelectedSubsystems', []);
  const [showDialog, setShowDialog] = useState(false);
  const [resource, setResource] = useState(null);
  const [resourceValues, setResourceValues] = useState([]);
  const [additionalFilters, setAdditionalFilters] = useState([]);
  const openDialog = useCallback(() => setShowDialog(true), []);
  const closeDialog = useCallback(() => {
    setResource(null);
    setResourceValues([]);
    setShowDialog(false);
  }, []);

  const optionRenderer = useCallback(
    (record) => {
      return resource?.field.map((field) => get(record, field)).join('/');
    },
    [resource?.field]
  );

  const handleResourceValueChange = useCallback(
    (values, records) => {
      if (values.length > 0 && records.length > 0) {
        setResourceValues(
          records.map((record, index) => {
            return { id: values[index], name: optionRenderer(record) };
          })
        );
      } else {
        setResourceValues([]);
      }
    },
    [optionRenderer]
  );

  const getORFields = (filters) => {
    const ORFields = [];
    filters.forEach((filter) => {
      if (
        ORFields.findIndex((field) => Object.keys(field)[0] === filter.key) < 0
      ) {
        ORFields.push({
          [filter.key]: flatten(
            filters
              .filter((value) => value.key === filter.key)
              .map((value) => value.value)
          ),
        });
      }
    });
    return ORFields;
  };

  const prepareAdvanceFilters = useCallback((resource, values) => {
    return values.map((value) => {
      return {
        key: resource?.id,
        resource: resource?.name,
        value: value?.id,
        name: truncateLongMessage(value?.name, 13),
      };
    });
  }, []);

  const prepareAdvanceRelatedFilters = useCallback((subsystems) => {
    return subsystems
      .filter((subsystem) => subsystem.relatedActivities?.length > 0)
      .map((subsystem) => {
        return {
          key: 'activityId',
          resource: 'Subsystem Activity',
          value: subsystem.relatedActivities,
          name: truncateLongMessage(subsystem?.name, 13),
        };
      });
  }, []);

  const setAttachmentFilters = useCallback(
    (advFilters) => {
      let mergedAdditionalFilters = [...additionalFilters, ...advFilters];
      mergedAdditionalFilters = mergedAdditionalFilters.filter(
        (filter, index) => {
          return (
            index ===
            mergedAdditionalFilters.findIndex(
              (value) =>
                filter.key === value.key && filter.value === value.value
            )
          );
        }
      );
      setAdditionalFilters(mergedAdditionalFilters);
      filterValues['ORFields'] = getORFields(mergedAdditionalFilters);
      setFilters(filterValues, []);
    },
    [additionalFilters, filterValues, setFilters]
  );

  const handleSubmit = useCallback(() => {
    const advanceFilters = prepareAdvanceFilters(resource, resourceValues);
    setAttachmentFilters(advanceFilters);
    closeDialog();
  }, [
    closeDialog,
    prepareAdvanceFilters,
    resource,
    resourceValues,
    setAttachmentFilters,
  ]);

  const removeAdditionalFilter = useCallback(
    (filter) => {
      const updatedAdditionalFilters = additionalFilters.filter(
        (_filter) =>
          _filter.key !== filter.key || _filter.value !== filter.value
      );
      setAdditionalFilters(updatedAdditionalFilters);
      filterValues['ORFields'] = getORFields(updatedAdditionalFilters);
      setFilters(filterValues, []);
    },
    [additionalFilters, filterValues, setFilters]
  );

  useEffect(() => {
    const advanceFilters = prepareAdvanceFilters(
      SUBSYSTEM_FILTER_RESOURCE,
      selectedSubsystems
    );

    const advanceRelatedFilters =
      prepareAdvanceRelatedFilters(selectedSubsystems);

    setAttachmentFilters([...advanceFilters, ...advanceRelatedFilters]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubsystems]);

  const filterGridKey = (filter) => {
    return filter.key + '-' + filter.value;
  };

  const getFilterChipLabel = (filter) => {
    return filter.resource + ': ' + filter.name;
  };

  return (
    <Fragment>
      <Button label="More Filter" onClick={openDialog} />
      <Dialog open={showDialog} onClose={closeDialog}>
        <DialogTitle>Advanced Filter</DialogTitle>
        <IconButton
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Form>
            <AttachmentAdditionalFilterDialog
              resource={resource}
              setResource={setResource}
              setResourceValues={setResourceValues}
              handleResourceValueChange={handleResourceValueChange}
              optionRenderer={optionRenderer}
            />
            <Button
              disabled={resourceValues.length === 0}
              onClick={handleSubmit}
              label="Ok"
            />
          </Form>
        </DialogContent>
      </Dialog>
      <Grid container spacing={1}>
        {additionalFilters.map((filter) => {
          return (
            <Grid key={filterGridKey(filter)} item>
              <Chip
                label={getFilterChipLabel(filter)}
                variant="outlined"
                onDelete={() => removeAdditionalFilter(filter)}
                sx={{ maxWidth: '200px' }}
              />
            </Grid>
          );
        })}
      </Grid>
    </Fragment>
  );
};

export default AttachmentAdditionalFilters;
