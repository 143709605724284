import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import {
  EditableDatagrid,
  EditRowButton,
} from '@react-admin/ra-editable-datagrid';
import React, { Fragment, ReactElement } from 'react';
import {
  Pagination,
  ReferenceManyField,
  TextField,
  useRecordContext,
} from 'react-admin';
import commonStyles from '../../../common/commonStyles';
import DeleteWithConfirmIconButton from '../../../common/DeleteWithConfirmIconButton';
import { EditableDatagridCreateButton } from '../../../common/EditableDatagridCreateButton';
import useInvalidateResourceQueryCache from '../../../common/hooks/useInvalidateResourceQueryCache';
import {
  RESOURCE_MATERIAL_PURCHASE_ORDER,
  RESOURCE_MATERIAL_PURCHASE_ORDER_MATERIAL,
} from '../../../constants';
import PriceField from '../../common/PriceField';
import { PurchaseOrderRequestStatus } from '../constants';
import POMaterialsFooter from './POMaterialsFooter';
import POMaterialsRowForm from './POMaterialsRowForm';
import RefillMaterialsButton from './RefillMaterialsButton';

interface POMaterialsFormProps {
  isShowMode?: boolean;
}

const POMaterialsForm: React.FC<POMaterialsFormProps> = ({
  isShowMode: isShowModeFromProps,
}): ReactElement => {
  const record = useRecordContext();

  const onRowChange = useInvalidateResourceQueryCache(
    RESOURCE_MATERIAL_PURCHASE_ORDER
  );

  const isShowMode =
    isShowModeFromProps ||
    record.status === PurchaseOrderRequestStatus.CANCELLED ||
    record.status === PurchaseOrderRequestStatus.CLOSED;

  return (
    <ReferenceManyField
      target="materialPurchaseOrderId"
      reference={RESOURCE_MATERIAL_PURCHASE_ORDER_MATERIAL}
      pagination={<Pagination />}
    >
      <Fragment>
        <EditableDatagrid
          key={`POMaterialsForm_${isShowMode}`}
          disableAutofocus
          bulkActionButtons={false}
          mutationMode="pessimistic"
          createForm={
            !isShowMode && (
              <POMaterialsRowForm
                recordId={record.id}
                onSuccess={onRowChange}
              />
            )
          }
          editForm={
            <POMaterialsRowForm recordId={record.id} onSuccess={onRowChange} />
          }
          sx={commonStyles.formTabDatagrid}
          actions={
            isShowMode ? (
              <Fragment />
            ) : (
              <Fragment>
                <EditRowButton />
                <DeleteWithConfirmIconButton onDelete={onRowChange} />
              </Fragment>
            )
          }
          empty={
            !isShowMode && (
              <Box>
                <EditableDatagridCreateButton label="Add Material" />
                <RefillMaterialsButton startIcon={<AddIcon />} />
              </Box>
            )
          }
        >
          <TextField label="Material" source="material.material" />
          <TextField label="Description" source="material.description" />
          <TextField
            label="Type"
            source="material.materialType.type"
            sortable={false}
          />
          <TextField
            label="Qty Ordered"
            source="qtyOrdered"
            className="fixed"
          />
          <TextField
            label="Qty Received"
            source="qtyReceived"
            className="fixed"
          />
          <PriceField label="Price" source="price" className="fixed" />
          <PriceField label="SubTotal" source="subTotal" className="fixed" />;
          <TextField label="Comment" source="comment" className="fixed2x" />
        </EditableDatagrid>
        <POMaterialsFooter isShowMode={isShowMode} />
      </Fragment>
    </ReferenceManyField>
  );
};

export default POMaterialsForm;
