import React, { Fragment, ReactElement, useCallback, useState } from 'react';
import {
  AppBar as BaseAppBar,
  RefreshIconButton,
  AppBarProps,
  TitlePortal,
} from 'react-admin';
import { ReactComponent as RefreshIcon } from './assets/icons/functionalIcons/Refresh.svg';
import ProjectSelectionModal from './components/project/projectSelection/ProjectSelectionModal';
import { RecordingInProgress } from './components/RecordingInProgress';
import Logo from './components/common/Logo';
import { useSupportModal } from './components/common/hooks/useSupportModal';
import ModalApproval from './components/modalApproval/ModalApproval';
import NotificationBase from './components/notification/NotificationBase';
import ProjectSwitch from './components/project/ProjectSwitch';
import SubscriptionInfo from './components/subscriptionInfo/SubscriptionInfo';
import { ContactSupportModal } from './components/support/ContactSupportModal';
import UserMenu from './components/userMenu/UserMenu';
import { getContext, UserContext } from './provider/userContext';

const AppBar: React.FC<AppBarProps> = (props): ReactElement => {
  const ctx: UserContext = getContext();
  const {
    onClose,
    onOpen,
    onStopRecordingAndOpen,
    onStartRecording,
    recordingInProgress,
    withRecording,
    lastRecordTimeRange,
    recordLink,
    supportModalOpen,
  } = useSupportModal();

  const [projectCount, setProjectCount] = useState();
  const [
    isCloseAllowedForProjectSelection,
    setCloseAllowedForProjectSelection,
  ] = useState(false);

  const [projectSelectionIsOpen, setProjectSelectionIsOpen] = useState(false);

  const openProjectSelectionAfterLogin = useCallback(() => {
    setCloseAllowedForProjectSelection(false);
    setProjectSelectionIsOpen(true);
  }, []);

  const openProjectSelectionFromUserMenu = useCallback(() => {
    setCloseAllowedForProjectSelection(true);
    setProjectSelectionIsOpen(true);
  }, []);

  const closeProjectSelection = useCallback(() => {
    setProjectSelectionIsOpen(false);
  }, []);

  return (
    <BaseAppBar
      {...props}
      sx={{ borderRadius: '4px' }}
      toolbar={
        <Fragment>
          <ProjectSwitch
            onProjectSelectionOpen={openProjectSelectionAfterLogin}
            updateProjectCount={setProjectCount}
          />
          {projectSelectionIsOpen && (
            <ProjectSelectionModal
              projectCount={projectCount}
              onClose={closeProjectSelection}
              allowClose={isCloseAllowedForProjectSelection}
              open
            />
          )}
          {ctx?.id ? <ModalApproval /> : null}
          {ctx?.id && ctx?.ablyToken && ctx?.projectId ? (
            <NotificationBase />
          ) : null}
          <RefreshIconButton icon={<RefreshIcon />} />
          {recordingInProgress && (
            <RecordingInProgress onClick={onStopRecordingAndOpen} />
          )}
        </Fragment>
      }
      userMenu={
        <UserMenu
          recordingInProgress={recordingInProgress}
          onOpenSupportModal={onOpen}
          onStartRecording={onStartRecording}
          onStopRecording={onStopRecordingAndOpen}
          onOpenCompanySwitch={openProjectSelectionFromUserMenu}
        />
      }
    >
      <Logo />
      <TitlePortal align="center" />
      <ContactSupportModal
        open={supportModalOpen}
        onClose={onClose}
        withRecording={withRecording}
        lastRecordTimeRange={lastRecordTimeRange}
        recordLink={recordLink}
      />
      <SubscriptionInfo />
    </BaseAppBar>
  );
};

export default AppBar;
