import React, { ReactElement } from 'react';
import { useRecordContext } from 'react-admin';
import { EditRowButton as EditButton } from '@react-admin/ra-editable-datagrid';
import EmptyIconButtonPlaceholder from '../../../common/EmptyIconButtonPlaceholder';

const EditRowButton: React.FC = (): ReactElement => {
  const record = useRecordContext();

  return record?.isDeleted ? <EmptyIconButtonPlaceholder /> : <EditButton />;
};

export default EditRowButton;
