import React, { useCallback, ReactElement } from 'react';
import { TabbedForm } from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import AttachmentRevisionCreateInDialog from '../../../attachmentRevision/AttachmentRevisionCreateInDialog';
import AttachmentRevisionList from '../../../attachmentRevision/AttachmentRevisionList';
import useCanAccess from '../../../common/hooks/useCanAccess';
import commonStyles from '../../../common/commonStyles';
import LazyFormTab from '../../../common/LazyFormTab';
import {
  RESOURCE_ATTACHMENT,
  RESOURCE_ATTACHMENT_REVISION,
} from '../../../constants';

const AdminAttachmentRevisionList: React.FC = (): ReactElement => {
  const queryClient = useQueryClient();
  const canAccessList = useCanAccess();

  const onUpload = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: [RESOURCE_ATTACHMENT, 'getList'],
    });
    await queryClient.invalidateQueries({
      queryKey: [RESOURCE_ATTACHMENT_REVISION],
    });
  }, [queryClient]);

  return (
    <TabbedForm syncWithLocation={false} toolbar={null}>
      <LazyFormTab
        label="Revisions"
        id="revisions_tab"
        sx={commonStyles.formTabHeader}
        syncWithLocation={false}
      >
        <AttachmentRevisionList
          onUpload={onUpload}
          onlyShow={!canAccessList.edit}
        />
        {canAccessList.edit && (
          <AttachmentRevisionCreateInDialog onUpload={onUpload} />
        )}
      </LazyFormTab>
    </TabbedForm>
  );
};

export default AdminAttachmentRevisionList;
