import React, { ReactElement, useCallback, useState } from 'react';
import { Edit } from 'react-admin';
import { Box, Tabs, Tab } from '@mui/material';
import EditTitle from '../common/EditTitle';
import CustomTabPanel from '../common/CustomTabPanel';
import { EditPropsWithLocation } from '../common/types';
import sideFilterStyles from '../common/sideFilterStyles';
import commonStyles from '../common/commonStyles';
import SmartCertDetailsForm from './components/SmartCertDetailsForm';

interface SmartCertDetailsEditProps extends EditPropsWithLocation {
  onCancel?: () => void;
}

const SmartCertDetailsEdit: React.FC<SmartCertDetailsEditProps> = (
  props
): ReactElement => {
  const { onCancel } = props;
  const [value, setValue] = useState(0);
  const [mutationInProgress, setMutationInProgress] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleMutationStart = useCallback(() => {
    setMutationInProgress(true);
  }, []);

  const handleMutationFinish = useCallback(() => {
    setMutationInProgress(false);
  }, []);

  return (
    <Box sx={sideFilterStyles.sideEditCard} id="aside">
      <Edit
        {...props}
        mutationMode="optimistic"
        actions={false}
        title={<EditTitle />}
        mutationOptions={{
          onMutate: handleMutationStart,
          onSettled: handleMutationFinish,
        }}
        queryOptions={{
          enabled: !mutationInProgress, // Blinking issue fix, described in comment: https://trackertech.monday.com/boards/195709125/pulses/8497024148/posts/3895028743
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label="Form"
              id="form_tab"
              sx={{ ...commonStyles.formTabHeader, overflowX: 'auto' }}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} id="form_tab">
          <SmartCertDetailsForm onCancel={onCancel} />
        </CustomTabPanel>
      </Edit>
    </Box>
  );
};

export default SmartCertDetailsEdit;
