import React, { ReactElement, ReactNode } from 'react';
import { TabbedFormTabs, TabbedFormView } from 'react-admin';
import commonStyles from '../commonStyles';

interface TabbedViewProps {
  children?: ReactNode;
  tabs?: ReactElement;
  syncWithLocation?: boolean;
  className?: string;
}

const TabbedView: React.FC<TabbedViewProps> = (props): ReactElement => (
  <TabbedFormView
    {...props}
    tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
    sx={commonStyles.tabbedFormBoldHeader}
    toolbar={false}
  />
);

export default TabbedView;
