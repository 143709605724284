import React, { Fragment, ReactElement } from 'react';
import Box from '@mui/material/Box';
import commonStyles from '../../common/commonStyles';
import StrictOptionInput from '../../common/StrictOptionInput';
import EnforceCertGatingLegend from './EnforceCertGatingLegend';

const ProjectFormEnforceCertGatingTab: React.FC = (): ReactElement => (
  <Fragment>
    <Box sx={commonStyles.flexBox}>
      <StrictOptionInput
        source="enforceCertGatingProject"
        label="Enforce Cert Gating Project"
        sx={commonStyles.flexBoxSelectItem}
      />
      <StrictOptionInput
        source="enforceCertGatingSys"
        label="Enforce Cert Gating System"
        sx={commonStyles.flexBoxSelectItem}
      />
      <StrictOptionInput
        source="enforceCertGatingSs"
        label="Enforce Cert Gating SubSystem"
        sx={commonStyles.flexBoxSelectItem}
      />
      <StrictOptionInput
        source="enforceCertGatingComponent"
        label="Enforce Cert Gating Component"
        sx={commonStyles.flexBoxSelectItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <EnforceCertGatingLegend />
    </Box>
  </Fragment>
);

export default ProjectFormEnforceCertGatingTab;
