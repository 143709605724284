import { Box } from '@mui/material';
import { GanttModel } from '@syncfusion/ej2-gantt/src/gantt/base/gantt-model';
import {
  ColumnDirective,
  ColumnsDirective,
  GanttComponent,
  Inject,
} from '@syncfusion/ej2-react-gantt';
import React, { ReactElement, useEffect, useMemo } from 'react';
import commonStyles from '../../../common/commonStyles';
import {
  ganttChartProps,
  ganttChartEditProps,
  ganttChartServices,
} from './constants';
import { GanttApi } from './types';
import useGantt from './useGantt';
import './styles.css';

export interface GanttProps
  extends Omit<GanttModel, 'dataSource' | 'actionComplete' | 'rowSelected'> {
  tasks: object[];
  onTaskChange: (args: object) => void;
  onTaskSelect: (args: object) => void;
  onInit: (gantt: GanttApi) => void;
  allowEdit: boolean;
  projectStartDate: Date | string;
  projectEndDate: Date | string;
}

const Gantt: React.FC<GanttProps> = ({
  tasks,
  onTaskChange,
  onTaskSelect,
  onInit,
  allowEdit = false,
  ...rest
}): ReactElement => {
  const { ganttApi, ganttInstanceRef } = useGantt();
  useEffect(() => {
    onInit(ganttApi);
  }, [ganttApi, onInit]);

  const props = useMemo(
    () => ({
      ...ganttChartProps,
      ...(allowEdit
        ? {
            ...ganttChartEditProps,
            actionComplete: onTaskChange,
            rowSelected: onTaskSelect,
          }
        : { readOnly: true }),
    }),
    [allowEdit, onTaskSelect, onTaskChange]
  );

  return (
    <Box sx={commonStyles.flexBox}>
      <GanttComponent
        ref={ganttInstanceRef}
        className="gantt-component"
        dataSource={tasks}
        {...props}
        {...rest}
      >
        <ColumnsDirective>
          <ColumnDirective field="id" allowFiltering={false} />
          <ColumnDirective field="name" width="250" />
          <ColumnDirective field="startDate" />
          <ColumnDirective field="endDate" />
          <ColumnDirective field="duration" width="100" />
          <ColumnDirective field="progress" width="120" />
          <ColumnDirective
            headerText="Predecessor"
            field="dependency"
            allowFiltering={false}
          />
        </ColumnsDirective>
        <Inject services={ganttChartServices} />
      </GanttComponent>
    </Box>
  );
};

export default Gantt;
