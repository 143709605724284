import React, { ReactElement } from 'react';
import { SimpleForm, ListButton, useRecordContext } from 'react-admin';
import { Box, Typography } from '@mui/material';
import { ReactComponent as CancelIcon } from '../../../assets/icons/functionalIcons/Cancel.svg';
import FooterToolbar from '../../common/FooterToolbar';
import commonStyles from '../../common/commonStyles';
import JSONFormTab from './JSONFormTab';

interface MultiTagITRFormProps {
  onCancel?: () => void;
  setPendingPunchTitle?: (title: string | null) => void;
  setPendingPunchCreateDialogCloseDisabled: (value: boolean) => void;
}

const MultiTagITRForm: React.FC<MultiTagITRFormProps> = ({
  onCancel,
  setPendingPunchTitle,
  setPendingPunchCreateDialogCloseDisabled,
}): ReactElement => {
  const record = useRecordContext();
  const title = [record?.test?.testFormNo, record?.test?.testName]
    .filter((item) => item)
    .join(' - ');

  return (
    <SimpleForm
      toolbar={
        <FooterToolbar autoSave={true} onCancel={onCancel}>
          <ListButton label="Cancel" icon={<CancelIcon />} onClick={onCancel} />
        </FooterToolbar>
      }
      resetOptions={{ keepDirtyValues: true }}
    >
      <Box sx={{ ...commonStyles.columnFlexBox, padding: '0 16px 30px' }}>
        <Typography variant={'h6'} color={'textSecondary'} align={'left'}>
          {title}
        </Typography>
      </Box>
      <JSONFormTab
        formSchema={record?.multiTagTemplateDetails?.formSchema}
        formUiSchema={record?.multiTagTemplateDetails?.formUiSchema}
        formData={record?.multiTagFormData}
        formDataKey="multiTagFormData"
        setPendingPunchTitle={setPendingPunchTitle}
        setPendingPunchCreateDialogCloseDisabled={
          setPendingPunchCreateDialogCloseDisabled
        }
      />
    </SimpleForm>
  );
};

export default MultiTagITRForm;
