import React, { ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import PunchPriorityInput from '../common/PunchPriorityInput';
import PunchCategoryInput from '../common/PunchCategoryInput';
import DisciplineInput from '../common/DisciplineInput';
import CustomDateInput from '../common/CustomDateInput';
import {
  required,
  useDictionaryLabelsWithResource,
} from '../../utils/UtilityFunctions';
import SubsystemInput from '../common/SubsystemInput';
import LocationInput from '../common/LocationInput';
import commonStyles from '../common/commonStyles';
import AutocompleteInput from '../common/AutocompleteInput';
import TextInput from '../common/TextInput';
import { editStatuses } from './constants';

interface PendingPunchFormProps {
  bulkEditMode?: boolean;
}

const PendingPunchForm: React.FC<PendingPunchFormProps> = ({
  bulkEditMode,
}): ReactElement => {
  const requiredValidation = bulkEditMode ? [] : [required()];
  const isRequired = !bulkEditMode;
  const { labels } = useDictionaryLabelsWithResource();

  return (
    <Box sx={commonStyles.columnFlexBox}>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="description"
          label="Description"
          sx={{ ...commonStyles.flexBoxLongItem, flexGrow: 1 }}
          fullWidth
          multiline
          validate={[...requiredValidation, maxLength(3000)]}
        />
        <SubsystemInput isRequired={isRequired} sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <DisciplineInput />
        <PunchPriorityInput
          source="priorityId"
          sx={commonStyles.flexBoxItem}
          isRequired={isRequired}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <LocationInput sx={commonStyles.flexBoxItem} />
        <PunchCategoryInput
          source="punchCatId"
          label={labels['punchCatId']}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <AutocompleteInput
          source="status"
          label="Status"
          sx={commonStyles.flexBoxItem}
          choices={editStatuses}
          getOptionDisabled={(choice) => choice.disabled || false}
        />
        <TextInput
          source="comments"
          label="Review Comments"
          sx={{ ...commonStyles.flexBoxLongItem, flexGrow: 1 }}
          fullWidth
          multiline
          validate={[maxLength(255)]}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="raisedBy"
          label="Raised By"
          sx={commonStyles.flexBoxLongItem}
          disabled
        />
        <CustomDateInput
          source="raisedDate"
          label="Raised Date"
          sx={commonStyles.flexBoxItem}
          disabled
        />
      </Box>
    </Box>
  );
};

export default PendingPunchForm;
