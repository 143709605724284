import React, { ReactElement } from 'react';
import { Card, CardContent } from '@mui/material';
import { BooleanInput, Button } from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import CompletedInput from '../../preservationItemActivity/components/CompletedInput';
import { useDictionaryLabelsWithResource } from '../../../utils/UtilityFunctions';
import { RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY } from '../../constants';
import PreservationSeriesInput from '../../common/PreservationSeriesInput';
import { ContactCategories } from '../../common/contactInput/constants';
import { getContext, UserContext } from '../../../provider/userContext';
import PreservationItemInput from '../../common/PreservationItemInput';
import useSidebarFilters from '../../common/hooks/useSidebarFilters';
import ContactInput from '../../common/contactInput/ContactInput';
import AssignedInput from '../../common/Assigned/AssignedInput';
import CustomNumberInput from '../../common/CustomNumberInput';
import sideFilterStyles from '../../common/sideFilterStyles';
import DateRangeFilter from '../../common/DateRangeFilter';
import ComponentInput from '../../common/ComponentInput';
import LocationInput from '../../common/LocationInput';
import { SideFilter } from '../../common/types';
import TestInput from '../../common/TestInput';
import TextInput from '../../common/TextInput';
import FilterWithCheckAccess from '../../../components/common/FilterWithCheckAccess';

const SmartPreservationItemActivitySidebarFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
  } = useSidebarFilters(
    RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY,
    defaultFilter
  );
  const { labels } = useDictionaryLabelsWithResource();
  const ctx: UserContext = getContext();

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <FilterWithCheckAccess>
          <TextInput
            source="id"
            label="Id"
            name="id"
            alwaysOn
            fullWidth
            resettable
          />
          <PreservationItemInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
            label={labels['preservationItemId']}
          />
          <PreservationSeriesInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
            label={labels['preservationSeriesId']}
          />
          <ComponentInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
            label={labels['componentId']}
          />
          <TestInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
            label={labels['testId']}
            optionText="testFormNo"
            requestParams={{
              filter: {
                is_preservation: true,
                divisionId: ctx?.defaultDivisionId,
              },
              sort: { field: 'testFormNo', order: 'ASC' },
            }}
          />
          <LocationInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
            label={labels['locationId']}
          />
          <CustomNumberInput
            source="frequency"
            label={labels['frequency'] || 'Frequency'}
            alwaysOn
            fullWidth
          />
          <CustomNumberInput
            source="estimatedManHours"
            label={labels['estimatedManHours'] || 'Estimated Man Hours'}
            alwaysOn
            fullWidth
          />
          <DateRangeFilter
            source="startDate"
            label={labels['startDate'] || 'Start Date'}
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <DateRangeFilter
            source="endDate"
            label={labels['endDate'] || 'End Date'}
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            source="sequence"
            label={labels['sequence'] || 'Sequence'}
            name="sequence_inc"
            alwaysOn
            fullWidth
          />
          <CustomNumberInput
            source="plusDate"
            label={labels['plusDate'] || 'Plus Date'}
            alwaysOn
            fullWidth
          />
          <TextInput
            source="storagePreservation"
            label={labels['storagePreservation'] || 'Storage Preservation'}
            name="storagePreservation_inc"
            fullWidth
            alwaysOn
          />
          <CustomNumberInput
            source="actualManHours"
            label={labels['actualManHours'] || 'Actual Man Hours'}
            alwaysOn
            fullWidth
          />
          <CustomNumberInput
            source="adminManHours"
            label={labels['adminManHours'] || 'Admin Man Hours'}
            alwaysOn
            fullWidth
          />
          <AssignedInput
            label="Responsible"
            category={ContactCategories.preservationEmpWorkGrp}
            source="assigned"
            alwaysOn
          />
          <TextInput
            source="inspector"
            name="inspector_inc"
            label={labels['inspector'] || 'Inspector: Signature'}
            fullWidth
            resettable
            alwaysOn
          />
          <DateRangeFilter
            source="signOffDate"
            label={labels['signOffDate'] || 'Sign Off Date'}
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <CompletedInput
            source="completed"
            label={labels['completed'] || 'Status'}
            alwaysOn
            sx={sideFilterStyles.sideFiltersSelectInput}
            multiple
          />
          <TextInput
            source="verifiedBy"
            name="verifiedBy_inc"
            label={labels['verifiedBy'] || 'Verified By: Signature'}
            fullWidth
            resettable
            alwaysOn
          />
          <DateRangeFilter
            source="verifiedDate"
            label={labels['verifiedDate'] || 'Verified By: Date'}
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <ContactInput
            source="printedBy_inc"
            label={labels['printedBy'] || 'Printed By'}
            alwaysOn
            category={ContactCategories.preservationEmpWorkGrp}
            canCreate={false}
          />
          <DateRangeFilter
            source="printDate"
            label={labels['printDate'] || 'Printed Date'}
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <DateRangeFilter
            source="dueDate"
            label={labels['dueDate'] || 'Due Date'}
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <DateRangeFilter
            source="lateDate"
            label={labels['lateDate'] || 'Late Date'}
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <DateRangeFilter
            source="updatedAt"
            label="Updated"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            source="updatedBy"
            label="Updated By"
            name="updatedBy_inc"
            fullWidth
            alwaysOn
          />
          <TextInput
            source="updatedByName"
            label="Updated By Name"
            name="updatedByName_inc"
            fullWidth
            alwaysOn
          />
          <BooleanInput
            label="Show orphaned Activities"
            source="isDeleted"
            fullWidth
            alwaysOn
          />
        </FilterWithCheckAccess>
        <Button label="Clear filters" onClick={handleClearFilters} />
      </CardContent>
    </Card>
  );
};

export default SmartPreservationItemActivitySidebarFilters;
