import React, { Fragment, useMemo, ReactElement } from 'react';
import { useGetOne } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { getContext, UserContext } from '../../../provider/userContext';
import { required } from '../../../utils/UtilityFunctions';
import useRolesSecurityLevelValidator from '../../common/hooks/useRolesSecurityLevelValidator';
import PositionInput from '../../common/PositionInput';
import ProjectInputWithGroup from '../../common/ProjectInputWithGroup';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_PROJECT } from '../../constants';
import { getProjectValidator } from '../helpers';
import RoleInput from './RoleInput';
import GroupInput from './GroupInput';
import UserGroupInput from './UserGroupInput';

interface ProjectEditRowProps {
  scopedFormData: FieldValues;
}

const ProjectEditRow: React.FC<ProjectEditRowProps> = ({
  scopedFormData,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const { data: currentProject } = useGetOne(RESOURCE_PROJECT, {
    id: scopedFormData?.id,
  });
  const readOnly = currentProject && !currentProject?.isActive;
  const rolesSecurityLevelValidator = useRolesSecurityLevelValidator();
  const projectInputRequestParams = useMemo(() => {
    return {
      filter: { divisionId: ctx.defaultDivisionId },
      sort: { field: 'project', order: 'ASC' as const },
    };
  }, [ctx.defaultDivisionId]);

  return (
    <Fragment>
      <ProjectInputWithGroup
        source="id"
        label="Project"
        requestParams={projectInputRequestParams}
        includeFields={['id', 'project', 'isActive']}
        getOptionDisabled={(option) => !option.isActive}
        readOnly={readOnly}
        validate={[required(), getProjectValidator()]}
        additionalParams={{ skipListAdditionalData: true }}
        isRequired
        sx={commonStyles.flexBoxSelectItem}
      />
      <GroupInput source="groups" label="Groups *" readOnly={readOnly} />
      <RoleInput
        id={scopedFormData?.id?.toString()}
        source="roles"
        label="Roles"
        readOnly={readOnly}
        validate={rolesSecurityLevelValidator}
      />
      <UserGroupInput
        source="userGroups"
        label="User Groups"
        readOnly={readOnly}
      />
      <PositionInput
        label="Position"
        source="positionId"
        projectId={scopedFormData?.id}
        readOnly={readOnly}
      />
    </Fragment>
  );
};

export default ProjectEditRow;
