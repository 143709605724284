import React, { CSSProperties, Fragment, ReactElement } from 'react';
import NotificationIcon from '@mui/icons-material/Notifications';
import { Menu, Badge, IconButton, MenuItem } from '@mui/material';
import { Button } from 'react-admin';
import Box from '@mui/material/Box';
import notificationStyles from './notificationStyles';
import NotificationItem from './NotificationItem';
import { Notification } from './type';

interface NotificationsListProps {
  notifications: Notification[];
  dismissAllNotifications: () => void;
  readNotification: (id: number) => void;
}

const NotificationsList: React.FC<NotificationsListProps> = ({
  notifications,
  dismissAllNotifications,
  readNotification,
}): ReactElement => {
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(
    null
  );
  const openNotification = Boolean(anchorElement);

  const handleNotificationClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElement(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorElement(null);
  };

  return (
    <Fragment>
      <IconButton id="basic-button" onClick={handleNotificationClick}>
        <Badge badgeContent={notifications.length} color="secondary">
          <NotificationIcon />
        </Badge>
      </IconButton>
      <iframe
        // src="#" // src attribute causes incorrect behavioud in useListContext and causes extra rerenders
        name="download-frame"
        style={notificationStyles.notificationIframe as CSSProperties}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorElement}
        open={openNotification}
        onClose={handleNotificationClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {notifications.map((notification) => (
          <NotificationItem
            notification={notification}
            readNotification={readNotification}
            key={`notification_${notification.id}`}
          />
        ))}

        {notifications.length == 0 && (
          <Box sx={{ margin: '10px' }}>No new notifications.</Box>
        )}

        {notifications.length > 0 && (
          <MenuItem
            key="notification_dismiss"
            onClick={handleNotificationClose}
            sx={notificationStyles.notificationDismissAll}
          >
            <Button
              label="Dismiss All"
              variant="contained"
              onClick={dismissAllNotifications}
            />
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  );
};

export default NotificationsList;
