import React, { ReactElement } from 'react';
import commonStyles from '../../common/commonStyles';
import ComponentInput from '../../common/ComponentInput';
import ProjectInput from '../../common/ProjectInput';
import SubsystemInput from '../../common/SubsystemInput';
import SystemInput from '../../common/SystemInput';
import TextInput from '../../common/TextInput';
import { activityCategory } from '../../common/constants';

interface Props {
  category: string;
  isRequired: boolean;
}

const CategoryNameInput: React.FC<Props> = ({
  category,
  isRequired,
}): ReactElement => {
  const categoryInputProps = {
    sx: commonStyles.flexBoxItem,
    label: 'Category Name',
    isRequired,
  };

  switch (category) {
    case activityCategory.project:
      return <ProjectInput {...categoryInputProps} disabled />;
    case activityCategory.system:
      return <SystemInput {...categoryInputProps} />;
    case activityCategory.subSystem:
      return <SubsystemInput {...categoryInputProps} />;
    case activityCategory.component:
      return <ComponentInput {...categoryInputProps} />;
    default:
      return (
        <TextInput
          name="entity"
          source="entity"
          label="Category Name"
          disabled
          sx={commonStyles.flexBoxItem}
        />
      );
  }
};

export default CategoryNameInput;
