import moment from 'moment';
import { DATE_FORMAT } from '../common/constants';
import { Activity, DurationUnit } from './types';

export const getRecalculatedDuration = (
  activity: Partial<Activity>,
  workDays: number,
  workHours: number,
  shifts: number
): number => {
  if (!activity.plannedStartDate || !activity.plannedDate) {
    return activity.duration;
  }

  const workingDays = countWorkingDays(
    activity.plannedStartDate,
    activity.plannedDate,
    workDays
  );

  return convertWorkingDaysToDuration(
    workingDays,
    workHours,
    shifts,
    activity.durationUnit
  );
};

// Copied from API project from "src/utils/util.ts" file
const countWorkingDays = (
  startDate: string,
  endDate: string,
  workDaysProjectSetting: number
): number => {
  const start = moment(startDate);
  const end = moment(endDate);
  let workingDays = 0;

  if (workDaysProjectSetting > 0) {
    while (!start.isAfter(end, 'day')) {
      const day = start.isoWeekday();
      if (day >= 1 && day <= workDaysProjectSetting) {
        workingDays++;
      }
      start.add(1, 'day');
    }
  }

  return workingDays;
};

// Copied from API project from "src/utils/util.ts" file
const convertWorkingDaysToDuration = (
  workingDaysCount: number,
  workHours: number,
  shifts: number,
  durationUnit?: DurationUnit
): number => {
  switch (durationUnit) {
    case 'HOUR':
      return workingDaysCount * workHours * shifts;
    case 'MINUTE':
      return workingDaysCount * workHours * shifts * 60;
    default:
      return workingDaysCount;
  }
};

export const getRecalculatedPlannedDate = (
  plannedStartDate: string,
  duration: number,
  workDays: number,
  workHours: number,
  shifts: number,
  durationUnit?: DurationUnit
): string => {
  const durationInWorkingDays = convertDurationToWorkingDays(
    duration,
    workHours,
    shifts,
    durationUnit
  );

  return addWorkingDays(plannedStartDate, durationInWorkingDays, workDays);
};

export const getCalculatedPlannedStartDate = (
  plannedDate: string,
  duration: number,
  workDays: number,
  workHours: number,
  shifts: number,
  durationUnit?: DurationUnit
): string => {
  const durationInWorkingDays = convertDurationToWorkingDays(
    duration,
    workHours,
    shifts,
    durationUnit
  );

  return subtractWorkingDays(plannedDate, durationInWorkingDays, workDays);
};

// Copied from API project from "src/utils/util.ts" file
const convertDurationToWorkingDays = (
  duration: number,
  workHours: number,
  shifts: number,
  durationUnit?: DurationUnit
): number => {
  switch (durationUnit) {
    case 'HOUR':
      return Math.ceil(duration / workHours / shifts);
    case 'MINUTE':
      return Math.ceil(duration / workHours / shifts / 60);
    default:
      return duration;
  }
};

// Copied from API project from "src/utils/util.ts" file
const addWorkingDays = (
  startDate: string,
  workingDaysCount: number,
  workDaysProjectSetting: number
): string => {
  const startMoment = moment(startDate);

  if (!workingDaysCount || !workDaysProjectSetting) {
    return startMoment.format(DATE_FORMAT);
  }

  const calendarDaysCount = getCalendarDayCount(
    startDate,
    workingDaysCount,
    workDaysProjectSetting
  );

  return startMoment.add(calendarDaysCount, 'days').format(DATE_FORMAT);
};

// Copied from API project from "src/utils/util.ts" file
const subtractWorkingDays = (
  endDate: string,
  workingDaysCount: number,
  workDaysProjectSetting: number
): string => {
  const endDateMoment = moment(endDate);

  if (!workingDaysCount || !workDaysProjectSetting) {
    return endDateMoment.format(DATE_FORMAT);
  }

  const calendarDaysCount = getCalendarDayCount(
    endDate,
    workingDaysCount,
    workDaysProjectSetting,
    false
  );

  return endDateMoment.subtract(calendarDaysCount, 'days').format(DATE_FORMAT);
};

// Copied from API project from "src/utils/util.ts" file
const getCalendarDayCount = (
  date: string,
  workingDaysCount: number,
  workDaysProjectSetting: number,
  addingMode = true
): number => {
  const dateMoment = moment(date);
  const dayOfWeek = dateMoment.isoWeekday(); // Get day of week (1=Monday, 7=Sunday)
  const weekendDaysOfWeek =
    workDaysProjectSetting !== 7
      ? [1, 2, 3, 4, 5, 6, 7].slice(workDaysProjectSetting - 7)
      : [];

  const workingWeeksCount = Math.floor(
    workingDaysCount / workDaysProjectSetting
  ); // Full working weeks
  let extraWorkingDaysCount = workingDaysCount % workDaysProjectSetting; // Remaining working days

  if (dayOfWeek >= 1 && dayOfWeek <= workDaysProjectSetting) {
    extraWorkingDaysCount -= 1; // Subtract 1 day as start/end date is also a working day
  }

  let calendarDaysCount = workingWeeksCount * 7;

  if (addingMode) {
    switch (true) {
      case weekendDaysOfWeek.includes(dayOfWeek):
        calendarDaysCount += 7 - dayOfWeek + extraWorkingDaysCount; // If startDate is a weekend day, the first working day is Monday
        break;
      case dayOfWeek + extraWorkingDaysCount > workDaysProjectSetting:
        calendarDaysCount += extraWorkingDaysCount + weekendDaysOfWeek.length; // Add N days of weekend
        break;
      default:
        calendarDaysCount += extraWorkingDaysCount;
        break;
    }
  } else {
    switch (true) {
      case weekendDaysOfWeek.includes(dayOfWeek):
        calendarDaysCount +=
          dayOfWeek - workDaysProjectSetting + extraWorkingDaysCount - 1;
        break;
      case dayOfWeek - extraWorkingDaysCount < 0:
        calendarDaysCount += extraWorkingDaysCount + weekendDaysOfWeek.length; // Add N days of weekend
        break;
      default:
        calendarDaysCount += extraWorkingDaysCount;
        break;
    }
  }

  if (addingMode) {
    dateMoment.add(calendarDaysCount, 'days');
  } else {
    dateMoment.subtract(calendarDaysCount, 'days');
  }

  let resultDaysCount = calendarDaysCount;

  const resultDayOfWeek = dateMoment.isoWeekday();
  // If calculated result day is a weekend day
  if (weekendDaysOfWeek.includes(resultDayOfWeek)) {
    const adjustDays = resultDayOfWeek - workDaysProjectSetting;
    if (addingMode) {
      resultDaysCount -= adjustDays;
    } else {
      if (workingDaysCount % workDaysProjectSetting === 0) {
        resultDaysCount -= 7 - resultDayOfWeek + 1; // start day should be Monday
      } else {
        resultDaysCount += adjustDays; // start day should be working day before weekend
      }
    }
  }

  return resultDaysCount;
};
