import React, {
  ReactElement,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { maxLength, useDataProvider, useRecordContext } from 'react-admin';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';
import { MAX_RECORD_PER_PAGE } from '../../../provider/constants';
import { required } from '../../../utils/UtilityFunctions';
import ActivityConstraintInput from '../../common/ActivityConstraintInput';
import ActivityDurationUnitInput from '../../common/ActivityDurationUnitInput';
import ActivityPhaseInput from '../../common/ActivityPhaseInput';
import ActivityTypeInput from '../../common/ActivityTypeInput';
import CertTemplateInput from '../../common/CertTemplateInput';
import commonStyles from '../../common/commonStyles';
import { activityCategory, activityDurationUnit } from '../../common/constants';
import CustomDateInput from '../../common/CustomDateInput';
import CustomNumberInput from '../../common/CustomNumberInput';
import DisciplineInput from '../../common/DisciplineInput';
import useCurrentProjectSettings from '../../common/hooks/useCurrentProjectSettings';
import TextInput from '../../common/TextInput';
import { RESOURCE_ACTIVITY } from '../../constants';
import {
  getRecalculatedDuration,
  getRecalculatedPlannedDate,
  getCalculatedPlannedStartDate,
} from '../helpers';
import { getContext } from '../../../provider/userContext';
import ActivityCategoryInput from './ActivityCategoryInput';
import ActivityInput from './ActivityInput';
import CategoryNameInput from './CategoryNameInput';

interface SystemActivityFormProps {
  bulkEditMode?: boolean;
  selectedIds?: string[];
}

const ActivityForm: React.FC<SystemActivityFormProps> = ({
  bulkEditMode,
  selectedIds,
}): ReactElement => {
  const isRequired = !bulkEditMode;
  const ctx = getContext();
  const record = useRecordContext();
  const [haveParentChildRelation, setHaveParentChildRelation] = useState(true);
  const dataProvider = useDataProvider();

  useEffect(() => {
    const getInfo = async () => {
      if (bulkEditMode) {
        try {
          const { data } = await dataProvider.getList(RESOURCE_ACTIVITY, {
            filter: {
              id: selectedIds,
            },
            pagination: {
              page: 1,
              perPage: MAX_RECORD_PER_PAGE,
            },
          });
          const haveChildActivities = data?.some(
            (activity) => activity?.childActivities?.length
          );
          setHaveParentChildRelation(haveChildActivities);
        } catch (e) {}
      }
    };
    getInfo().then();
  }, [bulkEditMode, dataProvider, selectedIds]);

  const {
    isLoading: currentProjectSettingsLoading,
    currentProjectSettings: { workDays, workHours, shifts },
  } = useCurrentProjectSettings();

  const disablePlannedDateAndDuration = useMemo(
    () =>
      (bulkEditMode && haveParentChildRelation) ||
      Boolean(record?.childActivities?.length),
    [bulkEditMode, haveParentChildRelation, record?.childActivities?.length]
  );

  const { setValue, watch } = useFormContext();
  const category = watch('category');
  const duration = watch('duration');
  const durationUnit = watch('durationUnit');
  const plannedStartDate = watch('plannedStartDate');
  const plannedDate = watch('plannedDate');

  useEffect(() => {
    setValue('certTemplateId', null);
    setValue('systemId', null);
    setValue('subsystemId', null);
    setValue('componentId', null);

    if (category === activityCategory.project) {
      setValue('projectId', ctx.projectId);
    }
  }, [setValue, category, ctx]);

  const handlePlannedStartDateChange = useCallback(
    (value) => {
      if (!currentProjectSettingsLoading) {
        if (value) {
          const newDuration = getRecalculatedDuration(
            {
              plannedStartDate: value.toISOString(),
              plannedDate,
              duration,
              durationUnit,
            },
            workDays,
            workHours,
            shifts
          );
          setValue('duration', newDuration, { shouldDirty: bulkEditMode });

          if (!plannedDate && newDuration) {
            const newPlannedDate = getRecalculatedPlannedDate(
              value.toISOString(),
              newDuration,
              workDays,
              workHours,
              shifts,
              durationUnit
            );
            setValue('plannedDate', newPlannedDate, {
              shouldDirty: bulkEditMode,
            });
          }
        } else {
          setValue('duration', null, { shouldDirty: bulkEditMode });
        }
      }
    },
    [
      bulkEditMode,
      currentProjectSettingsLoading,
      duration,
      durationUnit,
      plannedDate,
      setValue,
      shifts,
      workDays,
      workHours,
    ]
  );

  const handlePlannedDateChange = useCallback(
    (value) => {
      if (!currentProjectSettingsLoading) {
        if (!!plannedStartDate) {
          const newDuration = value
            ? getRecalculatedDuration(
                {
                  plannedStartDate,
                  plannedDate: value.toISOString(),
                  duration,
                  durationUnit,
                },
                workDays,
                workHours,
                shifts
              )
            : null;
          setValue('duration', newDuration, { shouldDirty: bulkEditMode });
        } else if (value && duration > 0) {
          const plannedStartDate = getCalculatedPlannedStartDate(
            value.toISOString(),
            duration,
            workDays,
            workHours,
            shifts,
            durationUnit
          );
          setValue('plannedStartDate', plannedStartDate, {
            shouldDirty: bulkEditMode,
          });
        }
      }
    },
    [
      bulkEditMode,
      currentProjectSettingsLoading,
      duration,
      durationUnit,
      plannedStartDate,
      setValue,
      shifts,
      workDays,
      workHours,
    ]
  );

  const handleDurationChange = useCallback(
    (e) => {
      const duration = e?.target?.value;
      if (!currentProjectSettingsLoading) {
        if (!!plannedStartDate) {
          const newPlannedDate =
            duration > 0
              ? getRecalculatedPlannedDate(
                  plannedStartDate,
                  duration,
                  workDays,
                  workHours,
                  shifts,
                  durationUnit
                )
              : null;
          setValue('plannedDate', newPlannedDate, {
            shouldDirty: bulkEditMode,
          });
        } else if (!!plannedDate && duration > 0) {
          const plannedStartDate = getCalculatedPlannedStartDate(
            plannedDate,
            duration,
            workDays,
            workHours,
            shifts,
            durationUnit
          );
          setValue('plannedStartDate', plannedStartDate, {
            shouldDirty: bulkEditMode,
          });
        }
      }
    },
    [
      bulkEditMode,
      currentProjectSettingsLoading,
      durationUnit,
      plannedDate,
      plannedStartDate,
      setValue,
      shifts,
      workDays,
      workHours,
    ]
  );

  const handleDurationUnitChange = useCallback(
    (e) => {
      if (!currentProjectSettingsLoading) {
        const newDuration = getRecalculatedDuration(
          {
            plannedStartDate,
            plannedDate,
            duration,
            durationUnit: e?.target?.value,
          },
          workDays,
          workHours,
          shifts
        );
        setValue('duration', newDuration, { shouldDirty: bulkEditMode });
      }
    },
    [
      bulkEditMode,
      currentProjectSettingsLoading,
      duration,
      plannedDate,
      plannedStartDate,
      setValue,
      shifts,
      workDays,
      workHours,
    ]
  );

  return (
    <Box sx={commonStyles.columnFlexBox}>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <TextInput
          name="activity"
          source="activity"
          label="Activity"
          sx={commonStyles.flexBoxItem}
          validate={maxLength(75)}
          disabled={bulkEditMode}
        />
        <ActivityCategoryInput
          sx={commonStyles.flexBoxItem}
          readOnly={Boolean(record?.id) || bulkEditMode}
          isRequired={isRequired}
        />
        <CategoryNameInput category={category} isRequired={isRequired} />
        <ActivityPhaseInput
          sx={commonStyles.flexBoxItem}
          isRequired={isRequired}
        />
      </Box>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <TextInput
          name="description"
          source="description"
          label="Description"
          sx={commonStyles.flexBoxLongItem}
          validate={[maxLength(255), ...(!bulkEditMode ? [required()] : [])]}
          multiline
        />
        <DisciplineInput
          sx={commonStyles.flexBoxItem}
          withAllOption
          isRequired={isRequired}
        />
        <ActivityTypeInput
          sx={commonStyles.flexBoxItem}
          isRequired={isRequired}
        />
      </Box>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <TextInput
          name="sequence"
          source="sequence"
          label="Sequence"
          sx={commonStyles.flexBoxItem}
          validate={[maxLength(20), ...(!bulkEditMode ? [required()] : [])]}
          disabled={bulkEditMode}
        />
        <ActivityConstraintInput sx={commonStyles.flexBoxItem} />
        <CertTemplateInput
          category={category}
          disabled={!category}
          sx={commonStyles.flexBoxItem}
        />
        <ActivityInput
          source="parentId"
          label="Parent Activity"
          idsToFilterOut={bulkEditMode ? selectedIds : [record?.id || '']}
          optionText={(option) => `${option.id}::${option.activity}`}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <CustomNumberInput
          name="duration"
          source="duration"
          label="Duration"
          onChange={handleDurationChange}
          disabled={disablePlannedDateAndDuration}
          sx={commonStyles.flexBoxItem}
          isPositive
        />
        <CustomNumberInput
          name="actualDuration"
          source="actualDuration"
          label="Actual Duration"
          sx={commonStyles.flexBoxItem}
          isPositive
        />
        <ActivityDurationUnitInput
          sx={commonStyles.flexBoxItem}
          defaultValue={activityDurationUnit.day}
          onChange={handleDurationUnitChange}
          disabled={disablePlannedDateAndDuration}
          isRequired
        />
        <CustomNumberInput
          name="percentComplete"
          source="percentComplete"
          label="Progress"
          max={100}
          sx={commonStyles.flexBoxItem}
          isPositive
        />
      </Box>
      <Box sx={{ ...commonStyles.flexBoxWithWrap, marginBottom: '20px' }}>
        <CustomDateInput
          disabled={disablePlannedDateAndDuration}
          name="plannedStartDate"
          source="plannedStartDate"
          label="Planned Start Date"
          onChange={handlePlannedStartDateChange}
          sx={commonStyles.flexBoxItem}
        />
        <CustomDateInput
          name="actualStartDate"
          source="actualStartDate"
          label="Actual Start Date"
          sx={commonStyles.flexBoxItem}
        />
        <CustomDateInput
          disabled={disablePlannedDateAndDuration}
          name="plannedDate"
          source="plannedDate"
          label="Planned Completion Date"
          onChange={handlePlannedDateChange}
          sx={commonStyles.flexBoxItem}
        />
        <CustomDateInput
          name="actualDate"
          source="actualDate"
          label="Actual Completion Date"
          sx={commonStyles.flexBoxItem}
        />
      </Box>
    </Box>
  );
};

export default ActivityForm;
