import React, { ReactElement } from 'react';
import { Labeled } from 'react-admin';
import ComponentField from '../ComponentField';
import ProjectField from '../ProjectField';
import SubsystemField from '../SubsystemField';
import SystemField from '../SystemField';
import { activityCategory } from '../constants';
import { ActivityCategory } from '../types';

interface ActivityCategoryParentFieldProps {
  category: ActivityCategory;
}

const ActivityCategoryParentField: React.FC<
  ActivityCategoryParentFieldProps
> = ({ category }): ReactElement => {
  switch (category) {
    case activityCategory.system:
      return (
        <Labeled>
          <SystemField />
        </Labeled>
      );
    case activityCategory.subSystem:
      return (
        <Labeled>
          <SubsystemField />
        </Labeled>
      );
    case activityCategory.component:
      return (
        <Labeled>
          <ComponentField />
        </Labeled>
      );
    case activityCategory.project:
    default:
      return (
        <Labeled>
          <ProjectField label="Project" />
        </Labeled>
      );
  }
};

export default ActivityCategoryParentField;
