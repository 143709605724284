import * as React from 'react';
import { ReactElement } from 'react';
import { Link, useRecordContext } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import get from 'lodash/get';
import { FieldPropsWithOptionalSource } from './types';
import useCanAccess from './hooks/useCanAccess';

interface Props extends FieldPropsWithOptionalSource {
  children: ReactElement;
  source?: string;
  to?: string;
  sx?: SxProps<Theme>;
}

const SmartLinkField: React.FC<Props> = ({
  children,
  resource,
  source,
  sx,
}) => {
  const { edit, show } = useCanAccess(resource);
  const record = useRecordContext();

  if (!edit && !show) {
    return children;
  }

  const field = get(record, source);
  const linkType = edit ? '' : 'show';

  return (
    <Link to={`/${resource}/${field}/${linkType}`} sx={sx}>
      {children}
    </Link>
  );
};

export default SmartLinkField;
