import { Typography } from '@mui/material';
import React, { Fragment, ReactElement, useCallback } from 'react';
import { Link } from 'react-admin';
import { DownloadLink } from '../../common/DownloadLink';
import { NotificationType } from '../../common/enums';
import { titleFromResource } from '../../common/hooks/useResourceTitle';
import DownloadErrorFileLink from '../../common/ImportButton/components/DownloadErrorFileLink';
import { ImportResult } from '../../common/ImportButton/types';
import { RESOURCE_MATERIAL } from '../../constants';
import { IMPORT_RESULT_STATUS, Notification } from '../type';

interface NotificationTitleProps {
  notification: Notification;
}

export interface GeneratePdfMessage {
  fileName: string;
  link: string;
}

const NotificationTitle: React.FC<NotificationTitleProps> = ({
  notification,
}): ReactElement | null => {
  const resourceName =
    titleFromResource?.[notification?.details?.resourceName]?.[0] || '';

  const downloadFileLinkOnClickHandler = useCallback((e) => {
    e.stopPropagation();
  }, []);

  switch (notification.notificationType) {
    case NotificationType.IMPORT: {
      const importMessage: ImportResult = notification?.details;

      if (importMessage) {
        if (importMessage.status === IMPORT_RESULT_STATUS.SUCCESS) {
          return (
            <Typography>{`${resourceName} import is successfully completed. See details.`}</Typography>
          );
        }

        if (importMessage.status === IMPORT_RESULT_STATUS.PARTIAL_FAIL) {
          return (
            <Fragment>
              <Typography>{`${resourceName} import is partly completed. See details.`}</Typography>
              <DownloadErrorFileLink
                importResult={importMessage}
                onClick={downloadFileLinkOnClickHandler}
              />
            </Fragment>
          );
        }

        if (importMessage.status === IMPORT_RESULT_STATUS.FAIL) {
          return (
            <Typography>{`${resourceName} import is failed. See details.`}</Typography>
          );
        }
      }

      break;
    }
    case NotificationType.GENERATE_PDF: {
      const generatePdfMessage: GeneratePdfMessage = notification?.details;

      if (generatePdfMessage) {
        return (
          <Typography variant="subtitle2">
            &quot;{generatePdfMessage.fileName}&quot; is successfully generated.
            <br />
            <DownloadLink
              title="File with generated pdf(s)"
              downloadUrl={generatePdfMessage.link}
            />
          </Typography>
        );
      }

      break;
    }
    case NotificationType.ERROR:
      return <Typography>{`${resourceName} error. See details.`}</Typography>;
    case NotificationType.EXPORT:
      return (
        <Typography>{`${resourceName} export is done. Click to download.`}</Typography>
      );
    case NotificationType.CONVERT_TO_PDF_AND_SAVE_ATTACHMENTS:
      return (
        <Typography>{`${notification.details.fileName} attachment added!`}</Typography>
      );
    case NotificationType.POPULATE:
    case NotificationType.CERTIFICATE_CLOSEOUT:
    case NotificationType.ITR_COPY_TEMPLATE:
    case NotificationType.COPY_BOOK_TO_PROJECT:
    case NotificationType.UPDATE_ACTIVITY_ACTUAL_DATE:
      return <Typography>{notification?.details.message}</Typography>;
    case NotificationType.EXPORT_REPORT:
      return (
        <Typography>{`${resourceName} export is done. Click to download.`}</Typography>
      );
    case NotificationType.MATERIAL_INFO:
      return (
        <Typography>
          {notification?.details?.message} <br />
          <Link to={`/${RESOURCE_MATERIAL}/${notification?.details?.['id']}`}>
            See details.
          </Link>
        </Typography>
      );
  }

  return null;
};

export default NotificationTitle;
