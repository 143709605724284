import React, { Fragment, ReactElement, useState, useCallback } from 'react';
import {
  Button,
  SimpleForm,
  useUnselectAll,
  useListContext,
} from 'react-admin';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import { getContext, UserContext } from '../../../provider/userContext';
import AssignedWithCreateInput from '../../common/Assigned/AssignedWithCreateInput';
import { ContactCategories } from '../../common/contactInput/constants';
import useCanAccess from '../../common/hooks/useCanAccess';
import ModalDialog from '../../common/ModalDialog';
import CustomDateInput from '../../common/CustomDateInput';
import useCreateMany from '../../common/hooks/useCreateMany';
import commonStyles from '../../common/commonStyles';
import {
  RESOURCE_TEST_EQUIPMENT,
  RESOURCE_TEST_EQUIPMENT_CHECKOUT,
} from '../../constants';

const AssignResponsibleButton: React.FC = (): ReactElement => {
  const canAccessList = useCanAccess(RESOURCE_TEST_EQUIPMENT_CHECKOUT);
  const ctx: UserContext = getContext();
  const { selectedIds } = useListContext();
  const [open, setOpen] = useState(false);
  const openDialog = useCallback(() => setOpen(true), []);
  const closeDialog = useCallback(() => setOpen(false), []);
  const unselectAll = useUnselectAll(RESOURCE_TEST_EQUIPMENT);

  const { createMany } = useCreateMany({
    resource: RESOURCE_TEST_EQUIPMENT_CHECKOUT,
    onSuccess: () => {
      unselectAll();
    },
  });

  const handleSubmit = useCallback(
    (data) => {
      createMany(
        selectedIds.map((testEquipmentId) => ({
          ...data,
          testEquipmentId,
          projectId: ctx?.projectId,
        }))
      );
      closeDialog();
    },
    [closeDialog, createMany, ctx?.projectId, selectedIds]
  );

  if (!canAccessList.create) return;

  return (
    <Fragment>
      <Button label="Assign Responsible" onClick={openDialog} />
      <ModalDialog title="Update Responsible" open={open} onClose={closeDialog}>
        <SimpleForm id="assign_responsible_form" onSubmit={handleSubmit}>
          <Box sx={commonStyles.flexBox}>
            <AssignedWithCreateInput
              label="Select a Responsible Person"
              sx={commonStyles.flexBoxLongItem}
              category={ContactCategories.itrEmpWorkGrp}
              isRequired
            />
            <CustomDateInput
              label="Check Out Date"
              source="checkOutDate"
              sx={commonStyles.flexBoxItem}
              defaultValue={dayjs()}
              maxDate={dayjs()}
              isRequired
            />
          </Box>
        </SimpleForm>
      </ModalDialog>
    </Fragment>
  );
};

export default AssignResponsibleButton;
