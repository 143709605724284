import React, { ReactElement } from 'react';
import { useListContext } from 'react-admin';
import useCanAccess from '../../common/hooks/useCanAccess';
import BulkEditButton from '../../common/BulkEditButton';
import { RESOURCE_ACTIVITY } from '../../constants';
import ActivityForm from './ActivityForm';

const activityRequiredFields = [
  'category',
  'entity',
  'activityPhaseId',
  'description',
  'disciplineId',
  'activityTypeId',
  'sequence',
];

const BulkActionButtons: React.FC = (): ReactElement => {
  const canAccessList = useCanAccess();
  const { selectedIds } = useListContext();

  if (!canAccessList.edit) return;

  return (
    <BulkEditButton
      requiredFields={activityRequiredFields}
      resource={RESOURCE_ACTIVITY}
      form={<ActivityForm bulkEditMode selectedIds={selectedIds} />}
    />
  );
};

export default BulkActionButtons;
