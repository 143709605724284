import React, { ReactElement, useCallback } from 'react';
import { BooleanInput, SimpleForm, useRecordContext } from 'react-admin';
import { CreateInDialogButton } from '@react-admin/ra-form-layout';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import { required } from '../../utils/UtilityFunctions';
import CustomMarkdownInput from '../common/CustomMarkdownInput';
import { RESOURCE_COMMENT } from '../constants';
import UserGroupsInput from './components/UserGroupsInput';
import UsersInput from './components/UsersInput';

export interface CommentCreateProps {
  transformFunc: (data, recordId) => object;
}

const CommentCreate: React.FC<CommentCreateProps> = ({
  transformFunc,
}): ReactElement => {
  const record = useRecordContext();
  const transform = useCallback(
    (data) => {
      return transformFunc(data, record.id);
    },
    [transformFunc, record.id]
  );

  return (
    <CreateInDialogButton
      resource={RESOURCE_COMMENT}
      fullWidth
      maxWidth="md"
      label="Add Comment"
      transform={transform}
    >
      <SimpleForm id="create_comment_form">
        <CustomMarkdownInput
          label={false}
          source="comment"
          name="comment"
          height="300px"
          validate={[required()]}
        />
        <Box sx={{ ...commonStyles.flexBox, ...commonStyles.fullWidth }}>
          <UserGroupsInput
            source="userGroupIds"
            label="Notify user groups"
            sx={commonStyles.halfWidth}
          />
          <UsersInput
            source="userIds"
            label="Notify users"
            sx={commonStyles.halfWidth}
          />
        </Box>
        <BooleanInput
          source="isIncludeInReport"
          label="Show in report?"
          defaultValue={false}
          name="isIncludeInReport"
        />
      </SimpleForm>
    </CreateInDialogButton>
  );
};

export default CommentCreate;
