import { CreateDialog } from '@react-admin/ra-form-layout';
import React, { Fragment, ReactElement, useCallback } from 'react';
import { Button, SimpleForm, useNotify } from 'react-admin';
import { getContext, UserContext } from '../../../../provider/userContext';
import FooterToolbar from '../../../common/FooterToolbar';
import useDialogState from '../../../common/hooks/useDialogState';
import ActivityForm from '../../../activity/components/ActivityForm';
import { CUSTOM_ROUTE_GANTT_CHART } from '../../../constants';

const CreateButton: React.FC = (): ReactElement => {
  const { isVisible, show, hide } = useDialogState(false);
  const ctx: UserContext = getContext();
  const notify = useNotify();
  const notifyCreated = useCallback(() => {
    notify('Element created');
  }, [notify]);

  return (
    <Fragment>
      <Button label="Create" onClick={show} />
      <CreateDialog
        title="Activity Create"
        fullWidth
        maxWidth="md"
        isOpen={isVisible}
        close={hide}
        mutationOptions={{ onSuccess: notifyCreated }}
      >
        <SimpleForm
          defaultValues={{ projectId: ctx?.projectId }}
          toolbar={
            <FooterToolbar
              autoSave={false}
              cancelButtonResource={CUSTOM_ROUTE_GANTT_CHART}
              onCancel={hide}
            />
          }
        >
          <ActivityForm />
        </SimpleForm>
      </CreateDialog>
    </Fragment>
  );
};

export default CreateButton;
